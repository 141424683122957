import { createSelector } from 'reselect';
import { reduxCreators, getStateName, getAppState } from '../util';
import { keyExportCallList } from '../constants/keys';

export const exportMap = reduxCreators([
	[keyExportCallList, 'exportCallList']
]);

export const stateName = key => getStateName(exportMap[key]);

export const getExportRoot = store => getAppState(store, 'export');

export const exportStateByKey = (state, key) => getExportRoot(state)[stateName(key)];

export const cdrExportList = state => exportStateByKey(state, keyExportCallList).data;

export const exportCallListSelector = createSelector(
	cdrExportList
	, (callsExport) => {
		if (callsExport != null) {
			const cdrExports = [];
			if (callsExport.normalizeList) {
				Object.keys(callsExport.normalizeList).forEach((key) => {
					cdrExports.push(callsExport.normalizeList[key]);
				});
			}
			return cdrExports;
		}
		return [];
	}
);

export const exportTimezoneSelector = createSelector(
	cdrExportList
	, (callsExport) => {
		if (callsExport != null) {
			if (callsExport.dataSourceList) {
				return callsExport.dataSourceList.timeZone;
			}
		}
		return [];
	}
);

export const exportFileTypeSelector = createSelector(
	cdrExportList
	, (callsExport) => {
		if (callsExport != null) {
			if (callsExport.dataSourceList) {
				return callsExport.dataSourceList.fileType;
			}
		}
		return [];
	}
);

export const exportRunIntervalSelector = createSelector(
	cdrExportList
	, (callsExport) => {
		if (callsExport != null) {
			if (callsExport.dataSourceList) {
				return callsExport.dataSourceList.runInterval;
			}
		}
		return [];
	}
);

export const exportSearchIntervalSelector = createSelector(
	cdrExportList
	, (callsExport) => {
		if (callsExport != null) {
			if (callsExport.dataSourceList) {
				return callsExport.dataSourceList.searchInterval;
			}
		}
		return [];
	}
);

export const exportCallDisplayColumnsSelector = createSelector(
	cdrExportList
	, (callsExport) => {
		if (callsExport != null) {
			if (callsExport.dataSourceList) {
				return callsExport.dataSourceList.columnsToDisplay;
			}
		}
		return [];
	}
);

export const exportCallAdvanceFilterSelector = createSelector(
	cdrExportList
	, (callsExport) => {
		if (callsExport != null) {
			if (callsExport.dataSourceList) {
				return callsExport.dataSourceList.advanceCallFilters;
			}
		}
		return [];
	}
);
