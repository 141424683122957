// NOTE: use suffix Selector to mean function with selector pattern. But
// everyone should make their selector standardize.
// use suffix Memoize to mean special type of selector where the result
// independent of props. Memoize can be used Redux async action too.
import { createSelector } from 'reselect';
import update from 'immutability-helper';
import each from 'lodash/each';
import { I } from '../../common/v5/config.js';
import {
	AT_ACTIVE,
	AT_PARTIAL,
	emptyArray,
	emptyObject,
	CTX_MY,
	CTX_NEW,
	CTX_REVIEW,
	CTX_SEARCH,
	D_AREAS,
	D_FORWARD_AGENTS,
	D_TAGS_INFO,
	DEFAULT_FOLDER_NAME,
	ME_CREATE,
	ME_CREATE_AS_CLOSED,
	RC_EMAIL,
	RC_SMS,
	RC_CHAT,
	RC_FACEBOOK,
	RC_LINE,
	RC_LINKEDIN,
	RC_FB_MESSENGER,
	RC_TWITTER,
	UNSELECT,
	CHAT_crOwner,
	SEARCH_ERRANDS,
	SEARCH_NEW_ERRANDS,
	SEARCH_MY_ERRANDS,
	SEARCH_CLOSE_ERRANDS,
	VIP_ERRANDS,
	ONLINE_STATUS,
	AWAY_STATUS,
	INPROGRESS_STATUS,
	CTX_POSTPONED,
	//DEFAULT_ERRAND_TYPE_ICONS
} from '../../common/v5/constants';
import {
	mapsSum,
	mapsSumLevel,
	recipientsValue
} from '../../common/v5/helpers';
import {
	IsContextSearch
} from '../../common/v5/utils';
import { noSelector, wfSettingsData } from './common';
import { actionReducerCreator, getAppState, getUniqueData } from '../util';
import { UPDATE_PRIORITY_SORT } from '../constants/constants';
import { isPostponeContext, isReviewContext, reviewAreaMap } from './review';
import { getSelectedSearchErrandCount } from './search';
import moment from 'moment';

export const [ actionMap, reducerMap ] = actionReducerCreator([
	[UPDATE_PRIORITY_SORT, ({ context, field, value }) => ({sort: {[context]: {[field]: {$set: value}}}})]
]);

export const getWorkflowRoot = store => getAppState(store, 'workflow');

const makeBranch = branch => state => getWorkflowRoot(state)[branch];

const makeBranchData = branch => state => getWorkflowRoot(state)[branch].data;

export const knowledgeBaseListDataMemo = makeBranchData('knowledgeBaseList');

const getUI = state => getWorkflowRoot(state).ui;

const makeUISelector = field => state => getUI(state)[field]

export const messageTruncateByDefault = makeUISelector('errandMessageTruncateByDefault')

export const showMobileView = state => getUI(state).showMobileView;

const getWorkflowFilter = state => getWorkflowRoot(state).filter;

const getFilterSelectedAreas = state => getWorkflowFilter(state).selectedAreas;

export const listInputs = state => getWorkflowRoot(state).listInputs;

const listInputsSource = state => listInputs(state).source;

export const makeFeatureMemo = field => state => wfSettingsData(state)[field];

export const getMyId = makeFeatureMemo('activeUserId');

export const inboxSizeMemo = makeFeatureMemo('agentInboxSize');

export const isRootMemo = makeFeatureMemo('isRoot');

function getAdminSimpleTagList(state) {
	return state.app.workflow.adminTagSimpleList.data;
}

function getAdminTagList(state) {
	return state.app.workflow.adminTagList.data;
}

export const knowledgeBaseValidDataMemo = noSelector(
	knowledgeBaseListDataMemo,
	data => {
		if (!data || !data.list || !data.list.length) {
			return emptyArray;
		}
		return data.list;
	}
);

// TODO: refactor this to avoid storing context into redux store at all. So,
// can reduce the burden keep syncing what current context is. One way is
// through URL, another way is through request parameter 'source'. The later
// method still need a way to get the context to fill up the 'source'.
function getCurrentContext(state) {
	return getWorkflowFilter(state).currentContext;
}

export const contextMemo = noSelector(
	getCurrentContext
	, isReviewContext
	, isPostponeContext
	, (workflowCurrentContext, reviewContext, postponeContext) => {
		if (reviewContext || postponeContext) {
			if (reviewContext) {
				return CTX_REVIEW;
			} else if (postponeContext) {
				return CTX_POSTPONED;
			}
		}
		return workflowCurrentContext;
	}
);

export const currentContextFromInputList = noSelector(
	contextMemo
	, listInputsSource
	, (context, source) => {
		if (context === CTX_REVIEW) {
			return context;
		}
		return source;
	}
);

export function makeArraySelector(selector, arrayKey) {
	return createSelector(
		selector
		, data => {
			if (!data || !data[arrayKey] || !data[arrayKey].length) {
				return emptyArray;
			}
			return data[arrayKey];
		}
	);
}

const getWorkflowFolder = state => getWorkflowRoot(state).folders.data;

export const getFolderListSelector = createSelector(
	getWorkflowFolder
	, (data) => {
		if (!data || !data.folders || !data.folders.length) {
			return emptyArray;
		}
		return data.folders;
	}
)

function getAgentsState(state) {
	return state.app.workflow.agents;
}

export function getNormalizedAgents(state) {
	const { byId } = getAgentsState(state);
	if (!byId) {
		return emptyObject;
	}
	return byId;
}

const emptyAllActive = {all: emptyArray, active: emptyArray}
	;
export const agentListSelector = createSelector(
	getAgentsState
	, ({data, param}) => {
		if (!data || !data.agents || !data.agents.length) {
			return emptyAllActive;
		}
		data = data.agents;
		if (!param || !param.includeInactive) {
			return {all: data, active: data};
		}
		return {all: data, active: data.filter(ca => ca.Active)};
	}
);

export const onlyActiveAgentsSelector = createSelector(
	agentListSelector
	, ({active}) => active
);

const getErrandList = state => {
	return state.app.workflow.errandList;
}

const getErrandListChat = state => {
	return state.app.workflow.errandListChat;
}

export const chatErrand = (state, errandId) => {
	const chats = getErrandListChat(state)
	let chat
	each(getErrandListChat(state), v => {
		if (v.errand.id === errandId) {
			chat = v
			return false
		}
	})
	return chat
}

const getSearchResults = state => state.app.search.results;

export const getHasDueDateSelected = createSelector(getErrandList,
	errandList => {
		let hasDueDateSelected = true;
		if (errandList.data) {
			const opr = errandList.data.opr;
			const norm = errandList.data.norm;
			$.each(opr, function(k,v) {
				if(v.selected) {
					if (norm[k] && norm[k].data && !norm[k].data.duedateselected) {
						hasDueDateSelected = false;
						return false;
					}
				}
			});
		}
		return hasDueDateSelected;
	}
);

export const getChatErrandSelected = createSelector(getErrandListChat,
	erdListChat => {
		let hasChatSelected = false;
		if (erdListChat.length > 0) {
			$.each(erdListChat, function(i,v) {
				if(v.ui.selected) {
					hasChatSelected = true;
					return false;
				}
			});
		}
		return hasChatSelected;
	}
);

function checkAllErrandSelected(data) {
	const opr = data.opr;
	let allSelected = true;
	$.each(opr, function(k,v) {
		if(!v.selected) {
			allSelected = false;
			return false;
		}
	});
	return allSelected;
}

// Determine if errands and chat errands are all selected
export const getErrandAllSelected = createSelector([getErrandList, getErrandListChat, getCurrentContext],
	(erdList, erdListChat, currCtx) => {
		let allErrandSelected = false
		, allChatErrandSelected = false;

		if (currCtx == CTX_MY && erdListChat.length > 0) {
			allChatErrandSelected = true;
			$.each(erdListChat, function(i,v) {
				if(!v.ui.selected) {
					allChatErrandSelected = false;
					return false;
				}
			});
			if (allChatErrandSelected && erdList.data) {
				allErrandSelected = checkAllErrandSelected(erdList.data);
			}
			return (allErrandSelected && allChatErrandSelected);
		} else {
			if(erdList.data) {
				allErrandSelected = checkAllErrandSelected(erdList.data);
			}
			return allErrandSelected;
		}
	}
);

const mapKeyChatDataSelect = {
		id: (k, v) => v.errand.id
		, area: (k, v) => v.errand.data.area
		, chat: (k, v) => v
		, needTagging: (k, v) => v.Role == CHAT_crOwner && v.tags.length == 0
		, cipherKey: (k, v) => v.errand.data.cipherKey
		, service: (k, v) => v.errand.data.service
	}
	, mapKeyErrandDataSelect = {
		id: (k, v, errands) => parseInt(k, 10)
		, area: (k, v, errands) => errands[k].data.area
		, needTagging: (k, v, errands) => errands[k].data.tagsList.length == 0
		, cipherKey: (k, v, errands) => errands[k].data.cipherKey
		, service: (k, v, errands) => errands[k].data.service
	}
	, mapKeySearchDataSelect = {
		id: (k, v, errands) => errands[k].id
		, area: (k, v, errands) => errands[k].areaId
		, needTagging: (k, v, errands) => errands[k].tags == ""
		, cipherKey: (k, v, errands) => errands[k].cipherKey
		, agentId: (k, v, errands) => errands[k].agentId
	}
	, dataCreator = (which, map) => (...args) => which.reduce((acc, v) => {
		const f = map[v];
		if (typeof f !== "function") {
			return acc;
		}
		acc[v] = f(...args);
		return acc;
	}, {})
	, mapSelectChats = [
		(k, v) => v.ui.selected
		, mapKeyChatDataSelect
		, ["id", "area", "chat", "needTagging", "cipherKey"]
		, (k, v) => v.errand.id.toString()
	]
	, mapSelectErrands = [
		(k, v) => v.selected
		, mapKeyErrandDataSelect
		, ["id", "area", "needTagging", "cipherKey"]
		, k => k
	]
	, mapSelectSearchResult = [
		(k, v) => v.selected
		, mapKeySearchDataSelect
		, ["id", "area", "needTagging", "cipherKey", "agentId"]
		, k => k
	]
	, idxSelected = 0
	, idxMap = 1
	, idxAll = 2
	, idxFalse = 3
	, selectedDataCreator = (selected, which, data, map, ...args) => {
		$.each(data, (k, v) => {
			if (map[idxSelected](k, v, ...args)) {
				const keyResolver = map[idxMap];
				let resultCreator;
				if (which && which === "area") {
					resultCreator = keyResolver.area;
				} else if (which && which.length) {
					const creator = arr => dataCreator(arr, keyResolver);
					if (which === "all") {
						resultCreator = creator(map[idxAll]);
					} else if (which === "cipherKey") {
						resultCreator = creator(["id", "cipherKey"]);
					} else {
						resultCreator = creator(which);
					}
				} else {
					resultCreator = map[idxFalse];
				}
				selected.push(resultCreator(k, v, ...args));
			}
		});
		return selected;
	}
	;
// emulating getSelectedData but more flexible.
const anySelectDataCreator = ({ norm, opr }, chats, getSize, which) => {
	let selected = [];
	selectedDataCreator(selected, which, chats, mapSelectChats);
	selectedDataCreator(selected, which, opr, mapSelectErrands, norm);
	if (getSize) {
		return selected.length;
	} else {
		return selected;
	}
};

const getSelectedData = (errands, chats, getSize, which) => {
	let selected = [];
	$.each(chats, (i, v) => {
		if (v.ui.selected) {
			if (which && typeof which === "string") {
				if (which === "all") {
					let needTagging = (v.Role == CHAT_crOwner && v.tags.length == 0);
					selected.push({
						id: v.errand.id,
						area: v.errand.data.area,
						chat: v,
						needTagging: needTagging,
						cipherKey: v.errand.data.cipherKey
					});
				} else if (which === "cipherKey") {
					selected.push({id: v.errand.id, cipherKey: v.errand.data.cipherKey});
				} else {
					selected.push(v.errand.data.area);
				}
			} else if (which && which.length) {
				let pushee = {};
				$.each(which, (j, w) => {
					pushee[w] = mapKeyChatDataSelect(w)(i, v);
				});
				selected.push(pushee);
			} else {
				selected.push(v.errand.id.toString());
			}
		}
	});
	$.each(errands.opr, (i, v) => {
		if (v.selected) {
			if (which && typeof which === "string") {
				if(which === "all") {
					let needTagging = (errands.norm[i].data.tagsList == null ||
						errands.norm[i].data.tagsList.length == 0);
					selected.push({
						id: parseInt(i, 10),
						area: errands.norm[i].data.area,
						needTagging: needTagging,
						cipherKey: errands.norm[i].data.cipherKey
					});
				} else if (which === "cipherKey") {
					selected.push({id: i, cipherKey: errands.norm[i].data.cipherKey});
				} else {
					selected.push(errands.norm[i].data.area);
				}
			} else if (which && which.length) {
				let pushee = {};
				$.each(which, (j, w) => {
					pushee[w] = mapKeyErrandDataSelect(w)(i, v, errands.norm);
				});
				selected.push(pushee);
			} else {
				selected.push(i);
			}
		}
	});
	if(getSize) {
		return selected.length;
	}else{
		return selected;
	}
};

export const getTotalSelectedErrands = createSelector(
	[
		getErrandList,
		getErrandListChat,
		getSelectedSearchErrandCount,
		contextMemo
	],
	(selectedErrand, selectedChat, selectedSearch, currentContext) => {
		if(IsContextSearch(currentContext)){
			return selectedSearch.allErrands + selectedSearch.myErrands + selectedSearch.closedErrands;
		}
		return getSelectedData(selectedErrand.data, selectedChat, true, false);
	}
);

export const getSelectedIds = createSelector(
	[
		getErrandList,
		getErrandListChat
	],
	(selectedErrand, selectedChat) => {
		return getSelectedData(selectedErrand.data, selectedChat, false, false);
	}
);

export const getSelectedErrandWithCipherKey = createSelector(
	[
		getErrandList,
		getSearchResults,
		contextMemo
	],
	(selectedErrand, {opr:searchSelected, errandById:searchResults}, currentContext) => {
		if(IsContextSearch(currentContext)){
			return selectedDataCreator([], ["id", "cipherKey"], searchSelected, mapSelectSearchResult, searchResults);
		}
		return getSelectedData(selectedErrand.data, [], false, "cipherKey");
	}
);

export const actionsSelectedErrandsSelector = createSelector(
	[
		getErrandList,
		getErrandListChat,
		getSearchResults,
		contextMemo
	]
	, ({ data }, chat, {opr:searchSelected, errandById:searchResults}, currentContext) => {
		if(IsContextSearch(currentContext)){
			return selectedDataCreator([], ["id", "area", "cipherKey", "agentId"], searchSelected, mapSelectSearchResult, searchResults);
		}
		return anySelectDataCreator(data, chat, false, ["id", "area", "cipherKey","service"]);
	}
);

// export const getSelectedChatErrandWithCipherKey = createSelector(getErrandListChat,
// 	(selectedChat) => {
// 		return getSelectedData({}, selectedChat, false, "cipherKey");
// 	}
// );

// export const getSelectedAllErrandWithCipherKey = createSelector(
// 	[getErrandList, getErrandListChat],
// 	(selectedErrand, selectedChat) => {
// 		return getSelectedData(selectedErrand.data, selectedChat, false, "cipherKey");
// 	}
// );

// without chat errands
export const getSelectedErrandIds = createSelector(
	[
		getErrandList,
		getSearchResults,
		contextMemo
	],
	(selectedErrand, {opr:searchSelected, errandById:searchResults}, currentContext) => {
		if(IsContextSearch(currentContext)){
			return selectedDataCreator([], "", searchSelected, mapSelectSearchResult, searchResults);
		}
		return getSelectedData(selectedErrand.data, [], false, false);
	}
);

// chat errands
export const getSelectedChatErrandIds = createSelector(
	[
		getErrandListChat,
		contextMemo
	],
	(selectedChat, currentContext) => {
		if(IsContextSearch(currentContext)){
			return emptyArray;
		}
		return getSelectedData({}, selectedChat, false, false);
	}
);

export const getSelectedAreas = createSelector(
	[
		getErrandList,
		getErrandListChat,
		getSearchResults,
		contextMemo
	],
	(selectedErrand, selectedChat, {opr:searchSelected, errandById:searchResults}, currentContext) => {
		if(IsContextSearch(currentContext)){
			let areas = getUniqueData(selectedDataCreator([], "area", searchSelected, mapSelectSearchResult, searchResults));
			return areas.join(',');
		}
		let areas = getUniqueData(getSelectedData(selectedErrand.data, selectedChat, false, "area"));
		let areasString = areas.join(',');
		return areasString;
	}
);

export const getSelectedAllErrandsWithArea = createSelector(
	[
		getErrandList,
		getErrandListChat,
		getSearchResults,
		contextMemo
	],
	(selectedErrand, selectedChat, {opr:searchSelected, errandById:searchResults}, currentContext) => {
		if(IsContextSearch(currentContext)){
			return selectedDataCreator([], "all", searchSelected, mapSelectSearchResult, searchResults);
		}
		return getSelectedData(selectedErrand.data, selectedChat, false, "all");
	}
);

export const getSelectedChatErrandsWithArea = createSelector(
	[
		getErrandListChat,
		contextMemo
	],
	(selectedChat, currentContext) => {
		if(IsContextSearch(currentContext)){
			return emptyArray;
		}
		return getSelectedData({}, selectedChat, false, "all");
	}
);

export const getSelectedErrandsWithArea = createSelector(
	[
		getErrandList,
		getSearchResults,
		contextMemo
	],
	(selectedErrand, {opr:searchSelected, errandById:searchResults}, currentContext) => {
		if(IsContextSearch(currentContext)){
			return selectedDataCreator([], "all", searchSelected, mapSelectSearchResult, searchResults);
		}
		return getSelectedData(selectedErrand.data, [], false, "all");
	}
);

const getSettings = (state, context) => {
	if(state["inboxPreview"]==false || (state["previewInbox"]==false && context==CTX_NEW) || (state["preview.my-errands"]==false && context==CTX_MY) || (state["previewSearch"]==false && context==CTX_SEARCH) ) {
		return false;
	}else{
		return true;
	}
};

const getPreviewDelay = (state, context) => {
	if(!state["inboxPreviewShowInstantly"] && state["inboxPreview"]) {
		return state["inboxPreviewTimeDelay"];
	}
}

const getPreviewInstant = (state, context) => {
	return state["inboxPreviewShowInstantly"];
}
export const getPreviewFeatures = createSelector(
	[
		getSettings,
		getPreviewInstant,
		getPreviewDelay
	],
	(activate, instant, delaytime) => {
		return {
			activate: activate, instant: instant , delaytime: delaytime
		};
	}
);

export const getWorkflowSettingsData = wfSettingsData;

export const workflowSettingsSelector = createSelector(
	getWorkflowSettingsData
	, data => {
		if (!data) {
			return emptyObject;
		}
		return data;
	}
);

export const getCollabSlackEnabled = createSelector(
	getWorkflowSettingsData,
	data => {
		if(!data){
			return false
		}
		return data.collabSlackEnabled;
	}
);

export const getCollabJiraEnabled = createSelector(
	getWorkflowSettingsData,
	data => {
		if(!data){
			return false
		}
		return data.collabJiraEnabled;
	}
);

export const getCollabMSTeamEnabled = createSelector(
	getWorkflowSettingsData,
	data => {
		if(!data){
			return false
		}
		return data.collabMSTeamEnabled;
	}
);

export const getCollabGoogleChatEnabled = createSelector(
	getWorkflowSettingsData,
	data => {
		if(!data){
			return false
		}
		return data.collabGoogleChatEnabled;
	}
);

export const ckeditorSettings = noSelector(
	workflowSettingsSelector
	, ({ answerWysiwyg }) => answerWysiwyg ? answerWysiwyg : emptyObject
);

export const getAreasSelector = createSelector(
	getWorkflowSettingsData,
	data => {
		if(!data || !data.areas) {
			return false;
		}
		return data.areas;
	}
);

function getAreasState(state) {
	return state.app.workflow.areas;
}

export const activeAreaListSelector = createSelector(
	getAreasState
	, ({data}) => {
		if (!data || !data.areas || !data.areas.length) {
			return emptyAllActive;
		}
		let areas = [];
		data.areas.forEach(organisation => {
			if(organisation.Active && organisation.Areas) {
				organisation.Areas.forEach(area => {
					areas.push({
						'Id': area.Id,
						'Name': organisation.Name+" - "+area.Name
					});
				});
			}
		});
		return areas;
	}
);

function getOrganizationViewAreasState(state) {
	return state.app.workflow.organizationOverviewAreas;
}

export const organizationOverviewAreasSelector = createSelector(
	getOrganizationViewAreasState
	, ({data}) => {
		if (!data || !data.areas) {
			return emptyArray;
		}
		//For safety, avoid organization that have no Areas
		let validAreas = [], orgAreas = data.areas;
		if(orgAreas) {
			orgAreas.forEach(a => {
				if(a.Areas && a.Areas.length > 0) {
					validAreas.push(a);
				}
			});
		}
		return validAreas;
	}
);

function getAgentAreasState(state) {
	return state.app.workflow.agentAreas;
}

function getConnectedAgentAreasState(state) {
	return state.app.workflow.connectedAgentAreas;
}

function getStatisticsAgentAreasState(state) {
	return state.app.workflow.statisticsAgentAreas;
}

function orgAreasCreation(data, orgVerifier, areaVerifier) {
	const result = [];
	data.forEach(ca => {
		if (orgVerifier(ca)) {
			const ao = update(ca, {Areas: {$set: []}});
			if (ca.Areas) {
				ca.Areas.forEach(a => {
					if (areaVerifier(a)) {
						ao.Areas.push(a);
					}
				});
			}
			result.push(ao);
		}
	});
	return result;
}

const passActive = ({ Active }) => Active;

export function allAndOnlyActiveOrgAreas(orgAreas, param) {
	//For safety, avoid organization that have no Areas
	let validAreas = [];
	if (orgAreas) {
		orgAreas.forEach(a => {
			if(a.Areas && a.Areas.length > 0) {
				validAreas.push(a);
			}
		});
	}
	if (!param || !param.includeInactive) {
		return {all: validAreas, active: validAreas};
	}
	return {
		all: validAreas
		, active: orgAreasCreation(validAreas, passActive, passActive)
	};
}

export const areaListSelector = createSelector(
	getAgentAreasState
	, ({ data, param }) => {
		if (!data || !data.areas || !data.areas.length) {
			return emptyAllActive;
		}
		return allAndOnlyActiveOrgAreas(data.areas, param);
	}
);

export const connectedAreaListSelector = createSelector(
	getConnectedAgentAreasState
	, ({ data, param }) => {
		if (!data || !data.areas || !data.areas.length) {
			return emptyAllActive;
		}
		return allAndOnlyActiveOrgAreas(data.areas, param);
	}
);

export const statisticsAreaListSelector = createSelector(
	getStatisticsAgentAreasState
	, ({ data, param }) => {
		if (!data || !data.areas || !data.areas.length) {
			return emptyAllActive;
		}
		return allAndOnlyActiveOrgAreas(data.areas, param);
	}
);

export const allAreasSelector = createSelector(
	areaListSelector
	, ({ all }) => all
);

export const allConnectedAreasSelector = createSelector(
	connectedAreaListSelector
	, ({ all }) => all
);

export const onlyActiveAreasSelector = createSelector(
	areaListSelector
	, ({ active }) => active
);

export const onlyActiveConnectedAreasSelector = createSelector(
	connectedAreaListSelector
	, ({ active }) => active
);

const alwaysTrue = () => true

export const contextAwareActiveAreasMemo = createSelector(
	onlyActiveAreasSelector
	, contextMemo
	, wfSettingsData
	, (activeAreas, context, wfData) => {
		if (context !== CTX_REVIEW) {
			return activeAreas;
		}
		return orgAreasCreation(
			activeAreas
			, alwaysTrue
			, ({ Id }) => reviewAreaMap(wfData)[Id]
		);
	}
);

export const contextAwareConnectedActiveAreasMemo = createSelector(
	onlyActiveConnectedAreasSelector
	, contextMemo
	, wfSettingsData
	, (activeAreas, context, wfData) => {
		if (context !== CTX_REVIEW) {
			return activeAreas;
		}
		return orgAreasCreation(
			activeAreas
			, alwaysTrue
			, ({ Id }) => reviewAreaMap(wfData)[Id]
		);
	}
);

function getSearchFilterInactive(state) {
	return state.app.search.ui.filters.showInactive;
}

function allOrOnlyActive({all, active}, showAll) {
	if (showAll) {
		return all;
	}
	return active;
}

function makeAllOrActiveCreateSelector(dataSelector, inactiveSelector) {
	return createSelector(
		dataSelector
		, inactiveSelector
		, (data, showInactive) => allOrOnlyActive(data, showInactive)
	);
}

export const filterAreaSelector = makeAllOrActiveCreateSelector(
	statisticsAreaListSelector
	, getSearchFilterInactive
);

export const filterAgentSelector = makeAllOrActiveCreateSelector(
	agentListSelector
	, getSearchFilterInactive
);

export const getAllowLightBulbOff = createSelector(
	getWorkflowSettingsData,
	data => {
		if(!data) {
			return false;
		}
		return !!data['external-experts.show-turn-light-off'];
	}
);

export const myErrandFolderMemoize = createSelector(
	getWorkflowSettingsData,
	wfSettings => {
		const folders = wfSettings.userFolders;
		for(let i=0; i<folders.length; i++) {
			const folder = folders[i];
			if(folder.value === CTX_MY) {
				return folder.id;
			}
		}
		return 0;
	}
);

const getWorkflowCollaborationQueryRecipients = state => state.app.workflow.wfExpertQueries.domain;

export const getCollaborationInfoSelector = createSelector(
	getWorkflowCollaborationQueryRecipients,
	recipients => {
		if(!recipients) {
			return;
		}
		let names = [];
		if(recipients.externalRecipients) {
			$.each(recipients.externalRecipients, (i,v) => {
				if(v.name) {
					names.push(v.name);
				} else {
					names.push(v.email);
				}
			});
		}
		if(recipients.internalRecipients) {
			$.each(recipients.internalRecipients, (i,v) => {
				names.push(v.name);
			});
		}
		return names.join(', ');
	}
);

const defAttachFileOptions = {single: false, multiple: false};

export const attachFileOptionsSelector = createSelector(
	getWorkflowSettingsData,
	wfSettings => {
		if(!wfSettings) {
			return defAttachFileOptions;
		}
		return {
			single: wfSettings.agentAttachFile,
			multiple: wfSettings.agentAttachMultipleFile
		};
	}
);

function workflowFilter(state) {
	return getWorkflowFilter(state);
}

const getFilteredAreas = state => {
	return workflowFilter(state).filteredAreas;
}

export const getVIPListCounters = state => {
	const list = getErrandList(state);
	if (list.data) {
		return list.data.totalVIPCount;
	} else {
		return 0;
	}
}

export const getCurrentAreasSelector = createSelector(
	contextAwareConnectedActiveAreasMemo
	, getFilteredAreas
	, (areas, filteredAreas) => {
		if (filteredAreas.length <= 0) {
			return areas;
		}
		const orgList = [];
		$.each(areas, (k, { Areas, Id, Name }) => {
			const passArea = [];
			$.each(filteredAreas, (index, area) => {
				if (area.parent == Id) {
					if (k && typeof Areas !== "undefined") {
						$.each(Areas, (i, ar) => {
							if (ar && ar.Name === area.Name) {
								passArea.push({Id: area.Id, Name: area.Name});
							}
						});
					}
				}
			});
			orgList.push({Id, Name, Areas: passArea});
		});
		return orgList;
	}
);

function getErrandListCounters(state) {
	const list = getErrandList(state);
	if (list.data) {
		return list.data.counters;
	}
}

function getSelectedAgentFilter(state) {
	return workflowFilter(state).selectedAgent;
}

const tagCountersAgentSelector = createSelector(
	[
		contextMemo,
		getSelectedAgentFilter,
		getMyId
	],
	(ctx, agent, myID) => {
		if (ctx === CTX_NEW || ctx === VIP_ERRANDS) {
			return 0;
		} else if (agent > 0) {
			return agent;
		}
		return myID;
	}
);

function agentsDefaultFolder(state) {
	const counters = getErrandListCounters(state);
	if (counters) {
		return counters.agentsDefaultFolder;
	}
}

export function getSelectedFolderFilter(state) {
	return workflowFilter(state).selectedFolder;
}

export const myDefaultFolderMemoize = createSelector(
	getWorkflowSettingsData,
	data => {
		if (!data || !data.userFolders || !data.userFolders.length) {
			return 0;
		}
		let found, value;
		$.each(data.userFolders, (i,v) => {
			if (v.value === DEFAULT_FOLDER_NAME) {
				found = true;
				value = v.id;
				return false;
			}
		});
		if (!found) {
			return 0;
		}
		return value;
	}
);

const tagCountersFolderSelector = createSelector(
	[
		contextMemo,
		getSelectedAgentFilter,
		getMyId,
		agentsDefaultFolder,
		getSelectedFolderFilter,
		myDefaultFolderMemoize
	],
	(ctx, agent, myID, defaultFolders, folder, myDefaultFolder) => {
		if (ctx === CTX_NEW || ctx === VIP_ERRANDS) {
			return 0;
		} else if (agent > 0 && agent != myID) {
			if (!defaultFolders) {
				return 0;
			}
			return defaultFolders[agent];
		} else if (folder > 0) {
			return folder;
		}
		return myDefaultFolder;
	}
);

function counterAgentsFoldersTags(state) {
	const counters = getErrandListCounters(state);
	if (counters) {
		return counters.byAgentsAreasFoldersTags;
	}
}

function counterAgentsFoldersAreas(state) {
	let myId = getMyId(state), agent = getSelectedAgentFilter(state);
	const counters = getErrandListCounters(state);
	if (counters) {
		if(agent === 0 || agent === myId){
			return counters.byAgentsAreasFolders;
		}else{
			return counters.byAgentsAreasDefaultFolders;
		}
	}
}

export const selectedFilterAreasMemoize = createSelector(
	getFilterSelectedAreas
	, currentContextFromInputList
	, (areas, context) => {
		const a = areas[context];
		if (typeof a === "undefined") {
			return emptyObject;
		}
		return a;
	}
);

const selectFilterAreaArray = createSelector(
	selectedFilterAreasMemoize
	, areas => {
		let result = [];
		$.each(areas, (k, v) => {
			if (v) {
				result.push(k);
			}
		});
		if (!result.length) {
			return emptyArray;
		}
		return result;
	}
);

const tagCountersSelector = createSelector(
	[
		counterAgentsFoldersTags,
		tagCountersAgentSelector,
		selectFilterAreaArray,
		tagCountersFolderSelector
	],
	(counters, agent, areas, folder) => {
		if (!counters) {
			return;
		}
		return mapsSum(counters, [agent, areas, folder]);
	}
);

function getTagInfo(state) {
	return state.domain[D_TAGS_INFO].byId;
}

const emptyTag = [];

export const tagsSelector = makeTagsSelector(getAdminTagList);

export const vipTagsSelector = createSelector(
	tagsSelector
	, (tags) => {
		if(tags && tags.length > 0) {
			let vipTagsArr = [], vipTagStr = "";
			$.each(tags, (k, v) => {
				if(v.tagVIP) {
					vipTagsArr.push(v.tagId);
				}
			});
			vipTagStr = vipTagsArr.join(",");
			return vipTagStr;
		}
		return "";
	}
);

export const tagsFilterSelector = createSelector(
	[
		vipTagsSelector,
		tagCountersSelector,
		getTagInfo,
		contextMemo
	],
	(vipTags, tagCounters, tagsInfo, currentContext) => {
		let newTagCounter = {};
		if (!tagCounters) {
			return emptyTag;
		}
		if(currentContext === VIP_ERRANDS) {
			let vipTagsArr = vipTags.split(',').map(Number);
			$.each(vipTagsArr, (k, tag) => {
				if(tagCounters.hasOwnProperty(tag)) {
					newTagCounter[tag] = tagCounters[tag];
				}
			});
		}else {
			newTagCounter =  tagCounters;
		}
		let tags = [];
		$.each(newTagCounter, (k, count) => {
			if (k == 0) {
				return;
			}
			const info = tagsInfo[k], id = k;
			let name;
			if (info) {
				name = info.display;
			} else {
				name = "" + k;
			}
			tags.push({id, name, count});
		});
		return tags;
	}
);

export const areaFilterSelector = createSelector(
	[
		counterAgentsFoldersAreas,
		tagCountersAgentSelector,
		tagCountersFolderSelector
	],
	(agentAreaFolder, agent, folder) => {
		return mapsSumLevel(agentAreaFolder, [agent, null, folder], 1);
	}
);

function counterAgentDefaultFolder(state) {
	const counters = getErrandListCounters(state);
	if (counters) {
		return counters.byAgentsAreasDefaultFolders;
	}
}

export const agentFilterSelector = createSelector(
	[
		counterAgentDefaultFolder
	],
	(counters) => {
		return mapsSumLevel(counters, [null, null], 0);
	}
);

function getServerChannels(state) {
	return state.server.services;
}

function serviceSelectorByField(field) {
	return (channels, errandChannels) => {
		let chs = [], chsFixed = [];
		$.each(channels[field], (k, v) => {
			if (v.name == 'Email' || v.name == 'Chat') {
				chsFixed.unshift({id: parseInt(k, 10), value: v.name});
			} else {
				chs.push({id: parseInt(k, 10), value: v.name});
			}
		});
		if (chs.length) {
			chs.sort(getSortOrder("value"));
		}
		$.each(chsFixed, (k, v) => {
			chs.unshift({id: v.id, value: v.value});
		});
		return chs;
	};
}

function getSortOrder(prop) {
    return function(i, j) {
        if (i[prop].trim() > j[prop].trim()) {
            return 1;
        } else if (i[prop].trim() < j[prop].trim()) {
            return -1;
        }
        return 0;
    }
}

function createServerChannelsSelector(field) {
	return createSelector(getServerChannels, serviceSelectorByField(field));
}

export const serverChannelsSelector = createServerChannelsSelector("byType");

export const serviceIDsSelector = createServerChannelsSelector("byId");

function makeTagsSelector(selector) {
	return makeArraySelector(selector, 'tags');
}

const getSimpleTags = makeTagsSelector(getAdminSimpleTagList);

function checkIncludeUntagged(state, props) {
	return props.includeUntagged;
}

const untaggedStr = I('(Untagged)')
	, removedtagStr = I('(Removed)')
	, untagged = {
		id: 0
		, name: untaggedStr
		, Tags: [
			{
				id: 0
				, name: untaggedStr
				, Tags: null
			},
			{
				id: -1
				, name: removedtagStr
				, Tags: null
			}
		]
	}
	;
export const simpleTagsSelector = createSelector(
	getSimpleTags
	, checkIncludeUntagged
	, (tags, includeUntagged) => {
		if (!includeUntagged) {
			return tags;
		}
		return update(tags, {$push: [untagged]});
	}
);

const selectedErrandsAreasSelector = createSelector(
	actionsSelectedErrandsSelector
	, selected => {
		if (!selected.length) {
			return emptyArray;
		}
		const results = []
			, exist = {}
			;
		$.each(selected, (i, { area }) => {
			if (!exist[area]) {
				exist[area] = true;
				results.push(area);
			}
		});
		if (!results.length) {
			return emptyArray;
		}
		return results;
	}
);

function getAreaForwardAgents(state) {
	return state.domain[D_FORWARD_AGENTS].byId;
}

const onlyActiveAgentsByIdSelector = createSelector(
	onlyActiveAgentsSelector
	, agents => {
		if (!agents || !agents.length) {
			return emptyObject;
		}
		const byId = {};
		$.each(agents, (i, v) => {
			byId[v.Id] = v;
		});
		byId.len = agents.length;
		return byId;
	}
);

const onlyActiveAgentsByIdWithoutMeSelector = createSelector(
	onlyActiveAgentsByIdSelector
	, getMyId
	, (agentsById, myId) => update(agentsById, {$unset: [myId]})
);

function getAreaFwdAgents(areaAgents, agents) {
	if (!areaAgents || !areaAgents.length) {
		return emptyArray;
	}
	const simpleAgents = []
		, exist = {}
		;
	$.each(areaAgents, (i, v) => {
		if (!exist[v]) {
			exist[v] = true;
			const agent = agents[v];
			if (agent && agent.Active) {
				// not allow forward to inactive user
				simpleAgents.push({id: agent.Id, value: agent.Name});
			}
		}
	});
	if (!simpleAgents.length) {
		return emptyArray;
	}
	return simpleAgents;
}

function getCommonAreaAgents(latestCommonAgents, areaFwdAgentsById, areas) {
	for (let aid = 1; aid < areas.length; aid++) {
		const areaAgents = areaFwdAgentsById[areas[aid]];
		if (!areaAgents) {
			return;
		}
		let common = []
			, remainNoMatchAgents = latestCommonAgents.slice()
			;
		$.each(areaAgents, (i, v) => {
			let found;
			$.each(remainNoMatchAgents, (index, w) => {
				if (v === w) {
					found = {index};
					common.push(w);
					return;
				}
			});
			if (found) {
				// try to avoid searching the same agent that confirm as common
				// agent.
				remainNoMatchAgents.splice(found.index, 1);
			}
		});
		if (!common.length) {
			// if any two areas have no common agents then no target agent can
			// be forwarded.
			return;
		}
		latestCommonAgents = common;
	}
	return latestCommonAgents;
}

export const forwardErrandsAgentsSelector = createSelector(
	onlyActiveAgentsByIdWithoutMeSelector
	, getAreaForwardAgents
	, selectedErrandsAreasSelector
	, getChatErrandSelected
	, (agents, byId, areas, hasSelectedChat) => {
		// console.log("dbg: selected areas:", {areas});
		if (hasSelectedChat || !agents.len || !areas.length) {
			return emptyArray;
		}
		let latestCommonAgents = byId[areas[0]];
		if (!latestCommonAgents) {
			return emptyArray;
		}
		if (areas.length > 1) {
			latestCommonAgents = getCommonAreaAgents(
				latestCommonAgents
				, byId
				, areas
			);
		}
		return getAreaFwdAgents(latestCommonAgents, agents);
	}
);

const getAreasAgentsDataWip = state => state.app.workflow.areasagentsdata.wip;

export const isLoadingFwdErrandsAgentsSelector = noSelector(
	getAreasAgentsDataWip
	, wip => wip
);

const searchResultErrands = state => state.app.search.results.errands;
const searchResultNewCount = state => state.app.search.results.totalAllErrand;
const searchResultMyCount = state => state.app.search.results.totalMyErrand;
const searchResultCloseCount = state => state.app.search.results.totalClosedErrand;

export const searchListSelector = createSelector(
	searchResultErrands,
	searchResultNewCount,
	searchResultMyCount,
	searchResultCloseCount,
	(searchErrands, newCount, myCount, closeCount) => {
		return {
			list: (searchErrands && searchErrands.length) ? searchErrands : [],
			all: newCount,
			my: myCount,
			close: closeCount
		};
	}
);

export const totalSearchResult = createSelector(
	getSearchResults
	, contextMemo
	, (searchResults, currentContext) => {
		let totalCount = searchResults.totalErrand;
		switch (currentContext) {
		case SEARCH_MY_ERRANDS:
			totalCount = searchResults.totalMyErrand;
			break;
		case SEARCH_NEW_ERRANDS:
			totalCount = searchResults.totalAllErrand;
			break;
		case SEARCH_CLOSE_ERRANDS:
			totalCount = searchResults.totalClosedErrand;
			break;
		}
		return totalCount;
	}
);

function normalizeOrgAreas(orgAreas) {
	const byArea = {}
	const byOrg = {}
	const areaIds = []
	const orgIds = []
	each(orgAreas, v => {
		const orgId = v.Id
		byOrg[orgId] = v
		orgIds.push(orgId)
		each(v.Areas, ({ Id }, index) => {
			areaIds.push(Id)
			byArea[Id] = { org: orgId, index }
		})
	})
	return { byArea, byOrg, areaIds, orgIds }
}

export const normalizedOrgAreasMemoize = createSelector(
	onlyActiveAreasSelector
	, orgAreas => normalizeOrgAreas(orgAreas)
);

export const selectedFilterAreaListStringMemoize = createSelector(
	selectFilterAreaArray
	, areas => areas.join(",")
);

export const contextAwareOrgAreasMemo = createSelector(
	contextAwareConnectedActiveAreasMemo
	, orgAreas => normalizeOrgAreas(orgAreas)
);

export const selectedFilterOrgsMemoize = createSelector(
	contextAwareOrgAreasMemo
	, selectedFilterAreasMemoize
	, ({ byArea, byOrg }, areasObject) => {
		let counterByOrgId = {}, orgs = {};
		$.each(areasObject, (k, v) => {
			if (v && byArea[k]) {
				const orgId = byArea[k].org
					, orgAreaSize = byOrg[orgId].Areas.length
					;
				let count = counterByOrgId[orgId];
				if (typeof count === "undefined") {
					count = 1;
				} else {
					count++;
				}
				counterByOrgId[orgId] = count;
				if (count >= orgAreaSize) {
					orgs[orgId] = AT_ACTIVE;
				} else {
					orgs[orgId] = AT_PARTIAL;
				}
			}
		});
		return orgs;
	}
);

export const openErrandExtraFieldsMemoize = createSelector(
	contextMemo
	, showMobileView
	, (context, mobile) => ({context, mobile})
);

const getWorkflowSlaTimeData = state  => state.app.workflow.getSlaTime.data;

export const getSlaTimeSelector = createSelector(
	getWorkflowSlaTimeData,
	data => {
		return data;
	}
);

const getWorkflowAgentTemplates = state => getWorkflowRoot(state).agentTemplates;

export const agentAreaTemplatesSelector = createSelector(
	getWorkflowAgentTemplates,
	agentTemplates => {
		if(!agentTemplates.data) {
			return [];
		}
		return agentTemplates.data;
	}
);

const uiListReady = state => getUI(state).listReady;
const filterContextChanged = state => getWorkflowFilter(state).contextChanged;

// not useable until fix the missing condition
export const getListViewData = createSelector(
	getErrandList,
	getErrandListChat,
	contextMemo,
	uiListReady,
	filterContextChanged,
	searchListSelector,
	(errandList, errandListChat, currentContext, listReady, contextChanged, searchList) => {
		let listViewData = [];
		// TODO: missing condition
		// let isExternalQueueChat = (typeof externalqueue !== "undefined" && externalqueue.isChat);
		// if (currentContext == MY_ERRANDS || isExternalQueueChat) {
		if (errandListChat && errandListChat.length > 0) {
			$.each(errandListChat, (i,chat) => {
				listViewData.push({
					data: chat.errand,
					chat: chat,
					selected: chat.ui.selected
				});
			});
		}
		if(!IsContextSearch(currentContext)){
			let errandListdata = errandList.data;
			if(errandListdata && errandListdata.order && errandListdata.order.length) { //&& !p.isExternal
				$.each(errandListdata.order, (i, v) => {
					const data = errandListdata.norm[v]
						, selected = errandListdata.opr[v] ? errandListdata.opr[v].selected : false
						;
					if (!listReady && contextChanged) {
						listViewData = [];
					} else {
						if (listReady || !contextChanged) {
							listViewData.push({
								data
								, selected
							});
						}
					}
				})
			}
		}
		else {
			let sd = searchList.list;
			if(sd && sd.length) {
				$.each(sd, (i,v) => {
					let lst = v;
					lst.collaboration = {status: false};
					lst.fromName = v.from;
					lst.serviceName = v.channel;
					lst.areaName = v.area;
					lst.date = v.createdTime;
					listViewData.push({
						data: {data: lst},
						selected: false
					});
				})
			}
		}
		return listViewData;
	}
)

//get incoming email errands (inbox/queue)
const incomingEmails = state => getWorkflowRoot(state).incomingEmailsList;
export const incomingFetchWIP = noSelector(
	incomingEmails
	, (emails) => {
		if(emails && emails.wip) {
			return true;
		}
		return false;
	}
)
export const queueEmailList = noSelector(
	incomingEmails
	, (emails) => {
		if(!emails.data || !emails.data.incomingMailErrands) {
			return emptyArray;
		}
		let qMails = [];
		let qList = emails.data.incomingMailErrands;
		if(qList && Object.keys(qList).length > 0) {
			$.each(qList,function(i, v){
				qMails.push({from: v.from, status: I("Waiting"), channel: "icon-v5-email"});
			});
		}
		return qMails;
	}
)

//get ongoing email errands (that opened by agent)
const activeEmails = state => getWorkflowRoot(state).allActiveEmailsErrands;
export const activeEmailsFetchWIP = noSelector(
	activeEmails
	, (emails) => {
		if(emails && emails.wip) {
			return true;
		}
		return false;
	}
)
export const activeEmailList = noSelector(
	activeEmails
	, (emails) => {
		if(!emails.data || !emails.data.activeErrandList) {
			return emptyArray;
		}
		const emailActiveIcon = "icon-v5-email";
		let activeMails = [];
		let activeList = emails.data.activeErrandList;
		let status = INPROGRESS_STATUS;
		if(activeList && Object.keys(activeList).length > 0) {
			$.each(activeList,function(i, v){
				if(v.state) {
					status = v.state
				}
				activeMails.push({from: v.from, group: v.areaName, agent: v.agentName, icon: emailActiveIcon, status: status});
			});
		}
		return activeMails;
	}
)

//to get agent queue (agent ready to work on email errands)
const activeAgents = state => getWorkflowRoot(state).allActiveAgents;
export const activeAgentsFetchWIP = createSelector(
	activeAgents
	, (agents) => {
		if(agents && agents.wip) {
			return true;
		}
		return false;
	}
)
export const getAllActiveAgents = createSelector(
	activeAgents
	, activeEmails
	, (allAgentsActive, emails) => {
		if(!allAgentsActive.data || !allAgentsActive.data.activeAgents) {
			return emptyArray;
		}
		const emailActiveIcon = "icon-v5-email";
		let agents = [];
		let allAgents = allAgentsActive.data.activeAgents;
		if(allAgents && allAgents.length > 0) {
			$.each(allAgents,function(i, v){
				if(!emails.data || !emails.data.activeErrandList) {
					return emptyArray;
				}
				let status = AWAY_STATUS, icon = "";
				if(v.status == "available") {
					icon = "";
					status = ONLINE_STATUS;
				} else {
					status = AWAY_STATUS; //todo: includes custom status (sip, dnd, etc)
				}

				let allEmails = emails.data.activeErrandList;
				if(allEmails && allEmails.length > 0) {
					$.each(allEmails, (i, m) => {
						if(m.agent == v.id) { //here check if any agent working on that
							icon = emailActiveIcon;
							if(m.state) {
								status = m.state
							}
							agents.push({from: m.from, group: v.areas, agent: v.name, icon: emailActiveIcon, status: status, channel: emailActiveIcon});
						} else {
							agents.push({from: "", group: v.areas, agent: v.name, icon: icon, status: status, channel: emailActiveIcon});
						}
					})
				} else {
					//if no one else working on any errands
					agents.push({from: "", group: v.areas, agent: v.name, icon: icon, status: status, channel: emailActiveIcon});
				}
			})
		}
		return agents;
	}
)


//Group queue
export const emailGroupQueue = createSelector(
	activeAgents
	, activeEmails
	, incomingEmails
	, (allAgentsActive, emails, incoming) => {
		if(!allAgentsActive.data || !allAgentsActive.data.activeAgents) {
			return emptyArray;
		}
		let groups = [], agentsArr = [];
		let areaData  = {};
		let allAgents = allAgentsActive.data.activeAgents;
		if(allAgents && allAgents.length > 0) {
			$.each(allAgents,function(i, v){
				agentsArr.push(v);
				if(v.areaInfo) {
					var areaObj = JSON.parse(v.areaInfo);
					if(areaObj && typeof areaObj == "object") {
						for (const key of Object.keys(areaObj)) {
							let id = areaObj[key].id;
							areaData[id] = {area: areaObj[key], agent: agentsArr, errands: []};
							continue;
						}
					}
				}
			});
		}
		let areasArr = [];
		if(areaData !== emptyObject) {
			Object.entries(areaData).map(item => {
				areasArr.push(item[1]);
				let areaId = item[1].area.id;
				if(!emails.data || !emails.data.activeErrandList) {
					return emptyArray;
				}
				let allEmails = emails.data.activeErrandList;
				if(allEmails.length > 0) {
					for(let i = 0; i<allEmails.length; i++){
						let em = allEmails[i];
						if(areaId === em.area) {
							item[1].errands.push(em);
						}
					}
				}
			});
		}
		const emailActiveIcon = "icon-v5-email";
		for(let i = 0; i< areasArr.length; i++){
			let a = areasArr[i];
				let areaName = a.area ? a.area.name : "";
				let activeErrands = a.errands ? a.errands.length : 0;
				let activeAgents = a.agent ? a.agent.length : 0;
				let cTxt = "";
				if(activeAgents > 1) {
					cTxt = I("agents online");
				} else {
					cTxt = I("agent online");
				}
				let queuedErrands = 0;
				if(activeAgents > 0) {
					if(incoming.data && incoming.data.incomingMailErrands) {
						let incomingErrands = incoming.data.incomingMailErrands;
						if(incomingErrands.length > 0) {
							for(let i = 0; i<incomingErrands.length; i++) {
								let em = incomingErrands[i];
								if(em.area == a.area.id) {
									queuedErrands++;
								}
							}
						}
					}
					groups.push({
						area: areaName,
						queue: queuedErrands,
						active: activeErrands,
						agents: activeAgents,
						customText: cTxt,
						channel: emailActiveIcon
					});
				}
		}
		return groups;
	}
)

//get workflow launchpad data
export const launchpadWidgetSettings = noSelector(
	workflowSettingsSelector
	, ({ launchpadWidgets }) => launchpadWidgets ? launchpadWidgets : emptyArray
);

//Default launchpad columns
const launchpadCols = [
	{ id: 1, header: "...", key: "channelIcon", type: "icon"},
	{ id: 2, header: I("Errand"), key: "displayId", type: 'postIcon'},
	{ id: 3, header: I("From"), key: "from"},
	{ id: 4, header: I("Subject"), key: "subject", width: '100%'}
];

const getWidgetColumnInfo = (widgetId, widgetSettings) => {
	let columns = [];
	const widget = widgetSettings.find(w => w.dataType === widgetId);
	if(typeof widget != "undefined" && widget.showColumns != "") {
		//use the settings
		const cols = widget ? widget.showColumns : "";
		const colsArr = cols.split(",");
		colsArr.map(col => {
			const columnsData = launchpadCols.find(c => c.id === parseInt(col));
			columns.push(columnsData);
		})
	} else {
		//use default columns
		columns = launchpadCols;
	}
	return columns;
}

const getWidgetMaxResultInfo = (widgetId, widgetSettings) => {
	const widget = widgetSettings.find(w => w.dataType === widgetId);
	return widget ? widget.maxResults : 10;
}

const dueErrands = state => getWorkflowRoot(state).allDueErrandsInAWeek;
export const dueErrandsSelector = createSelector(
	dueErrands
	, launchpadWidgetSettings
	, (errands, widgetSettings) => {
		const widgetId = 1;
		let dueData = [];
		let columns = [];
		if(errands && errands.data) {
			const data = errands.data;
			const maxData = getWidgetMaxResultInfo(widgetId, widgetSettings);
			if(data.dueErrands && data.dueErrands.length > 0) {
				dueData = data.dueErrands;
				dueData = dueData.slice(0, maxData);
				$.each(dueData,function(i, v){
					v.channelIcon = DEFAULT_ERRAND_TYPE_ICONS[v.channel];
					if(v.postponeDate) {
						const postponeDate = moment.unix(v.postponeDate).format('YYYY/MM/DD h:mm a')
						v.iconTitle = postponeDate;
						v.icon = "icon-calendar-plus";
					}
				});
			}
			if (process.env.NODE_ENV !== 'production') {
				if(data.error != null) {
					console.log("Fetch due errands error -> ", data.err);
				}
			}
			columns = getWidgetColumnInfo(widgetId, widgetSettings);
		}
		return {cols: columns, data: dueData };
	}
)

const incomingCollabs = state => getWorkflowRoot(state).allIncomingCollabs;
export const incomingCollabsSelector = createSelector(
	incomingCollabs
	, launchpadWidgetSettings
	, (errands, widgetSettings) => {
		const widgetId = 2;
		let collabData = [];
		let columns = [];
		if(errands && errands.data) {
			const data = errands.data;
			const maxData = getWidgetMaxResultInfo(widgetId, widgetSettings);
			if(data.incomingCollabs && data.incomingCollabs.length > 0) {
				collabData = data.incomingCollabs;
				collabData = collabData.slice(0, maxData);
				$.each(collabData,function(i, v){
					v.channelIcon = DEFAULT_ERRAND_TYPE_ICONS[v.channel];
				});
			}
			if (process.env.NODE_ENV !== 'production') {
				if(data.error != null) {
					console.log("Fetch collaboration requests error -> ", data.err);
				}
			}
			columns = getWidgetColumnInfo(widgetId, widgetSettings);
		}
		return {cols: columns, data: collabData };
	}
)

const forwardedErrands = state => getWorkflowRoot(state).allForwardedErrands;
export const forwardedErrandsSelector = createSelector(
	forwardedErrands
	, launchpadWidgetSettings
	, (errands, widgetSettings) => {
		const widgetId = 3;
		let forwardData = [];
		let columns = [];
		if(errands && errands.data) {
			const data = errands.data;
			const maxData = getWidgetMaxResultInfo(widgetId, widgetSettings);
			if(data.forwardedErrands && data.forwardedErrands.length > 0) {
				forwardData = data.forwardedErrands;
				forwardData = forwardData.slice(0, maxData);
				$.each(forwardData,function(i, v){
					v.channelIcon = DEFAULT_ERRAND_TYPE_ICONS[v.channel];
				});
			}
			if (process.env.NODE_ENV !== 'production') {
				if(data.error != null) {
					console.log("Fetch forwarded errands error -> ", data.err);
				}
			}
			columns = getWidgetColumnInfo(widgetId, widgetSettings);
		}
		return {cols: columns, data: forwardData };
	}
)

const expiringErrands = state => getWorkflowRoot(state).allExpiringErrands;
export const expiringErrandsSelector = createSelector(
	expiringErrands
	, launchpadWidgetSettings
	, (errands, widgetSettings) => {
		const widgetId = 4;
		let expErrands = [];
		let columns = [];
		if(errands && errands.data) {
			const data = errands.data;
			const maxData = getWidgetMaxResultInfo(widgetId, widgetSettings);
			if(data.expiredErrands && data.expiredErrands.length > 0) {
				expErrands = data.expiredErrands;
				expErrands = expErrands.slice(0, maxData);
				$.each(expErrands,function(i, v){
					v.channelIcon = DEFAULT_ERRAND_TYPE_ICONS[v.channel];
				});
			}
			if (process.env.NODE_ENV !== 'production') {
				if(data.error != null) {
					console.log("Fetch expiring errands error -> ", data.err);
				}
			}
			columns = getWidgetColumnInfo(widgetId, widgetSettings);
		}
		return {cols: columns, data: expErrands };
	}
)
