// NOTE: use suffix Selector to mean function with selector pattern. But
// everyone should make their selector standardize.
// use suffix Memoize to mean special type of selector where the result
// independent of props. Memoize can be used Redux async action too.
import { createSelector } from 'reselect';
import update from 'immutability-helper';
import { I } from '../../common/v5/config.js';
import {
	AT_ARCHIVE,
	AT_UPLOADED,
	BTN_CLEAR,
	BTN_CREATE,
	BTN_SEND,
	CH_AREA_LINK,
	CTX_MY,
	D_AREAS,
	DEF_MANUAL_BUTTONS_ORDER,
	MANUAL_CHECKBOX_OPTS,
	MANUAL_CHECKBOXES_ORDER,
	ME_CREATE,
	ME_CREATE_ALLOWED_CHANNELS,
	ME_CREATE_AS_CLOSED,
	ME_START,
	ME_NOTE,
	ME_ST_BUSY,
	ME_ST_CREATED,
	MP_CALL,
	MP_BASIC_CALL,
	MP_NONE,
	OPT_CHANNELS,
	RC_EMAIL,
	RC_SMS,
	RC_CHAT,
	RC_FACEBOOK,
	RC_LINE,
	RC_LINKEDIN,
	RC_FB_MESSENGER,
	RC_TWITTER,
	RC_VK,
	RC_VOICE,
	UI_SHOW_MANUAL_ERRAND,
	UNSELECT,
	ERRAND_HOTKEYS,
	CLEAR_ANSWER,
	SEND,
	AT_LIBRARY,
	AT_SAVED,
	emptyArray,
	emptyObject,
	ME_CREATE_AS_NEW,
	ME_CREATE_AS_MY,
	MP_MINIMIZE,
	UI_SHOW_MANUAL_CALL,
	BTN_TXT_SAVING,
	BTN_TXT_SAVE,
	UI_SHOW_BULK_SEND_POPUP
} from '../../common/v5/constants';
import {
	recipientsValue,
	getHotkeyChar
} from '../../common/v5/helpers.js';
import { noSelector } from './common';
import {
	outboundCallSid
	, outboundErrandId
	, outboundPhonesByArea
	, selectedPhoneMemoize
	, sipMakeCallCurrentErrand
} from './call';
import { getAreasSelector } from './workflow'

export const showManualSelector = state =>
	state.app.workflow.ui[UI_SHOW_MANUAL_ERRAND];

export const showManualCallSelector = state =>
	state.app.workflow.ui[UI_SHOW_MANUAL_CALL];

export const showBulkSendSelector = state =>
	state.app.workflow.ui[UI_SHOW_BULK_SEND_POPUP];

export const isManualPopup = state => showManualSelector(state) !== MP_NONE;
export const isManualCallPopup = state => showManualCallSelector(state) !== MP_NONE;

function enableSMS(state) {
	const featureData = state.app.workflow.fetchWfSettings.data;
	if (featureData) {
		return featureData.enableSMS;
	}
	return false;
}

function checkNewErrandMenu(state) {
	const featureData = state.app.workflow.fetchWfSettings.data;
	if (featureData) {
		return featureData["hide-new-errands-menu"];
	}
	return false;
}

function newManualErrandInMyErrands(state) {
	const featureData = state.app.workflow.fetchWfSettings.data;
	if (featureData) {
		return featureData.manualErrandInMyErrands;
	}
	return false;
}
export function getManualState(state) {
	return state.app.errand.ui.manual.state;
}

export function getManualCallState(state) {
	return state.app.errand.ui.manualCall.state;
}

export function getManualCreatedId(state) {
	if(isCallMemoize(state)){
		return state.app.errand.ui.manualCall.createdId;
	}
	return state.app.errand.ui.manual.createdId;
}

export function getCallCreatedId(state) {
	return state.app.errand.ui.manualCall.createdId;
}

export function getSelectedManualTab(state) {
	if(isCallMemoize(state)){
		return state.app.errand.ui.manualCall.tab;
	}
	return state.app.errand.ui.manual.tab;
}

export function getSelectedManualCurrentReply(state) {
	if(isCallMemoize(state)){
		return state.app.errand.ui.manualCall.currentReply;
	}
	return state.app.errand.ui.manual.currentReply;
}

function getSelectedManualCreation(state) {
	if(isCallMemoize(state)){
		return state.app.errand.ui.manualCall.createAs;
	}
	return state.app.errand.ui.manual.createAs;
}

export function getManualErrandInputs(state) {
	if(isCallMemoize(state)){
		return state.app.errand.manualCallInputs;
	}
	return state.app.errand.manualInputs;
}

export function getManualCallInputs(state) {
	return state.app.errand.manualCallInputs;
}

function isAventaEnabled(state) {
	if (state.app.workflow.fetchWfSettings.data){
		return state.app.workflow.fetchWfSettings.data.aventaEnabled;
	}
	return false
}

function isSipEnabled(state) {
	if (state.app.workflow.fetchWfSettings.data){
		return state.app.workflow.fetchWfSettings.data.sipEnabled;
	}
	return false
}

export const isCallMemoize = noSelector(
	showManualCallSelector
	, show => {
		if(show === MP_CALL || show === MP_BASIC_CALL ) {
			return true;
		} else {
			return false;
		}
	}
);

//fixme @sue check minimize mode too
export const manualErrandSelectedAreaSelector = createSelector(
	isCallMemoize,
	showManualCallSelector,
	getManualErrandInputs,
	getManualCallInputs
	, (isCall, manualUIMode, inputs, callInputs) => {
		if(isCall || manualUIMode === MP_MINIMIZE) {
			return callInputs.area;
		}
		return inputs.area;
	}
);

const phoneOrgAreasMemoize = createSelector(
	getAreasSelector
	, outboundPhonesByArea
	, (orgAreas, phonesByArea) => {
		if (!orgAreas || !phonesByArea) {
			return false;
		}
		const phoneOrgAreas = [], normalized = {};
		$.each(orgAreas, (i, org) => {
			let areas;
			$.each(org.Areas, (j, area) => {
				const { Id } = area;
				if (typeof phonesByArea[Id] !== "undefined") {
					normalized[Id] = area;
					if (areas) {
						areas.push(area);
					} else {
						areas = [area];
					}
				}
			});
			if (areas) {
				phoneOrgAreas.push(update(org, {Areas: {$set: areas}}));
			}
		});
		if (!phoneOrgAreas.length) {
			return false;
		}
		return {array: phoneOrgAreas, object: normalized};
	}
);

export const getManualErrandAreasMemoize = createSelector(
	getAreasSelector
	, isCallMemoize
	, phoneOrgAreasMemoize
	, showManualCallSelector
	, (orgAreas, isCall, phoneOrgAreas, showCall) => {
		if (!orgAreas) {
			return false;
		} else if (!isCall || showCall === MP_BASIC_CALL) {
			return orgAreas;
		} else if (!phoneOrgAreas) {
			return false;
		}
		return phoneOrgAreas.array;
	}
);

const selectedAreaPhonesMemoize = createSelector(
	phoneOrgAreasMemoize
	, manualErrandSelectedAreaSelector
	, (phoneOrgAreas, selectedArea) => {
		if (!phoneOrgAreas) {
			return false;
		}
		const areaPhone = phoneOrgAreas.object[selectedArea];
		if (typeof areaPhone === "undefined") {
			return false;
		}
		return areaPhone;
	}
);

// Check if the selected area valid. If current popup not call popup then it is
// considered as valid. Can not base on isCallMemoize because it will be used by
// action that change isCallMemoize.
export const isSelectedAreaValidForCallMemoize = noSelector(
	selectedAreaPhonesMemoize
	, areaPhone => !!areaPhone
);

const getDomainAreas = state => state.domain[D_AREAS];

export const manualErrandSelectedAreaData = createSelector(
	[
		manualErrandSelectedAreaSelector,
		getDomainAreas
	],
	(areaID, domainAreas) => {
		if(areaID <= 0) {
			return;
		}
		return domainAreas.byId[areaID];
	}
);

export const manualErrandSelectedReplyChannel = createSelector(
	getManualErrandInputs
	, isCallMemoize
	, (inputs, isCall) => {
		if (isCall) {
			return RC_VOICE;
		}

		return inputs.reply_channel;
	}
);



function getSelectedAccount(state) {
	return state.app.errand.manualInputs.selected_indexes.account;
}

const manualErrandInvalidAccount = createSelector(
	manualErrandSelectedReplyChannel
	, getSelectedAccount
	, isCallMemoize
	, (channel, account, isCall)  => {
		if (channel === RC_EMAIL || channel === RC_SMS || isCall ||
			channel === RC_VOICE) {
			return false;
		} else if (account === UNSELECT) {
			return {reason: I('You must select an account.')};
		}
		return false;
	}
);

export function getManualInputUpdateTo(state) {
	if(isCallMemoize(state) || sipMakeCallCurrentErrand(state)) {
		return state.app.errand.manualCallInputs.update_to;
	}
	return state.app.errand.manualInputs.update_to;
}

export function getManualErrandTranslateTo(state) {
	if(isCallMemoize(state)) {
		return state.app.errand.manualCallInputs.translate_to;
	}
	return state.app.errand.manualInputs.translate_to;
}

const manualErrandInvalidFrom = createSelector(
	getSelectedManualTab
	, getSelectedManualCreation
	, getManualInputUpdateTo
	, manualErrandSelectedReplyChannel
	, isCallMemoize
	, (tab, createAs, updateTo, selectedReplyChannel, isCall) => {
		if ((tab === ME_CREATE && createAs === ME_CREATE_AS_CLOSED)
			|| updateTo.length
			|| isCall) {
			return false;
		} else if (tab === ME_CREATE) {
			return {reason: I("You can't leave the From field empty.")};
		} else if (selectedReplyChannel === RC_FACEBOOK
			|| selectedReplyChannel === RC_VK
			|| selectedReplyChannel === RC_TWITTER
			|| selectedReplyChannel === RC_LINKEDIN) {
			return false;
		}
		return {reason: I("You can't leave the To field empty.")};
	}
);

const invalidOutboundCallSession = createSelector(
	isCallMemoize
	, outboundCallSid
	, outboundErrandId
	, isAventaEnabled
	, isSipEnabled
	, (isCall, callSid, errandId, aventaEnabled, sipEnabled) => {
		if (isCall) {
			if(!aventaEnabled && !sipEnabled){
				if (!callSid) {
					return {
						reason: I("Require valid call session to create voice errand.")
					};
				} else if (errandId <= 0) {
					return {
						reason: I("Require valid errand id to create voice errand.")
					};
				}
			}
		}
		return false;
	}
);

const manualReasonButtonDisableSelector = createSelector(
	manualErrandSelectedAreaSelector
	, manualErrandInvalidAccount
	, manualErrandInvalidFrom
	, invalidOutboundCallSession
	, (areaID, invalidAccount, invalidFrom, invalidCallSession) => {
		let reason = '';
		if (areaID <= 0) {
			reason = I('You must select an area.');
		}
		if (invalidAccount) {
			reason += ' ' + invalidAccount.reason;
		}
		if (invalidFrom) {
			reason += ' ' + invalidFrom.reason;
		}
		if (invalidCallSession) {
			reason += ' ' + invalidCallSession.reason;
		}
		reason.trim();
		if (reason) {
			return {reason};
		}
		return false;
	}
);

const manualErrandRecipientTo = createSelector(
	getManualErrandInputs,
	inputs => recipientsValue(inputs, 'to')
);

const manualCallErrandRecipientTo = createSelector(
	getManualCallInputs,
	inputs => recipientsValue(inputs, 'to')
);

export const getOutboundPhoneTo = createSelector(
	isCallMemoize
	, manualErrandRecipientTo
	, (isCall, tos) => {
		if (!isCall || !tos.length) {
			return "";
		}
		return tos[0];
	}
);

export const getCallOutboundPhoneTo = createSelector(
	manualCallErrandRecipientTo
	, (tos) => {
		if (!tos.length) {
			return "";
		}
		return tos[0];
	}
);

const manualErrandRecipientCc = createSelector(
	getManualErrandInputs,
	inputs => recipientsValue(inputs, 'cc')
);

const manualErrandRecipientBcc = createSelector(
	getManualErrandInputs,
	inputs => recipientsValue(inputs, 'bcc')
);

export const manualErrandRecipientsSelector = createSelector(
	[
		manualErrandRecipientTo,
		manualErrandRecipientCc,
		manualErrandRecipientBcc,
	],
	(to, cc, bcc) => ({to, cc, bcc})
);

export const manualErrandAreaSignaturesSelector = createSelector(
	manualErrandSelectedAreaData,
	areaData => {
		if(!areaData) {
			return [];
		}
		let signatures = [];
		if (Array.isArray(areaData.signatures)) {
			signatures = areaData.signatures;
		}
		if (Array.isArray(areaData.user_signatures)) {
			signatures = signatures.concat(areaData.user_signatures);
		}
		return signatures;
	}
);

export const manualErrandAreaSalutationsSelector = createSelector(
	manualErrandSelectedAreaData,
	areaData => {
		if(!areaData) {
			return [];
		}
		let salutations = [];
		if (Array.isArray(areaData.salutations)) {
			salutations = areaData.salutations;
		}
		if (Array.isArray(areaData.user_salutations)) {
			salutations = salutations.concat(areaData.user_salutations);
		}
		return salutations;
	}
);

export const manualErrandAreaTemplatesSelector = createSelector(
	manualErrandSelectedAreaData,
	areaData => {
		if(!areaData || !areaData.templates) {
			return [];
		}
		return areaData.template_contents;
	}
);

export const manualErrandAreaTagsSelector = createSelector(
	manualErrandSelectedAreaData,
	areaData => {
		if(!areaData) {
			return []; // TODO: return false to detection of not ready
		}
		return areaData.normal_tags;
	}
);

export const manualErrandSelectedTagsSelector = createSelector(
	getManualErrandInputs,
	inputs => inputs.tags
);

export const addressBookSelector = createSelector(
	manualErrandSelectedAreaData,
	areaData => {
		if(!areaData) {
			return [];
		}
		return areaData.addressbook_addresses;
	}
);

export const manualRecipientsOptionsSelector = createSelector(
	addressBookSelector,
	addressBook => {
		let items = [];
		if(addressBook && addressBook.length) {
			$.each(addressBook, (i,v) => {
				if(v.value) {
					items.push({id: v.id, value: v.value + " ("+v.id+")"});
				} else {
					items.push({id: v.id, value: v.id});
				}
			});
		}
		return items.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
	}
);

function parseSocialAccountsValue(accounts, channel) {
	let acc = [];
	$.each(accounts, (i,v) => {
		const [id, accountId] = v.value.split('#');
		acc.push(update(v, {$merge: {id, accountId}}));
	});
	//Set default selected value for each channel
	let chooseTitle = OPT_CHANNELS[channel].manual.choose;
	acc.push({
		accountId: UNSELECT,
		id: UNSELECT,
		name: chooseTitle,
		value: channel
	});
	return acc;
}

const defaultChannels = [RC_EMAIL],
	emptyOption = [];

const manualErrandChannelsData = createSelector(
	[
		manualErrandSelectedAreaData,
		enableSMS
	],
	(areaData, hasSMS) => {
		if(!areaData) {
			return;
		}
		let channels = [];
		let socialChannels = {};

		$.each(CH_AREA_LINK, (k,v) => {
			const c = areaData[v];
			if(c && c.length && k !== RC_EMAIL) {
				socialChannels[k] = parseSocialAccountsValue(c,k);
			}

		});
		channels = defaultChannels.slice();
		if (hasSMS) {
			channels.push(RC_SMS);
		}
		channels.push(RC_VOICE);
		$.each(socialChannels, (k,v) => {
			if(v) {
				channels.push(k);
			}
		});
		return {channels, socialChannels};
	}
);

export const manualErrandChannelsSelector = createSelector(
	[
		manualErrandChannelsData,
		getSelectedManualTab
	],
	(channelsData, tab) => {
		if(!channelsData) {
			return defaultChannels;
		} else if (tab === ME_START) {
			return channelsData.channels;
		}
		// need to do filtering for manual creation which do not allow certain
		// channel.
		let allowChannels = [];
		$.each(channelsData.channels, (i,v) => {
			if (ME_CREATE_ALLOWED_CHANNELS[v]) {
				allowChannels.push(v);
			}
		});

		return allowChannels;
	}
);

const emptyPhoneData = {norm: emptyObject, order: emptyArray};

const selectedAreaPhoneListMemoize = createSelector(
	manualErrandSelectedAreaSelector
	, outboundPhonesByArea
	, (selectedArea, phonesByArea) => {
		if (!phonesByArea) {
			return emptyPhoneData;
		}
		const phoneList = phonesByArea[selectedArea];
		if (typeof phoneList === "undefined") {
			return emptyPhoneData;
		}
		return phoneList;
	}
);

export const manualErrandFromOptionsSelector = createSelector(
	isCallMemoize
	, selectedAreaPhoneListMemoize
	, manualErrandChannelsData
	, manualErrandSelectedReplyChannel
	, (isCall, areaPhones, channelsData, selectedReplyChannel) => {
		if (isCall) {
			return areaPhones;
		} else if (selectedReplyChannel === RC_EMAIL ||
			selectedReplyChannel === RC_SMS ||
			selectedReplyChannel === RC_VOICE) {
			// email channel is special as no force option
			return emptyOption;
		} else if (!channelsData) {
			return emptyOption;
		} else {
			return channelsData.socialChannels[selectedReplyChannel];
		}
	}
);

const CLEAR_STR = I('Clear'),
	CREATE_STR = I('Create'),
	DONE_STR = I('Done'),
	SEND_STR = I('Send'),
	SAVE_STR = BTN_TXT_SAVE,
	SENDING_STR = I('Sending...'),
	CREATING_STR = I('Creating...'),
	SAVING_STR = BTN_TXT_SAVING,
	disAndBusy = {disabled: {$set: true}, busy: {$set: true}},
	disOnly = {disabled: {$set: true}},
	defButtonState = {
		[BTN_CLEAR]: {text: CLEAR_STR, class: 'btn-grey', hotkey: getHotkeyChar(ERRAND_HOTKEYS,CLEAR_ANSWER)},
		[BTN_CREATE]: {text: CREATE_STR, class: 'btn-blue', hidden: true, hotkey: getHotkeyChar(ERRAND_HOTKEYS,SEND)},
		[BTN_SEND]: {text: SEND_STR, class: 'btn-blue', hotkey: getHotkeyChar(ERRAND_HOTKEYS,SEND)},
	};

const needCreateButton = noSelector(
	getSelectedManualTab
	, isCallMemoize
	, (selectedTab, isCall) => {
		//isCall now is not always about creation, needed re-review
		if (selectedTab === ME_CREATE || isCall) {
			return true;
		}
		return false;
	}
);

const getButtons = createSelector(
	showManualSelector
	, showManualCallSelector
	, isCallMemoize
	, isSipEnabled
	, getManualState
	, getManualCallState
	, needCreateButton
	, getManualCreatedId
	, manualReasonButtonDisableSelector
	, (show, showCall, isCall, isSip, manualErdState, callState, wantCreate, createdId, reason) => {
		let state = manualErdState;
		if(isCall) {
			state = callState;
		}
		if (state !== ME_ST_CREATED && state !== ME_ST_BUSY && !reason) {
			if (!wantCreate) {
				return defButtonState;
			}
			return update(defButtonState, {
				[BTN_CREATE]: {hidden: {$set: false}},
				[BTN_SEND]: {hidden: {$set: true}}
			});
		}
		if (state === ME_ST_CREATED) {
			if(isSip && isCall){
				return update(defButtonState, {
					[BTN_CLEAR]: {disabled: {$set: (showCall == MP_BASIC_CALL ? false : true)}},
					[BTN_SEND]: {hidden: {$set: true}},
				});
			}
			return update(defButtonState, {
				[BTN_CLEAR]: {disabled: {$set: (showCall == MP_BASIC_CALL ? false : true)}},
				[BTN_SEND]: {text: {$set: (showCall == MP_BASIC_CALL ? SAVE_STR : DONE_STR)}, hotkey: {$set: ""}},
			});
		} else if (state === ME_ST_BUSY) {
			if (wantCreate) {
				return update(defButtonState, {
					[BTN_CLEAR]: disAndBusy,
					[BTN_CREATE]: {
						busy: {$set: true},
						disabled: {$set: true},
						hidden: {$set: ((createdId && showCall == MP_BASIC_CALL) ? true : false)},
						text: {$set: CREATING_STR}
					},
					[BTN_SEND]: {
						busy: {$set: true},
						disabled: {$set: true},
						text: {$set: SAVING_STR},
						hidden: {$set: ((createdId && showCall == MP_BASIC_CALL) ? false : true)},
					}
				});
			} else {
				return update(defButtonState, {
					[BTN_CLEAR]: disAndBusy,
					[BTN_SEND]: {
						busy: {$set: true},
						disabled: {$set: true},
						text: {$set: SENDING_STR}
					}
				});
			}
		}
		// must be disabled for submission because of certain reason.
		if (wantCreate) {
			return update(defButtonState, {
				[BTN_CREATE]: {
					disabled: {$set: true},
					hidden: {$set: false},
					reason: {$set: reason.reason}
				},
				[BTN_SEND]: {hidden: {$set: true}}
			});
		} else {
			return update(defButtonState, {
				[BTN_SEND]: {
					disabled: {$set: true},
					reason: {$set: reason.reason}
				}
			});
		}
	}
);

export const manualButtonsSelector = createSelector(
	getButtons
	, buttons => ({buttons, order: DEF_MANUAL_BUTTONS_ORDER})
);

export const manualFileArchivesSelector = createSelector(
	manualErrandSelectedAreaData,
	area => {
		if(!area) {
			return emptyArray;
		}
		return area.file_archive_images;
	}
);

const defSerialAttachment = {
	lists: null, // purposely null
	size: 0
};

const archiveAttachments = createSelector(
	[getSelectedManualCurrentReply, getManualErrandInputs],
	(currentReply, inputs) => {
		return currentReply === ME_NOTE ? emptyArray : inputs.archive_attachments;
	}
)

const uploadedAttachments = createSelector(
	[getSelectedManualCurrentReply, getManualErrandInputs],
	(currentReply, inputs) => {
		return currentReply === ME_NOTE ? inputs.internal_comment_uploaded_attachments :
			inputs.uploaded_attachments;
	}
)

const kbAttachments = createSelector(
	[getSelectedManualCurrentReply, getManualErrandInputs],
	(currentReply, inputs) => {
		return currentReply === ME_NOTE ? emptyArray : inputs.library_attachments;
	}
)

const savedAttachments = createSelector(
	[getSelectedManualCurrentReply, getManualErrandInputs],
	(currentReply, inputs) => {
		return currentReply === ME_NOTE ? emptyArray : inputs.saved_attachments;
	}
)

const serialAttachments = createSelector(
	[
		archiveAttachments,
		uploadedAttachments,
		savedAttachments,
		kbAttachments
	],
	(archive, uploaded, saved, kb) => {
		if(!archive.length && !uploaded.length && !kb.length && !saved) {
			return defSerialAttachment;
		}
		const size = archive.length + uploaded.length + kb.length;
		return {
			lists: {
				[AT_ARCHIVE]: {list: archive},
				[AT_UPLOADED]: {list: uploaded},
				[AT_SAVED]: {list: saved},
				[AT_LIBRARY]: {list: kb}
			},
			size
		};
	}
);

// NOTE: Shuold return null when no attachments.
export const manualAttachmentsSelector = (state, props) => serialAttachments(state, props).lists;

export const manualAttachmentSizeSelector = (state, props) => serialAttachments(state, props).size;

const dummy = () => true;

const defCheckboxes = {
	checkboxes: MANUAL_CHECKBOX_OPTS,
	order: MANUAL_CHECKBOXES_ORDER
};

export const manualCheckboxesSelector = createSelector(
	dummy
	, showBulkSendSelector
	, (seletedTab, isBulkSend) => {
		if (isBulkSend) {
			return null;
		}
		return defCheckboxes;
	}
);

const manualCheckboxes = (state, props) => getManualErrandInputs(state, props).checkboxes;

export const manualCheckboxStatesSelector = createSelector(
	manualCheckboxes,
	checkboxes => checkboxes
);

export const selectedAccountMemoize = noSelector(
	isCallMemoize
	, selectedPhoneMemoize
	, getSelectedAccount
	, (isCall, phone, account) => isCall ? phone : account
);

export const selectedPhoneDataMemoize = noSelector(
	selectedPhoneMemoize
	, selectedAreaPhoneListMemoize
	, (phoneId, { norm }) => norm[phoneId]
);

export const manualErrandCreateAsSelector = createSelector(
	getSelectedManualCreation
	, checkNewErrandMenu
	, newManualErrandInMyErrands
	,
	(createAs, hideNewErrand, mErrandInMyErrands) => {
		if(createAs === "") {
			if (hideNewErrand || mErrandInMyErrands) {
				return ME_CREATE_AS_MY;
			} else {
				return ME_CREATE_AS_NEW;
			}
		}
		return createAs;
	}
);

export const manualCallMinimize = noSelector(
	showManualCallSelector
	,show => {
		if(show === MP_MINIMIZE) {
			return true;
		}
		return false;
	}
);
