// NOTE: use suffix Selector to mean function with selector pattern. But
// everyone should make their selector standardize.
// use suffix Memoize to mean special type of selector where the result
// independent of props. Memoize can be used Redux async action too.
import { createSelector } from 'reselect';
import update from 'immutability-helper';
import { I } from '../../common/v5/config.js';
import {
	BTN_CLEAR,
	BTN_CREATE,
	BTN_SEND,
	BTN_SAVE,
	DEF_MANUAL_BUTTONS_ORDER,
	MANUAL_CHECKBOX_OPTS,
	MANUAL_CHECKBOXES_ORDER,
	// ME_CREATE,
	// ME_CREATE_AS_CLOSED,
	// ME_START,
	ME_ST_BUSY,
	ME_ST_CREATED
} from '../../common/v5/constants';
import {
	recipientsValue
} from '../../common/v5/helpers.js';

function getManualState(state) {
	return state.app.internalMessage.ui.manual.state;
}

export function getManualIMInputs(state) {
	return state.app.internalMessage.manualInputs;
}


const IMRecipientTo = createSelector(
	getManualIMInputs,
	inputs => recipientsValue(inputs, 'to')
);

const IMRecipientGroup = createSelector(
	getManualIMInputs,
	inputs => recipientsValue(inputs, 'group')
);

const IMRecipientFrom = createSelector(
	getManualIMInputs,
	inputs => recipientsValue(inputs, 'from')
);

export const IMRecipientsSelector = createSelector(
	[
		IMRecipientTo,
		IMRecipientGroup,
		IMRecipientFrom
	],
	(to, group, from) => ({to, group, from})
);

const CLEAR_STR = I('Clear'),
	CREATE_STR = I('Create'),
	DONE_STR = I('Done'),
	SEND_STR = I('Send'),
	SENDING_STR = I('Sending...'),
	// CREATING_STR = I('Creating...'),
	SAVE_STR = I('Save'),
	disAndBusy = {disabled: {$set: true}, busy: {$set: true}},
	// disOnly = {disabled: {$set: true}},
	defButtonState = {
		[BTN_CLEAR]: {text: CLEAR_STR, class: 'btn-grey'},
		[BTN_CREATE]: {text: CREATE_STR, class: 'btn-blue', hidden: true},
		[BTN_SEND]: {text: SEND_STR, class: 'btn-blue'},
		[BTN_SAVE]: {text: SAVE_STR, class: 'btn-blue'}
	};

const getButtons = createSelector(
	[
		getManualState,
	],
	(state) => {
		if(state !== ME_ST_CREATED && state !== ME_ST_BUSY) {
			return update(defButtonState, {
				[BTN_CREATE]: {hidden: {$set: true}},
				[BTN_SEND]: {hidden: {$set: false}},
				[BTN_SAVE]: {hidden: {$set: false}}
			});
		}
		if(state === ME_ST_CREATED) {
			return update(defButtonState, {
				[BTN_CLEAR]: {disabled: {$set: true}},
				[BTN_SEND]: {text: {$set: DONE_STR}},
				[BTN_SAVE]: {disabled: {$set: true}}
			});
		} else if(state === ME_ST_BUSY) {
			return update(defButtonState, {
				[BTN_CLEAR]: disAndBusy,
				[BTN_SEND]: {
					busy: {$set: true},
					disabled: {$set: true},
					text: {$set: SENDING_STR}
				},
				[BTN_SAVE]: disAndBusy
			});
		}
		// must be disabled for submission because of certain reason.
		return update(defButtonState, {
			[BTN_SEND]: {
				disabled: {$set: true},
				// reason: {$set: reason.reason}
			},
			[BTN_SAVE]: {
				disabled: {$set: true}
			}
		});
	}
);

export const manualButtonsSelector = createSelector(
	getButtons,
	buttons => ({buttons, order: DEF_MANUAL_BUTTONS_ORDER})
);

const getMessageData = state => {
		return state
};

const getSelectedData= (messageData, getSize, which) => {
	let selected = [];
	$.each(messageData.opr, (i,v) => {
		if(v.selected) {
			if(which) {
				if(which === "all") {
					selected.push({id: parseInt(i, 10)});
				}else{
					selected.push(v[which]);
				}
			}else{
				selected.push(i);
			}
		}
	});
	if(getSize) {
		return selected.length;
	}else{
		return selected;
	}
}

export const getTotalSelectedMessages = createSelector(
	[
		getMessageData
	],
	(selected) => {
		return getSelectedData(selected, true, false);
	}
);

export const getSelectedIds = createSelector(
	[
		getMessageData
	],
	(selected) => {
		return getSelectedData(selected, false, false);
	}
);

const dummy = () => true;

const defCheckboxes = {
	checkboxes: MANUAL_CHECKBOX_OPTS,
	order: MANUAL_CHECKBOXES_ORDER
};

export const manualCheckboxesSelector = createSelector(
	dummy,
	seletedTab => defCheckboxes
);

const manualCheckboxes = (state, props) => getManualIMInputs(state, props).checkboxes;

export const manualCheckboxStatesSelector = createSelector(
	manualCheckboxes,
	checkboxes => checkboxes
);

export const getAppIM = state => state.app.internalMessage;

const currentMessage = state => getAppIM(state).currentMessage;

export function isMsgHistoryReady(state) {
	return currentMessage(state).history;
}

export function isAcquireReady(state) {
	return currentMessage(state).acquire;
}
