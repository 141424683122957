import { createSelector } from 'reselect';
import update from 'immutability-helper';
import {
	UNKNOWN_PHOTO_LINK
} from '../../common/v5/constants';

export const isMobileSelector = (state, props) => {
	return state.app.workflow.ui.showMobileView;
};

const getViewErrandSelector = (state, props) => {
	return state.app.workflow.ui.showErrandMobileView;
};

const getViewErrandHeaderSelector = (state, props) => {
	return state.app.errand.ui.showErrandHeaderMobile;
};

const getShowErrandOptionSelector = (state, props) => {
	return state.app.errand.ui.showErrandOptionMobile;
};

export const mobileStateSelector = createSelector(
	[
		isMobileSelector,
		getViewErrandSelector,
		getViewErrandHeaderSelector,
		getShowErrandOptionSelector
	],
	(mobile, errand, header, option) => {
		if(!mobile) {
			return;
		}
		return {
			mobileMode: mobile,
			viewErrand: errand,
			viewErrandHeader: header,
			showErrandOption: option
		};
	}
);
