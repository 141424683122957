import { createMatchSelector, getLocation } from 'connected-react-router';
import { createSelector } from 'reselect';
import update from 'immutability-helper';
import { I } from '../../common/v5/config';
import { emptyArray, emptyObject } from '../../common/v5/constants';
import { ROUTE_MATCHER } from '../../common/v5/reviewConstants';
import { arrayAreasToAreaObjects, noSelector, wfSettingsData } from './common';

const reviewMatchSelector = createMatchSelector(ROUTE_MATCHER);
const postponeMatchSelector = createMatchSelector(ROUTE_MATCHER);

export const reviewFeatureEnabledMemo = noSelector(
	wfSettingsData
	, ({ review }) => !!review
);

const reviewAreasFromWfSettings = wfData => {
	const { review } = wfData;
	if (review && review.areas) {
		return review.areas;
	}
	return emptyArray;
};

const postponeAreasFromWfSettings = wfData => {
	const { postpone } = wfData;
	if (postpone && postpone.areas) {
		return postpone.areas;
	}
	return emptyArray;
};

export const postponeAreaMap = wfData => arrayAreasToAreaObjects(postponeAreasFromWfSettings(wfData));
export const reviewAreaMap = wfData => arrayAreasToAreaObjects(reviewAreasFromWfSettings(wfData));

export const reviewFolderEnabledMemo = noSelector(
	wfSettingsData
	, data => reviewAreasFromWfSettings(data).length > 0
);

export const postponeFolderEnabledMemo = noSelector(
	wfSettingsData
	, data => postponeAreasFromWfSettings(data).length > 0
);

export const reviewSelector = noSelector(
	reviewFolderEnabledMemo
	, reviewMatchSelector
	, (enabled, match) => {
		if (!enabled) {
			return null;
		}
		return match;
	}
);
export const postponedSelector = noSelector(
	postponeFolderEnabledMemo
	, postponeMatchSelector
	, (enabled, match) => {
		if (!enabled) {
			return null;
		}
		return match;
	}
);
export const isReviewContext = noSelector(reviewSelector, review => !!review);
export const isPostponeContext = noSelector(postponedSelector, postpone => !!postpone);
const defReviewLocationState = {review: emptyObject};

export const locationState = noSelector(
	getLocation
	, location => {
		if (location && location.state && location.state.review) {
			return location.state;
		}
		return defReviewLocationState;
	}
);

export const waitLoadListMemo = noSelector(
	locationState
	, ({ review: { waitLoadList } }) => !!waitLoadList
);
