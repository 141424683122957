import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reduceReducers from 'reduce-reducers';
import { devtestAction, devtestReducer } from './devtests';
import createRootReducer from '../reducers/root';
import socketError from '../middlewares/socketerror';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { reduxTimeout } from 'redux-timeout';
import { getStateWith } from 'reselect-tools';
import '../debugger';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();

const middilewares = [
	thunk
	, routerMiddleware(history)
	, reduxTimeout()
	, socketError
];

const reducer = createRootReducer(history);

let rootReducer;
if (window.DebugC3) {
	rootReducer = reduceReducers(reducer, devtestReducer);
} else {
	rootReducer = reducer;
}

const configureStore = preloadedState => createStore(
	rootReducer
	, preloadedState
	, composeEnhancers(
		applyMiddleware(...middilewares)
	)
);

const store = configureStore();

if (window.__RESELECT_TOOLS__) {
	getStateWith(() => store.getState());
}

if (window.DebugC3) {
	window.DebugC3 = action => store.dispatch(devtestAction(action));
}

export default store;
