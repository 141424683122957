import { createSelector } from 'reselect';
import update from 'immutability-helper';
import { isSalutation, isSignature, isTemplate, isAccount, removeDupeByKey } from '../../common/v5/helpers';
import {
	ADMIN_VIEW_MAP
	, M_REVIEW_KEYWORDS
	, M_REVIEW_AGENTS
	, M_SALUTATION
	, M_SIGNATURE
	, M_TEMPLATE
	, M_TEMPLATE_WHATSAPP
	, M_MY_SALUTATION
	, M_MY_SIGNATURE
	, M_COMPANIES
	, M_CONFIGCHANGELOG
	, RPLY_MANUAL
	, M_ERRANDINTERNALSTATE
	, emptyArray
	, emptyObject
	, REVIEW_TYPE_SLICE
	, M_CHATWIDGETDL
	, M_FAQWIDGETDL
	, M_VOICEWIDGETDL
	, M_AGENTSIPSTATUS
	, M_STUN_TURN
	, M_CLASSIFIER
	, M_ACCOUNTS
	, M_ACC_EMAIL
	, M_ACC_LINE
	, M_ACC_LINKEDIN
	, M_ACC_JIRA
	, M_ACC_HUBSPOT
	, M_ACC_MSTEAMS
	, M_ACC_GOOGLEREVIEW
	, M_ACC_GOOGLECHAT
	, M_ACC_GOOGLEPLAY
	, M_ACC_SMPP
	, M_ACC_TRUSTPILOT
	, M_ACC_TELEGRAM
	, M_ACC_WHATSAPP
	, M_ACC_TWILIO
	, M_ACC_VIBER
	, M_ACC_SLACK
	, M_ACC_YOUTUBE
	, M_ACC_INSTAGRAM
	, M_ACC_TWITTER
	, M_QUICK_REPLY
	, M_ACC_FACEBOOK
	, M_CALL_RECORDINGS
	, M_CONTACT_CARDS
	, M_TAG
	, M_API_CALLBACK
	, M_API_ACCESSTOKENS
	, M_CORS_WHITELIST
	, M_GROUPS
	, M_CHAT_IP_BLACKLIST
	, M_AGENT_SIP_LOGINS
	, M_SIP_PRIORITY_NUMBERS
	, M_CALL_IVR
	, M_CALL_SIP_TRUNK
	, IVR_CONNECT_OPTIONS
	, IVR_CONNECT_ICON_MAP
	, M_SKILLS
	, M_GENERATIVE_AI_DOCUMENT
} from '../../common/v5/constants';
import { getAppState, getStateName, reduxCreators } from '../util';
import {
	keyAddAgentMP
	, keyAddAgentAvatar
	, keyAgentAdmin
	, keyAgentAdminList
	, keyAgentNew
	, keyAgentAdminSalutations
	, keyAgentAdminSignatures
	, keyAgentWordlist
	, keyDeleteAgentWordlist
	, keyDeleteAddressbook
	, keyUploadAddressbook
	, keyDeleteReviewAddress
	, keyDeleteReviewAgents
	, keyDeleteFileArchive
	, keyDeleteSalutation
	, keyDeleteSignature
	, keyDeleteTemplate
	, keyDeleteRouteKeywords
	, keyDeleteRouteAutoTags
	, keyEditAddressbook
	, keyEditAgentData
	, keyEditAgentWordlist
	, keyEditFileArchive
	, keyGetAddressbook
	, keyGetAgentValidateExternalID
	, keyGetExternalExpertAddressList
	, keyGetAccountLine
	, keyGetOneAccountLine
	, keySaveAccountLine
	, keyDeleteAccountLine
	, keyGetAccountLinkedIn
	, keyGetOneAccountLinkedIn
	, keySaveAccountLinkedIn
	, keyDeleteAccountLinkedIn
	, keyGetAccountJira
	, keyGetOneAccountJira
	, keySaveAccountJira
	, keyDeleteAccountJira
	, keyGetAccountHubSpot
	, keyGetOneAccountHubSpot
	, keySaveAccountHubSpot
	, keyDeleteAccountHubSpot
	, keyGetAccountMSTeams
	, keyGetOneAccountMSTeams
	, keySaveAccountMSTeams
	, keyDeleteAccountMSTeams
	, keyGetAccountGoogleReview
	, keyGetOneAccountGoogleReview
	, keySaveAccountGoogleReview
	, keyDeleteAccountGoogleReview
	, keyGetAccountGoogleChat
	, keyGetOneAccountGoogleChat
	, keySaveAccountGoogleChat
	, keyDeleteAccountGoogleChat
	, keyGetAccountGooglePlay
	, keyGetOneAccountGooglePlay
	, keySaveAccountGooglePlay
	, keyDeleteAccountGooglePlay
	, keyGetAccountTrustpilot
	, keyGetOneAccountTrustpilot
	, keySaveAccountTrustpilot
	, keyDeleteAccountTrustpilot
	, keyGetAccountTelegram
	, keyGetOneAccountTelegram
	, keySaveAccountTelegram
	, keyDeleteAccountTelegram
	, keyGetAccountWhatsApp
	, keyGetOneAccountWhatsApp
	, keySaveAccountWhatsApp
	, keyDeleteAccountWhatsApp
	, keyGetAccountTwilio
	, keyGetOneAccountTwilio
	, keySaveAccountTwilio
	, keyDeleteAccountTwilio
	, keyGetAccountViber
	, keyGetOneAccountViber
	, keySaveAccountViber
	, keyDeleteAccountViber
	, keyGetAccountSlack
	, keySaveAccountSlack
	, keyGetOneAccountSlack
	, keyDeleteAccountSlack
	, keyGetAccountSMPP
	, keyGetOneAccountSMPP
	, keySaveAccountSMPP
	, keyDeleteAccountSMPP
	, keyGetAccountYoutube
	, keyGetOneAccountYoutube
	, keySaveAccountYoutube
	, keyDeleteAccountYoutube
	, keyGetAccountTwitter
	, keyGetOneAccountTwitter
	, keySaveAccountTwitter
	, keyDeleteAccountTwitter
	, keyGetAccountInstagram
	, keyGetOneAccountInstagram
	, keySaveAccountInstagram
	, keyDeleteAccountInstagram
	, keyGetAccountFacebook
	, keyGetOneAccountFacebook
	, keySaveAccountFacebook
	, keyDeleteAccountFacebook
	, keyGetAccountEmail
	, keyGetOneAccountEmail
	, keySaveAccountEmail
	, keyDeleteAccountEmail
	, keyGetFileArchive
	, keyGetRouteKeywords
	, keyGetRouteAutoTags
	, keyGetRouteSip
	, keySaveRouteSip
	, keyDeleteRouteSip
	, keyGetKnowledgeBase
	, keyGetKnowledgeBaseCategory
	, keyGetKnowledgeBaseRoot
	, keyGetOneReviewAddress
	, keyGetOneSalutation
	, keyGetOneSignature
	, keyGetOneTemplate
	, keyGetReviewAddresses
	, keyGetReviewAgents
	, keyGetSalutations
	, keyGetSignatures
	, keyGetTemplates
	, keyInsertKnowledgeBaseNode
	, keyRemoveAgentMP
	, keyRemoveAgentAvatar
	, keyRemoveAvatarEEAddressList
	, keyRemoveExternalExpertAddressList
	, keyRemoveKnowledgeBaseNode
	, keySaveAddressbook
	, keySaveAgentWordlist
	, keySaveExternalExpertAddressList
	, keySaveReviewAddress
	, keySaveAgentForReview
	, keySaveRouteKeywords
	, keySaveSalutation
	, keySaveSignature
	, keySaveTemplate
	, keyUploadReviewAddressFile
	, keyGetAdminLibrary
	, keyGetAdminLibraryCategory
	, keyGetAdminLibraryQuestion
	, keyGetAdminLibrarySuggestion
	, keyGetAdminLibraryRating
	, keySaveAdminLibrary
	, keySaveAdminLibraryCategory
	, keySaveAdminLibrarySuggestion
	, keySaveAdminLibraryQuestion
	, keySaveFileArchive
	, keyRemoveAdminLibrary
	, keyRemoveAdminLibraryCategory
	, keyRemoveAdminLibraryQuestion
	, keyRemoveAdminLibrarySuggestion
	, keyAgentUploadList
	, keyAutoTagUploadList
	, keyAgentImportStatus
	, keyAgentValidate
	, keyAgentActivate
	, keyAgentDeactivate
	, keyAgentUnlock
	, keyFetchLibraryList
	, keyFetchCategoryList
	, keyFetchQuestionList
	, keyFetchSuggestionList
	, keyFetchRatingList
	, keyFetchMoreLibraryList
	, keyFetchMoreCategoryList
	, keyFetchMoreQuestionList
	, keyFetchMoreSuggestionList
	, keyFetchMoreRatingList
	, keyGetOneFileArchive
	, keyGetOneRouteKeywords
	, keyGetOneRouteAutoTags
	, keyGetOneRouteSip
	, keyGetCompanyList
	, keyGetConfigChangeLog
	, keyGetStunTurnList
	, keyAddStunTurn
	, keyGetErrandInternalState
	, keyGetOneErrandInternalState
	, keySaveErrandInternalState
	, keyAddNewCompany
	, keyGetChatWidgetCfgList
	, keyGetChatWidgetCfgDefList
	, keyGetFaqWidgetCfgList
	, keyGetFaqWidgetCfgDefList
	, keyGetVoiceWidgetCfgList
	, keyGetVoiceWidgetCfgDefList
	, keyAgentSipStatus
	, keyFetchAgentSipStatus
	, keyUpdateAgentSipStatus
	, keyValidateAgentSipStatus
	, keyUpdateStunTurnStatus
	, keyClassifier
	, keyFetchClassifier
	, keyGetTags
	, keyGetTagsList
	, keyUploadTags
	, keyUpdateClassifier
	, keyGetQuickReplies
	, keyDeleteQuickReply
	, keyGetOneQuickReply
	, keySaveQuickReply
	, keyGetRoutingGroups
	, keyGetOneRoutingGroups
	, keySaveRoutingGroups
	, keyDeleteRoutingGroups
	, keyGetOrganisations
	, keyGetOrganisationById
	, keyAgentEmailAvailability
	, keySaveOrganisationExternalSetting
	, keyRemoveTags
	, keySaveAdminTag
	, keyAppendAdminTag
	, keyGetCORSWhitelist
	, keyGetOneCORSWhitelist
	, keySaveCORSWhitelist
	, keyDeleteCORSWhitelist
	, keyGetAdminGroups
	, keyGetOneAdminGroups
	, keySaveAdminGroups
	, keyDeleteAdminGroups
	, keyGetGroupFolders
	, keyGetOneGroupFolders
	, keySaveGroupFolders
	, keyDeleteGroupFolders
	, keyGetGroupAgents
	, keyGetOneGroupAgents
	, keySaveGroupAgents
	, keyDeleteGroupAgents
	, keyGetChatIPAddressBlacklist
	, keyDeleteChatIPAddressBlacklist
	, keyGetCallRecordings
	, keyGetOneCallRecordings
	, keyDownloadCallRecording
	, keyGetAgentSipLogins
	, keyGetOneAgentSipLogin
	, keySaveAgentSipLogin
	, keyDeleteAgentSipLogin
	, keyGetSipPriorityNumbers
	, keyGetOneSipPrioNumber
	, keySaveSipPrioNumber
	, keyDeleteSipPrioNumber
	, keyGetOneSipTrunk
	, keySaveSipTrunk
	, keyDeleteSipTrunk
	, keyGetContactCard
	, keyGetOneContactCard
	, keyGetContactCardNotes
	, keySaveContactCard
	, keyMergeContactCard
	, keyDeleteContactCard
	, keyRemoveContactCardAccount
	, keyDeleteContactCardNotes
	, keyCustomerAvatar
	, keyGetCallbackAPI
	, keyGetCallbackAPIList
	, keySaveCallbackAPI
	, keyUpdateCallbackAPI
	, keyRemoveCallbackAPI
	, keyGetJWTList
	, keySaveJWTAPI
	, keyRemoveJWTAPI
	, keyCallIVRList
	, keyCallIVRSave
	, keyCallIVRDelete
	, keyCallIVRPromptUpload
	, keyCallIVRMOHUpload
	, keyCallIVRDeploy
	, keyTwoFANewSecret
	, keyVerifyTwoFAToken
	, keyCallSipTrunkList
	, keySaveSkillsCategory
	, keySaveSkills
	, keyGetSkillsCategory
	, keyGetSkills
	, keyGetSkillProficiency
	, keyDeleteSkills
	, keyDeleteSkillsCategory
	, keyGetSkillAgents
	, keyGetSkillAreas
	, keyAgentAssist
	, keyGetGenerativeAIDocument
	, keyPostGenerativeAIDocument
	, keyDeleteGenerativeAIDocument
	, keyLanguageList
} from '../constants/keys';
import {
	commonSelectorsCreator,
	noSelector,
	selectCreateSelector,
	selectNoSelector
} from './common';
import { currentActiveReply, fileArchivesSelector } from './errand';
import { isAdminPageSelector } from './hmf';
import { manualFileArchivesSelector } from './manual';
import { ckeditorSettings, getWorkflowSettingsData } from './workflow';

export const admin = reduxCreators([
	[keyAddAgentMP, 'addAgentMP']
	, [keyAgentAdmin, 'agentData']
	, [keyAgentAdminList, 'agentList']
	, [keyAgentNew,'agentNew']
	, [keyAgentAdminSalutations, 'agentAreaSalutation']
	, [keyAgentAdminSignatures, 'agentAreaSignature']
	, [keyAddAgentAvatar, 'keyAddAgentAvatar']
	, [keyAgentWordlist, 'wordList']
	, [keyDeleteAddressbook, 'deleteAddressBook']
	, [keyUploadAddressbook, 'uploadAddressBook']
	, [keyDeleteAgentWordlist, 'deleteAgentWordList']
	, [keyDeleteFileArchive, 'deleteFileArchive']
	, [keyDeleteReviewAddress, 'deleteReviewAddress']
	, [keyDeleteReviewAgents, 'DeleteReviewAgent']
	, [keyDeleteSalutation, 'deleteSalutation']
	, [keyDeleteSignature, 'deleteSignature']
	, [keyDeleteTemplate, 'deleteTemplate']
	, [keyDeleteRouteKeywords, 'deleteRouteKeywords']
	, [keyDeleteRoutingGroups, 'deleteRoutingGroups']
	, [keyDeleteRouteAutoTags, 'deleteRouteAutotags']
	, [keyEditAddressbook, 'editAddressbook']
	, [keyEditAgentData, 'editAgentData']
	, [keyEditAgentWordlist, 'wordListData']
	, [keyEditFileArchive, 'editFileArchive']
	, [keyGetOneReviewAddress, 'reviewAddressData']
	, [keyGetReviewAddresses, 'reviewAddresses']
	, [keyGetReviewAgents, 'reviewAgents']
	, [keyGetAccountFacebook, 'accountFacebooks']
	, [keyGetOneAccountFacebook, 'accountFacebook']
	, [keySaveAccountFacebook, 'saveAccountFacebook']
	, [keyDeleteAccountFacebook, 'deleteAccountFacebook']
	, [keyGetAccountLine, 'accountLines']
	, [keyGetOneAccountLine, 'accountLine']
	, [keySaveAccountLine, 'saveAccountLine']
	, [keyDeleteAccountLine, 'deleteAccountLine']
	, [keyGetAccountLinkedIn, 'accountLinkedIn']
	, [keyGetOneAccountLinkedIn, 'accountLinkedIn']
	, [keySaveAccountLinkedIn, 'saveAccountLinkedIn']
	, [keyDeleteAccountLinkedIn, 'deleteAccountLinkedIn']
	, [keyGetAccountJira, 'accountJira']
	, [keyGetOneAccountJira, 'accountJira']
	, [keySaveAccountJira, 'saveAccountJira']
	, [keyDeleteAccountJira, 'deleteAccountJira']
	, [keyGetAccountHubSpot, 'accountHubSpot']
	, [keyGetOneAccountHubSpot, 'accountHubSpot']
	, [keySaveAccountHubSpot, 'saveAccountHubSpot']
	, [keyDeleteAccountHubSpot, 'deleteAccountHubSpot']
	, [keyGetAccountMSTeams, 'accountMSTeams']
	, [keyGetOneAccountMSTeams, 'accountMSTeams']
	, [keySaveAccountMSTeams, 'saveAccountMSTeams']
	, [keyDeleteAccountMSTeams, 'deleteAccountMSTeams']
	, [keyGetAccountGoogleReview, 'accountGoogleReview']
	, [keyGetOneAccountGoogleReview, 'accountGoogleReview']
	, [keySaveAccountGoogleReview, 'saveAccountGoogleReview']
	, [keyDeleteAccountGoogleReview, 'deleteAccountGoogleReview']
	, [keyGetAccountGoogleChat, 'accountGoogleChat']
	, [keyGetOneAccountGoogleChat, 'accountGoogleChat']
	, [keySaveAccountGoogleChat, 'saveAccountGoogleChat']
	, [keyDeleteAccountGoogleChat, 'deleteAccountGoogleChat']
	, [keyGetAccountGooglePlay, 'accountGooglePlay']
	, [keyGetOneAccountGooglePlay, 'accountGooglePlay']
	, [keySaveAccountGooglePlay, 'saveAccountGooglePlay']
	, [keyDeleteAccountGooglePlay, 'deleteAccountGooglePlay']
	, [keyGetAccountTrustpilot, 'accountTrustpilot']
	, [keyGetOneAccountTrustpilot, 'accountTrustpilot']
	, [keySaveAccountTrustpilot, 'saveAccountTrustpilot']
	, [keyDeleteAccountTrustpilot, 'deleteAccountTrustpilot']
	, [keyGetAccountTelegram, 'accountTelegram']
	, [keyGetOneAccountTelegram, 'accountTelegram']
	, [keySaveAccountTelegram, 'saveAccountTelegram']
	, [keyDeleteAccountTelegram, 'deleteAccountTelegram']
	, [keyGetAccountWhatsApp, 'accountWhatsApp']
	, [keyGetOneAccountWhatsApp, 'accountWhatsApp']
	, [keySaveAccountWhatsApp, 'saveAccountWhatsApp']
	, [keyDeleteAccountWhatsApp, 'deleteAccountWhatsApp']
	, [keyGetAccountTwilio, 'accountTwilio']
	, [keyGetOneAccountTwilio, 'accountTwilio']
	, [keySaveAccountTwilio, 'saveAccountTwilio']
	, [keyDeleteAccountTwilio, 'deleteAccountTwilio']
	, [keyGetAccountViber, 'accountViber']
	, [keyGetOneAccountViber, 'accountViber']
	, [keySaveAccountViber, 'saveAccountViber']
	, [keyDeleteAccountViber, 'deleteAccountViber']
	, [keyGetAccountSlack, 'accountSlacks']
	, [keyGetOneAccountSlack, 'accountSlack']
	, [keySaveAccountSlack, 'saveAccountSlack']
	, [keyDeleteAccountSlack, 'deleteAccountSlack']
	, [keyGetAccountSMPP, 'accountSMPP']
	, [keyGetOneAccountSMPP, 'accountSMPP']
	, [keySaveAccountSMPP, 'saveAccountSMPP']
	, [keyDeleteAccountSMPP, 'deleteAccountSMPP']
	, [keyGetAccountYoutube, 'accountYoutube']
	, [keyGetOneAccountYoutube, 'accountYoutube']
	, [keySaveAccountYoutube, 'saveAccountYoutube']
	, [keyDeleteAccountYoutube, 'deleteAccountYoutube']
	, [keyGetAccountTwitter, 'accountTwitter']
	, [keyGetOneAccountTwitter, 'accountTwitter']
	, [keySaveAccountTwitter, 'saveAccountTwitter']
	, [keyDeleteAccountTwitter, 'deleteAccountTwitter']
	, [keyGetAccountInstagram, 'accountInstagrams']
	, [keyGetOneAccountInstagram, 'accountInstagram']
	, [keySaveAccountInstagram, 'saveAccountInstagram']
	, [keyDeleteAccountInstagram, 'deleteAccountInstagram']
	, [keyGetAccountEmail, 'accountEmails']
	, [keyGetOneAccountEmail, 'accountEmail']
	, [keySaveAccountEmail, 'saveAccountEmail']
	, [keyDeleteAccountEmail, 'deleteAccountEmail']
	, [keyGetFileArchive, 'fileArchives']
	, [keyGetRouteKeywords, 'routekeywords']
	, [keyGetAdminGroups, 'adminGroups']
	, [keyGetOneAdminGroups, 'oneAdminGroups']
	, [keySaveAdminGroups, 'saveAdminGroups']
	, [keyDeleteAdminGroups, 'deleteAdminGroups']
	, [keyGetGroupFolders, 'groupFolders']
	, [keyGetOneGroupFolders, 'groupFolder']
	, [keySaveGroupFolders, 'saveAdminGroupsFolder']
	, [keyDeleteGroupFolders, 'deleteGroupsFolder']
	, [keyGetGroupAgents, 'groupAgents']
	, [keyGetOneGroupAgents, 'groupAgents']
	, [keySaveGroupAgents, 'saveAdminGroupAgents']
	, [keyDeleteGroupAgents, 'deleteGroupAgents']
	, [keyGetCORSWhitelist, 'corsWhiteList']
	, [keyGetOneCORSWhitelist, 'oneCorsWhiteList']
	, [keySaveCORSWhitelist, 'saveCorsWhiteList']
	, [keyDeleteCORSWhitelist, 'deleteCorsWhiteList']
	, [keyGetChatIPAddressBlacklist, 'getChatIPBlackList']
	, [keyDeleteChatIPAddressBlacklist, 'deleteChatIPBlacklist']
	, [keyGetCallRecordings, 'callrecordings']
	, [keyGetOneCallRecordings, 'callrecording']
	, [keyDownloadCallRecording, 'downloadCallrecording']
	, [keyGetAgentSipLogins, 'agentsiplogins']
	, [keyGetOneAgentSipLogin, 'oneagentsiplogin']
	, [keySaveAgentSipLogin, 'saveagentsiplogin']
	, [keyDeleteAgentSipLogin, 'deleteagentsiplogin']
	, [keyGetOneSipTrunk, 'getOneSipTrunk']
	, [keySaveSipTrunk, 'saveSipTrunk']
	, [keyDeleteSipTrunk, 'deleteSipTrunk']
	, [keyGetSipPriorityNumbers, 'sipPriorityNumbers']
	, [keyGetOneSipPrioNumber, 'getOneSipPrioNumber']
	, [keySaveSipPrioNumber, 'sipSavePrioNumber']
	, [keyDeleteSipPrioNumber, 'deleteSipPrioNumber']
	, [keyGetContactCard, 'contactCards']
	, [keyGetOneContactCard, 'contactCard']
	, [keyGetContactCardNotes, 'contactCardNote']
	, [keySaveContactCard, 'savecontactCard']
	, [keyMergeContactCard, 'mergeContactCard']
	, [keyDeleteContactCard, 'deletecontactCard']
	, [keyRemoveContactCardAccount, 'deletecontactCardAccount']
	, [keyDeleteContactCardNotes, 'deletecontactCardNote']
	, [keyCustomerAvatar, 'deleteContactCardAvatar']
	, [keyGetRoutingGroups, 'routinggroups']
	, [keyGetRouteAutoTags, 'routeAutoTags']
	, [keyGetRouteSip, 'routesips']
	, [keyGetSalutations, 'salutations']
	, [keyGetSignatures, 'signatures']
	, [keyGetKnowledgeBase, 'getKnowledgeBase']
	, [keyGetKnowledgeBaseCategory, 'knowledgeBaseCategory']
	, [keyGetKnowledgeBaseRoot, 'keyGetKnowledgeBaseRoot']
	, [keyGetTemplates, 'templates']
	, [keyGetOneSignature, 'signatureData']
	, [keyGetOneSalutation, 'salutationData']
	, [keyGetOneTemplate, 'template']
	, [keyGetOneFileArchive, 'fileArchive']
	, [keyGetOneRouteKeywords, 'routekeyword']
	, [keyGetOneRoutingGroups, 'routinggroup']
	, [keyGetOneRouteAutoTags, 'routeAutoTag']
	, [keyGetOneRouteSip, 'routesip']
	, [keyGetAddressbook, 'addressBook']
	, [keyGetExternalExpertAddressList, 'externalExpertAddress']
	, [keyGetAgentValidateExternalID, 'agentValidateExternalID']
	, [keyInsertKnowledgeBaseNode, 'insertKnowledgeNode']
	, [keyRemoveAgentMP, 'removeAgentMP']
	, [keyRemoveAgentAvatar, 'removeAgentAvatar']
	, [keyRemoveAvatarEEAddressList, 'removeAvatarEEAddressList']
	, [keyRemoveExternalExpertAddressList, 'removeExternalExpertAddressList']
	, [keyRemoveKnowledgeBaseNode, 'removeKnowledgeBaseNode']
	, [keySaveAddressbook, 'saveAddressbook']
	, [keySaveAgentWordlist, 'saveAgentWordList']
	, [keySaveFileArchive, 'saveFileArchive']
	, [keySaveExternalExpertAddressList, 'saveExternalExpertAddressList']
	, [keySaveReviewAddress, 'saveReviewAddress']
	, [keySaveAgentForReview, 'SaveAgentForReview']
	, [keySaveRouteKeywords, 'saveRouteKeyword']
	, [keySaveRoutingGroups, 'saveRoutingGroups']
	, [keySaveRouteSip, 'saveRouteSip']
	, [keyDeleteRouteSip, 'deleteRouteSip']
	, [keySaveSalutation, 'saveSalutation']
	, [keySaveSignature, 'saveSignature']
	, [keySaveTemplate, 'saveTemplate']
	, [keyUploadReviewAddressFile, 'uploadReviewAddressFile']
	, [keyGetAdminLibrary, 'getAdminLibrary']
	, [keyGetAdminLibraryCategory, 'getAdminLibraryCategory']
	, [keyGetAdminLibraryQuestion, 'getAdminLibraryQuestion']
	, [keyGetAdminLibrarySuggestion, 'keyGetAdminLibrarySuggestion']
	, [keyGetAdminLibraryRating, 'getAdminLibraryRating']
	, [keySaveAdminLibrary, 'saveAdminLibrary']
	, [keySaveAdminLibraryCategory, 'saveAdminLibraryCategory']
	, [keySaveAdminLibraryQuestion, 'saveAdminLibraryQuestion']
	, [keySaveAdminLibrarySuggestion, 'saveAdminLibrarySuggestion']
	, [keyRemoveAdminLibrary, 'keyRemoveAdminLibrary']
	, [keyRemoveAdminLibraryCategory, 'keyRemoveAdminLibraryCategory']
	, [keyRemoveAdminLibraryQuestion, 'keyRemoveAdminLibraryQuestion']
	, [keyRemoveAdminLibrarySuggestion, 'keyRemoveAdminLibrarySuggestion']
	, [keyAgentUploadList, 'agentUploadList']
	, [keyAutoTagUploadList, 'autoTagUploadList']
	, [keyAgentImportStatus, 'agentImportStatus']
	, [keyAgentValidate, 'validateAgent']
	, [keyAgentEmailAvailability, 'keyAgentEmailAvailability']
	, [keyAgentActivate, 'keyAgentActivate']
	, [keyAgentDeactivate, 'keyAgentDeactivate']
	, [keyAgentUnlock, 'keyAgentUnlock']
	, [keyFetchLibraryList, 'keyFetchLibraryList']
	, [keyFetchCategoryList, 'keyFetchCategoryList']
	, [keyFetchQuestionList, 'keyFetchQuestionList']
	, [keyFetchSuggestionList, 'keyFetchSuggestionList']
	, [keyFetchRatingList, 'keyFetchRatingList']
	, [keyFetchMoreLibraryList, 'keyFetchMoreLibraryList']
	, [keyFetchMoreCategoryList, 'keyFetchMoreCategoryList']
	, [keyFetchMoreQuestionList, 'keyFetchMoreQuestionList']
	, [keyFetchMoreSuggestionList, 'keyFetchMoreSuggestionList']
	, [keyFetchMoreRatingList, 'keyFetchRatingList']
	, [keyGetCompanyList, 'companyList']
	, [keyGetConfigChangeLog, 'ConfigChangeLog']
	, [keyGetStunTurnList, 'stunTurnList']
	, [keyAddStunTurn, 'addStunTurn']
	, [keyGetErrandInternalState, 'errandInternalState']
	, [keyGetOneErrandInternalState, 'keyGetOneErrandInternalState']
	, [keySaveErrandInternalState, 'keySaveErrandInternalState']
	, [keyAddNewCompany, 'addNewCompany']
	, [keyGetChatWidgetCfgList,'chatWidgetCfg']
	, [keyGetChatWidgetCfgDefList,'chatWidgetCfgDef']
	, [keyGetFaqWidgetCfgList,'faqWidgetCfg']
	, [keyGetFaqWidgetCfgDefList,'faqWidgetCfgDef']
	, [keyGetVoiceWidgetCfgList,'voiceWidgetCfg']
	, [keyGetVoiceWidgetCfgDefList,'voiceWidgetCfgDef']
	, [keyAgentSipStatus, 'agentSipStatus']
	, [keyFetchAgentSipStatus, 'fetchAgentStatus']
	, [keyUpdateAgentSipStatus, 'updateAgentStatus']
	, [keyValidateAgentSipStatus, 'validateAgentSipStatus']
	, [keyUpdateStunTurnStatus, 'updateStunTurnStatus']
	, [keyClassifier, 'classifier']
	, [keyFetchClassifier, 'fetchClassifier']
	, [keyUpdateClassifier, 'updateClassifier']
	, [keyGetTags, 'tags']
	, [keyGetTagsList, 'tagsList']
	, [keyRemoveTags, 'tagsRemove']
	, [keySaveAdminTag, 'tagsSave']
	, [keyAppendAdminTag, 'tagsAppend']
	, [keyUploadTags, 'tagsUpload']
	, [keyDeleteQuickReply, 'quickReplyDelete']
	, [keyGetOneQuickReply, 'quickReply']
	, [keyGetQuickReplies, 'quickReplies']
	, [keySaveQuickReply, 'quickReplySave']
	, [keyGetOrganisations, 'organisations']
	, [keyGetOrganisationById, 'keyGetOrganisationById']
	, [keySaveOrganisationExternalSetting, 'keySaveOrganisationExternal']
	, [keyGetCallbackAPIList, 'callbackAPIList']
	, [keyGetCallbackAPI, 'callbackAPI']
	, [keySaveCallbackAPI, 'callbackAPISave']
	, [keyRemoveCallbackAPI, 'callbackAPIRemove']
	, [keyUpdateCallbackAPI, 'callbackAPIUpdate']
	, [keyGetJWTList, 'jwtAPIList']
	, [keySaveJWTAPI, 'jwtAPISave']
	, [keyRemoveJWTAPI, 'keyRemoveJWTAPI']
	, [keyCallIVRList, 'keyCallIVRList']
	, [keyCallIVRSave, 'keyCallIVRSave']
	, [keyCallIVRDelete, 'keyCallIVRDelete']
	, [keyCallIVRPromptUpload, 'keyCallIVRPromptUpload']
	, [keyCallIVRMOHUpload, 'keyCallIVRMOHUpload']
	, [keyCallIVRDeploy, 'keyCallIVRDeploy']
	, [keyTwoFANewSecret,'keyTwoFANewSecret']
	, [keyVerifyTwoFAToken,'keyVerifyTwoFAToken']
	, [keyCallSipTrunkList, 'keyCallSipTrunkList']
	, [keySaveSkillsCategory, 'SaveSkillCategory']
	, [keySaveSkills, 'SaveSkill']
	, [keyGetSkillsCategory, 'GetSkillsCategory']
	, [keyGetSkillProficiency, 'GetSkillProficiency']
	, [keyGetSkills, 'GetSkills']
	, [keyDeleteSkills, 'DeleteSkills']
	, [keyDeleteSkillsCategory, 'DeleteSkillsCategory']
	, [keyGetSkillAgents, 'GetSkillAgents']
	, [keyGetSkillAreas, 'GetSkillAreas']
	, [keyAgentAssist, 'AgentAssist']
	, [keyGetGenerativeAIDocument, 'getGenerativeAIDocument']
	, [keyLanguageList, 'languageList']
	, [keyPostGenerativeAIDocument, 'postGenerativeAIDocument']
]);

export const adminRoot = store => getAppState(store, 'admin');

export const selectorsCreator = (branch, reduxMap) => commonSelectorsCreator(
	adminRoot,
	branch,
	reduxMap
)

const adminUi = store => adminRoot(store).ui;

export const getCurrentAdminView = state => adminUi(state).view;

export const adminAdmin = state => adminRoot(state).admin;

export const getActiveAdminId = state => adminAdmin(state).activeId;

export const getWordFilter = state => adminAdmin(state).filter.wordFilter;

export const stateName = key => getStateName(admin[key]);

export const adminStateByKey = (store, key) => adminRoot(store)[stateName(key)]

const asyncData = key => state => {
	return adminRoot(state)[stateName(key)].data;
}

const accountLine = asyncData(keyGetAccountLine);
const accountLinkedIn = asyncData(keyGetAccountLinkedIn);
const accountJira = asyncData(keyGetAccountJira);
const accountHubSpot = asyncData(keyGetAccountHubSpot);
const accountMSTeams = asyncData(keyGetAccountMSTeams);
const accountGoogleReview = asyncData(keyGetAccountGoogleReview);
const accountGoogleChat = asyncData(keyGetAccountGoogleChat);
const accountGooglePlay = asyncData(keyGetAccountGooglePlay);
const accountTrustpilot = asyncData(keyGetAccountTrustpilot);
const accountTelegram = asyncData(keyGetAccountTelegram);
const accountWhatsApp = asyncData(keyGetAccountWhatsApp);
const accountTwilio = asyncData(keyGetAccountTwilio);
const accountViber = asyncData(keyGetAccountViber);
const accountSlack = asyncData(keyGetAccountSlack);
const accountSMPP = asyncData(keyGetAccountSMPP);
const accountYoutube = asyncData(keyGetAccountYoutube);
const accountFacebook = asyncData(keyGetAccountFacebook);
const accountEmail = asyncData(keyGetAccountEmail);
const accountInstagram = asyncData(keyGetAccountInstagram);
const accountTwitter = asyncData(keyGetAccountTwitter);

const fileArchive = asyncData(keyGetFileArchive);
const genaiDocument = asyncData(keyGetGenerativeAIDocument);

const routeKeywords = asyncData(keyGetRouteKeywords);
const routingGroups = asyncData(keyGetRoutingGroups);
const callRecordings = asyncData(keyGetCallRecordings);
const agentSipLogins = asyncData(keyGetAgentSipLogins);
const sipPriorityNumbers = asyncData(keyGetSipPriorityNumbers);
const adminGroups = asyncData(keyGetAdminGroups);
const groupFolders = asyncData(keyGetGroupFolders);
const groupAgents = asyncData(keyGetGroupAgents);
const corsWhiteList = asyncData(keyGetCORSWhitelist);
const chatIPBlacklist = asyncData(keyGetChatIPAddressBlacklist);
const contactCards = asyncData(keyGetContactCard);

export const groupFoldersSelector = createSelector(
	groupFolders
	, data => {
		if (!data || !data.folderList) {
			return emptyArray;
		}
		var list = data.folderList;
		return list
	}
);
export const groupAgentsSelector = createSelector(
	groupAgents
	, data => {
		let errorResult = "Agent group ID not found" //todo:maybe change at backend to return empty instead or string
		if (!data || data.result === errorResult) {
			return emptyObject;
		}
		var list = data;
		return list
	}
);

export const callRecordingsSelector = createSelector(
	callRecordings
	, data => {
		if (!data || !data.result || !data.result.data) {
			return emptyArray;
		}
		var list = data.result.data;
		var count = data.result.count;
		var offset = data.result.offset;
		return {list, count, offset}
	}
);

export const agentSipLoginsSelector = createSelector(
	agentSipLogins
	, data => {
		if (!data || !data.result || !data.result.data) {
			return emptyArray;
		}
		var list = data.result.data;
		return list
	}
);

export const agentNoSipLoginsSelector = createSelector(
	agentSipLogins
	, data => {
		if (!data || !data.result || !data.result.nosip) {
			return emptyArray;
		}
		var list = data.result.nosip;
		return list
	}
);

export const sipPriorityNumbersSelector = createSelector(
	sipPriorityNumbers
	, data => {
		if (!data || !data.result || !data.result.data) {
			return emptyArray;
		}
		var list = data.result.data;
		return list
	}
);

export const sipNumberNoneSelector = createSelector(
	sipPriorityNumbers
	, data => {
		if (!data || !data.result || !data.result.nosip) {
			return emptyArray;
		}
		var list = data.result.nosip;
		return list
	}
);

const routeAutoTags = asyncData(keyGetRouteAutoTags);

const routeSip = asyncData(keyGetRouteSip);

const reviewAddresses = asyncData(keyGetReviewAddresses);

const reviewAgents = asyncData(keyGetReviewAgents);

const salutations = asyncData(keyGetSalutations);

const signatures = asyncData(keyGetSignatures);

const templates = asyncData(keyGetTemplates);

const quickReplies = asyncData(keyGetQuickReplies);

const wordList = asyncData(keyAgentWordlist);

const companyList = asyncData(keyGetCompanyList);

const stunTurnList = asyncData(keyGetStunTurnList);

const chatWidgetCfgList = asyncData(keyGetChatWidgetCfgList);

const chatWidgetCfgDefList = asyncData(keyGetChatWidgetCfgDefList);

const faqWidgetCfgList = asyncData(keyGetFaqWidgetCfgList);

const faqWidgetCfgDefList = asyncData(keyGetFaqWidgetCfgDefList);

const voiceWidgetCfgList = asyncData(keyGetVoiceWidgetCfgList);

const voiceWidgetCfgDefList = asyncData(keyGetVoiceWidgetCfgDefList);

const agentSipStatuses = asyncData(keyAgentSipStatus);

const classifiers = asyncData(keyClassifier);

const tagKeywords = asyncData(keyGetTags);

const tagAdminList = asyncData(keyGetTagsList);

const organisationsKeywords = asyncData(keyGetOrganisations);

const ivrGetList = asyncData(keyCallIVRList);

const sipTrunkGetList = asyncData(keyCallSipTrunkList);

const skillAdminList = asyncData(keyGetSkills);

const skillAdminCategoryList = asyncData(keyGetSkillsCategory);

const skillAdminAgentList = asyncData(keyGetSkillAgents);

const skillAdminAreaList = asyncData(keyGetSkillAreas);

const skillAdminProficiencyList  = asyncData(keyGetSkillProficiency);

const reviewAddressesSelector = createSelector(
	reviewAddresses
	, data => {
		if (!data || !data.list || !data.list.length) {
			return emptyArray;
		}
		const { list, map } = data
			, listObject = []
			;
		for (let i=0; i<list.length; i++) {
			let review = map[list[i]];
			if(review && review.reviewType > 0) {
				let type = REVIEW_TYPE_SLICE[review.reviewType]
				review = update(review, {$merge: {type: type}});
			}
			listObject.push(review);
		}
		return listObject;
	}
);

const reviewAgentsSelector = createSelector(
	reviewAgents
	, data => {
		if (!data || !data.list || !data.list.length) {
			return emptyArray;
		}
		const { list, map } = data
			, listObject = []
			;
		for (let i=0; i<list.length; i++) {
			let review = map[list[i]];
			listObject.push(review);
		}
		return listObject;
	}
);

export const tagsListSelector = createSelector(
	tagAdminList
	, data => {
		if (!data || !data.tags || !data.tags.length) {
			return emptyArray;
		}
		let dataList = [];
		for (let i=0; i<data.tags.length; i++) {
			let tag = data.tags[i];
			dataList.push({
				id: tag.id,
				tagId: tag.tagId,
				keyId: tag.id+"-"+(tag.connectedTags ? tag.connectedTags.length.toString() : "0")+"-Level-"+tag.tagLevel,
				name: tag.displayName,
				level: tag.tagLevel,
				parentId: tag.tagParentId,
				areas: tag.connectedAreas.length,
				tagVIP: tag.tagVIP,
				tagColor: tag.tagColor,
				tagDelete: tag.tagDelete ? "X" : "",
				connectedAreasName: tag.connectedAreaOrgName,
				connectedAreas: (tag.connectedAreas ? tag.connectedAreas.join(","): ""),
				connectedTags: tag.connectedTags,
				connectedTagsLen: tag.connectedTags ? tag.connectedTags.length : 0,
				connectedTagsList: getConnTagsList(tag.connectedTags, data.tags),
				numOfNextLevelTags: tag.numOfNextLevelTags,
				levelsUnderneath: tag.levelsUnderneath,
			});
		}
		return dataList;
	}
);

export const getNonExpandables = createSelector(
	tagsListSelector
	, allTagsList => {
		if(allTagsList.length === 0) {
			return [];
		}
		let nonExpandables = [];
		for (let i=0; i<allTagsList.length; i++) {
			if (allTagsList[i].levelsUnderneath === 0) {
				nonExpandables.push(allTagsList[i].keyId);
			}
		}
		return nonExpandables;
	}
);

export const getUniqueChannels = (list) => {
	let uniqService = [];
	if( list && list.length > 0) {
		const serviceHeld = list.map(service => {
			return service.service
		})
		uniqService = [...new Set(serviceHeld)];
	}
	return uniqService;
}

//ids: array of tag ids
//tags: array of tags with details
export const getConnTagsList = (ids, tags) => {
	let connTagsList = [];
	if(ids && ids.length > 0) {
		for(let i=0; i<ids.length; i++) {
			const id = ids[i];
			const tag = getTagById(id, tags);
			connTagsList.push({
				id: tag.id,
				tagId: tag.tagId,
				keyId: tag.id+"-"+(tag.connectedTags ? tag.connectedTags.length.toString() : "0")+"-Level-"+tag.tagLevel,
				name: tag.displayName,
				level: tag.tagLevel,
				parentId: tag.tagParentId,
				areas: tag.connectedAreas ? tag.connectedAreas.length : 0,
				tagVIP: tag.tagVIP,
				tagColor: tag.tagColor,
				tagDelete: tag.tagDelete ? "X" : "",
				connectedAreasName: tag.connectedAreaOrgName,
				connectedAreas: (tag.connectedAreas ? tag.connectedAreas.join(","): ""),
				connectedTags: tag.connectedTags,
				connectedTagsLen: tag.connectedTags ? tag.connectedTags.length : 0,
				connectedTagsList: getConnTagsList(tag.connectedTags, tags),
				numOfNextLevelTags: tag.numOfNextLevelTags,
				levelsUnderneath: tag.levelsUnderneath
			});
		}
	}
	return connTagsList;
}

const getTagById = (id, tags) => {
	let tagData = {};
	for (let i=0; i<tags.length; i++) {
		if(tags[i].id === id) {
			tagData = tags[i];
			break;
		}
	}
	return tagData;
}

export const mainTagsListSelector = createSelector(
	tagAdminList
	, data => {
		if (!data || !data.tags || !data.tags.length) {
			return emptyArray;
		}
		let dataList = [];
		for (let i=0; i<data.tags.length; i++) {
			let tag = data.tags[i];
			if(tag.tagLevelString === "1") {
				dataList.push({
					id: tag.id,
					tagId: tag.tagId,
					keyId: tag.id+"-"+(tag.connectedTags ? tag.connectedTags.length.toString() : "0")+"-Level-"+tag.tagLevel,
					name: tag.displayName,
					level: tag.tagLevel,
					parentId: tag.tagParentId,
					areas: tag.connectedAreas.length,
					tagVIP: tag.tagVIP,
					tagColor: tag.tagColor,
					tagDelete: tag.tagDelete ? "X" : "",
					connectedAreasName: tag.connectedAreaOrgName,
					connectedAreas: (tag.connectedAreas ? tag.connectedAreas.join(","): ""),
					connectedTags: tag.connectedTags,
					connectedTagsLen: tag.connectedTags ? tag.connectedTags.length : 0,
					connectedTagsList: getConnTagsList(tag.connectedTags, data.tags),
					numOfNextLevelTags: tag.numOfNextLevelTags,
					levelsUnderneath: tag.levelsUnderneath
				});
			}
		}
		return dataList;
	}
);

const currentTagLevelSelected = state => adminAdmin(state).input.level ? parseInt(adminAdmin(state).input.level, 10) : 1;

export const subTagsListSelector = createSelector(
	tagsListSelector
	, currentTagLevelSelected
	, (data, lvl) => {
		let dataList = [], subtags = [];
		if(data && data.length > 0) {
			//return all next level tags
			for (let i=0; i<data.length; i++) {
				let tag = data[i];
				if(parseInt(tag.level, 10) === (lvl+1)) {
					tag.keyId = tag.id+"-"+(tag.connectedTags ? tag.connectedTags.length.toString() : "0")+"-Level-"+tag.tagLevel,
					dataList.push(tag);
				}
			}
			subtags = removeDupeByKey(dataList, 'tagId');
		}
		return subtags;
	}
);

const createSimpleNoDataEmptyArraySelector = selector => noSelector(
	selector
	, data => {
		if (data && data.length > 0) {
			return data;
		}
		return emptyArray;
	}
);

const salutationsSelector = createSimpleNoDataEmptyArraySelector(salutations);

const signaturesSelector = createSimpleNoDataEmptyArraySelector(signatures);

const templatesSelector = createSimpleNoDataEmptyArraySelector(templates);

const quickRepliesSelector = createSimpleNoDataEmptyArraySelector(quickReplies);

const wordListSelector = createSimpleNoDataEmptyArraySelector(wordList);

const chatWidgetCfgSelector = createSimpleNoDataEmptyArraySelector(chatWidgetCfgList);

const chatWidgetCfgDefSelector = createSimpleNoDataEmptyArraySelector(chatWidgetCfgDefList);

const faqWidgetCfgSelector = createSimpleNoDataEmptyArraySelector(faqWidgetCfgList);

const faqWidgetCfgDefSelector = createSimpleNoDataEmptyArraySelector(faqWidgetCfgDefList);

const voiceWidgetCfgSelector = createSimpleNoDataEmptyArraySelector(voiceWidgetCfgList);

const voiceWidgetCfgDefSelector = createSimpleNoDataEmptyArraySelector(voiceWidgetCfgDefList);

const agentSipStatusSelector = createSimpleNoDataEmptyArraySelector(agentSipStatuses);

const classifierSelector = createSimpleNoDataEmptyArraySelector(classifiers);

const companyAllList = asyncData(keyGetCompanyList);

const configChangeLogList = asyncData(keyGetConfigChangeLog);

const stunTurnSelector = createSelector(
	getAllStunTurnList
	, theList =>{
		if(theList && theList.length > 0){
			let dataList = [];
			for (let i=0; i<theList.length; i++) {
				let data = theList[i];
				dataList.push({
					id: data.id,
					name: data.stunturnname,
					url: data.stunturnurl,
					active: data.active,
					stunturnusername: data.stunturnusername,
					stunturnpassword: data.stunturnpassword,
					stunturnservertype: data.stunturnservertype,
					selectedWebRtcService: data.services,
				});
			}
			return dataList;
		}
		return emptyArray;
	}
);

const companySelector = createSelector(
	companyAllList
	, comp => {
		if (comp && comp.length > 0) {
			let dataList = [];
			for (let i=0; i<comp.length; i++) {
				let data = comp[i];
				dataList.push({
					id: data.id,
					companyName: data.name,
					companyEmail: data.email,
					companyAddress: data.address,
					companyCity: data.city,
					companyZipcode: data.zipcode,
					companyPhone: data.phone,
					companyMobile: data.mobile,
					companyFax: data.fax
				});
			}
			return dataList;
		}
		return emptyArray;
	}
);

const configChangeLogSelector = createSelector(
	getWordFilter
	, configChangeLogList
	, (wordFilter, theList)  => {
		if (theList && theList.length > 0) {
			let dataList = [];
			for (let i=0; i<theList.length; i++) {
				let data = theList[i];
				if (data.note.toUpperCase().indexOf(wordFilter.toUpperCase()) !== -1 || wordFilter == ""){
					dataList.push({
						id: data.id,
						date: data.DateTime,
						agent: data.username,
						type: data.type,
						note: data.note
					});
				}
			}
			return dataList;
		}
		return emptyArray;
	}
);

const errandInternalState = asyncData(keyGetErrandInternalState);

const errandInternalStateSelector = createSelector(
	errandInternalState
	, data => {
		if (data && data.errandstatus && data.errandstatus.length > 0){
			let dataList = [];
			for (let i=0; i<data.errandstatus.length; i++) {
				let item = data.errandstatus[i];
				dataList.push({
					id: item.id,
					name: item.name,
				});
			}
			return dataList;
		}
		return emptyArray;
	}
);

const addressBook = asyncData(keyGetAddressbook);

const addressBookSelector = createSelector(
	addressBook
	, addr => {
		if (addr && addr.addressbook && addr.addressbook.length > 0) {
			const book = addr.addressbook;
			let dataList = [];
			for (let i=0; i<book.length; i++) {
				let data = book[i];
				dataList.push({
					id: data.id,
					name: data.name,
					address: data.address,
					service: data.service
				});
			}
			return dataList;
		}
		return emptyArray;
	}
);

export const accountTwilioSelector = createSelector(
	accountTwilio
	, data => {
		if(data){
			var list = [];
			$.each(data.accounts, function(i,v) {
				$.each(v.phones, function(j,w) {
					w.twilioAccountFriendlyName = v.friendlyName;
					list.push(w);
				});
			});
			return list
		}
		return emptyArray
	}
);

export const accountTwitterSelector = createSimpleNoDataEmptyArraySelector(accountTwitter);
export const accountInstagramSelector = createSimpleNoDataEmptyArraySelector(accountInstagram);
export const accountLineSelector = createSimpleNoDataEmptyArraySelector(accountLine);
export const accountLinkedInSelector = createSimpleNoDataEmptyArraySelector(accountLinkedIn);
export const accountJiraSelector = createSimpleNoDataEmptyArraySelector(accountJira);
export const accountHubSpotSelector = createSimpleNoDataEmptyArraySelector(accountHubSpot);
export const accountMSTeamsSelector = createSimpleNoDataEmptyArraySelector(accountMSTeams);
export const accountTrustpilotSelector = createSimpleNoDataEmptyArraySelector(accountTrustpilot);
export const accountTelegramSelector = createSimpleNoDataEmptyArraySelector(accountTelegram);
export const accountWhatsAppSelector = createSimpleNoDataEmptyArraySelector(accountWhatsApp);
export const accountViberSelector = createSimpleNoDataEmptyArraySelector(accountViber);
export const accountSlackSelector = createSimpleNoDataEmptyArraySelector(accountSlack);
export const accountGoogleReviewSelector = createSimpleNoDataEmptyArraySelector(accountGoogleReview);
export const accountGoogleChatSelector = createSimpleNoDataEmptyArraySelector(accountGoogleChat);
export const accountGooglePlaySelector = createSimpleNoDataEmptyArraySelector(accountGooglePlay);
export const accountSMPPSelector = createSimpleNoDataEmptyArraySelector(accountSMPP);
export const accountYoutubeSelector = createSimpleNoDataEmptyArraySelector(accountYoutube);
export const accountFacebookSelector = createSimpleNoDataEmptyArraySelector(accountFacebook);
export const accountEmailSelector = createSimpleNoDataEmptyArraySelector(accountEmail);
export const fileArchiveAdminSelector = createSimpleNoDataEmptyArraySelector(fileArchive);
export const routeKeywordsSelector = createSimpleNoDataEmptyArraySelector(routeKeywords);
export const routingGroupSelector = createSimpleNoDataEmptyArraySelector(routingGroups);
export const corsWhitelistSelector = createSimpleNoDataEmptyArraySelector(corsWhiteList);
export const adminGroupsSelector = createSimpleNoDataEmptyArraySelector(adminGroups);
export const chatIPBlacklistSelector = createSimpleNoDataEmptyArraySelector(chatIPBlacklist);
export const contactCardSelector = createSimpleNoDataEmptyArraySelector(contactCards);
export const routeSipSelector = createSimpleNoDataEmptyArraySelector(routeSip);
export const tagKeywordsSelector = createSimpleNoDataEmptyArraySelector(tagKeywords);
export const newOrganisationsSelector = createSimpleNoDataEmptyArraySelector(organisationsKeywords);
export const genaiDocumentSelector = createSimpleNoDataEmptyArraySelector(genaiDocument);

export const routeAutoTagsSelector = createSelector(
	routeAutoTags
	, atags => {
		if (atags && atags.list && atags.list.length > 0) {
			return atags.list
		}
		return emptyArray;
	}
);

const externalExpertAddress = asyncData(keyGetExternalExpertAddressList);

const externalExpertAddressSelector = createSelector(
	externalExpertAddress
	, addr => {
		if (addr && addr.list && addr.list.length > 0) {
			let dataList = [];
			const addrList = addr.list
			for (let i=0; i<addrList.length; i++) {
				const data =  addrList[i];
				dataList.push({
					id: data.id,
					emailAddress: data.emailAddress,
					questionType: data.questionType,
					areaCount: data.count,
					connectedArea: data.connectedArea,
					connectedAreaName: data.connectedAreaOrgName,
					serviceType: data.serviceType,
					eeAvatar: data.avatar
				});
			}
			return dataList;
		}
		return emptyArray;
	}
);

const agentsList = asyncData(keyAgentAdminList);

export const getAgentListSelector = createSelector(
	getCurrentAdminView
	, agentsList
	, (view, agents) => {
		if(view === M_REVIEW_AGENTS || view === M_CALL_RECORDINGS) {
			if(agents && agents.length > 0){
				let dataList = [];
				for(let i = 0; i< agents.length; i++){
					let data =  agents[i];
					dataList.push({
						Id: data.id,
						Name: data.name
					});
				}
				return dataList;
			}
		}
		return emptyArray;
	}
);

const agentListSelector = createSelector(
	agentsList
	, agents => {
		if(agents && agents.length > 0){
			let dataList = [];
			for(let i = 0; i< agents.length; i++){
				let data =  agents[i];
				dataList.push({
					id: data.id,
					name: data.name,
					groups: data.groupId,
					status: data.active,
					locked: data.locked,
					level: data.level
				});
			}
			return dataList;
		}
		return emptyArray;
	}
);

function getCallbackAPIList(state) {
	const admin = state.app.admin
	if(typeof admin !== 'undefined' && admin !== null &&
		admin.callbackAPIList !== null && admin.callbackAPIList.data !== null){
		return admin.callbackAPIList.data.data;
	}
	return emptyArray;
}

function getJWTAPIList(state) {
	const admin = state.app.admin
	if(typeof admin !== 'undefined' && admin !== null &&
		admin.jwtAPIList !== null && admin.jwtAPIList.data !== null){
		return admin.jwtAPIList.data;
	}
	return emptyArray;
}

export const callbackAPIListSelector = createSelector(
	getCallbackAPIList
	, data => {
		if(data && data.list){
			let dataList = [];
			for(let i = 0; i< data.list.length; i++){
				const cb =  data.list[i];
				dataList.push({
					id: cb.id,
					name: cb.name,
					enabled: cb.enabled,
					endpoint: cb.callbackapi ? cb.callbackapi.endpoint : "",
					secretKey: cb.callbackapi ? cb.callbackapi.secret : "",
					eventType: cb.type,
					areaList: cb.arealist,
					createdBy: cb.creator ? cb.creator.username : ""
				});
			}
			return dataList;
		}
		return emptyArray;
	}
);

export const jwtAPIListSelector = createSelector(
	getJWTAPIList
	, data => {
		if(data && data.data && data.data.length > 0){
			let dataList = [];
			for(let i = 0; i< data.data.length; i++){
				const jwt =  data.data[i];
				dataList.push({
					id: jwt.id,
					name: jwt.name,
					expireDate: jwt.expireDate,
					token: jwt.token ? jwt.token : "",
					createdBy: jwt.creatorName ? jwt.creatorName : "",
					createdOn: jwt.createdOn,
					others: jwt.others,
					allowAttachment: jwt.allowAttachment,
					apiVersion: jwt.apiVersion,
					automatedConversation: jwt.automatedConversation,
					createErrand: jwt.createErrand
				});
			}
			return dataList;
		}
		return emptyArray;
	}
)

export const jwtChannelSelector = createSelector(
	getJWTAPIList
	, data => {
		if(data && data.channels && data.channels.length > 0){
			let dataList = [];
			for(let i = 0; i< data.channels.length; i++){
				const jwtC =  data.channels[i];
				dataList.push({
					id: jwtC.id,
					name: jwtC.name,
				});
			}
			return dataList;
		}
		return emptyArray;
	}
)

const currentJWTSelectedChannel = state => adminAdmin(state).input.jwtCreateErrandChannel;

export const jwtChannelAccSelector = createSelector(
	getJWTAPIList
	, currentJWTSelectedChannel
	, (data, selectedChannel) => {
		if(data && data.channels && data.channels.length > 0){
			let dataList = [];
			for(let i = 0; i< data.channels.length; i++){
				const jwtC =  data.channels[i];
				if(selectedChannel == jwtC.id) {
					if(jwtC.accounts && jwtC.accounts.length > 0) {
						for(let b = 0; b< jwtC.accounts.length; b++){
							const jwtAcc =  jwtC.accounts[b];
							dataList.push({
								id: jwtAcc.id,
								name: jwtAcc.name,
							});
						}
					}
				}
			}
			return dataList;
		}
		return emptyArray;
	}
)

export const jwtUserSelector = createSelector(
	getJWTAPIList
	, data => {
		if(data && data.users && data.users.length > 0){
			let dataList = [];
			for(let i = 0; i< data.users.length; i++){
				const jwtU =  data.users[i];
				dataList.push({
					id: jwtU.id,
					name: jwtU.name,
				});
			}
			return dataList;
		}
		return emptyArray;
	}
)

export const jwtAPIVersion = createSelector(
	getJWTAPIList
	, data => {
		if(data && data.apiVersions && data.apiVersions.length > 0){
			let dataList = [];
			for(let i = 0; i< data.apiVersions.length; i++){
				const jwtV =  data.apiVersions[i];
				dataList.push({
					id: jwtV.id,
					name: jwtV.name,
				});
			}
			return dataList;
		}
		return emptyArray;
	}
)

//ivr
export const ivrListRecordsSelector = createSelector(
	ivrGetList
	, (rs) => {
		if(!rs) {
			return emptyArray;
		}
		const list = rs.result.data;
		const active = rs.result.activeivr;
		let info = [];
		if(list && list.length > 0) {
			//moh, offhourprompt and nodeTree => move these to string to avoid bootstrap table error on getting obj as props
			for(let i=0;i<list.length;i++) {
				const cf = JSON.parse(list[i].callflow);
				const nodeTree = generateCallFlowTree(cf);
				list[i].nodeTree = JSON.stringify(nodeTree);
				if(list[i].moh) {
					list[i].moh = JSON.stringify(list[i].moh);
				}
				if(list[i].offhourprompt) {
					list[i].offhourprompt = JSON.stringify(list[i].offhourprompt);
				}
				if(list[i].id == active) {
					list[i].active = true;
				} else {
					list[i].active = false;
				}
				info.push(list[i]);
			}
			return info;
		}
		return info;
	}
)

//sip trunk
export const sipTrunkListSelector = createSelector(
	sipTrunkGetList
	, (rs) => {
		if(!rs) {
			return emptyArray;
		}
		const list = rs.result.data;
		let info = [];
		if(list && list.length > 0) {
			for(let i=0;i<list.length;i++) {
				info.push(list[i]);
			}
			return info;
		}
		return info;
	}
)

//skills
export const skillsAdminSelector = createSelector(
	skillAdminList
	, skillAdminCategoryList
	, (rs, category) => {
		if(!rs) {
			return emptyArray;
		}
		let cats = [];
		if(category && category.skillcategory) {
			cats = category.skillcategory;
		}
		const list = rs.skills;
		let info = [];
		if(list && list.length > 0) {
			for(let i=0;i<list.length;i++) {
				const skill = list[i];
				if(skill.agents && skill.agents.length > 0) {
					let agents = [];
					for(let j=0;j<skill.agents.length;j++) {
						agents.push(skill.agents[j].name);
					}
					skill.agentNames = agents.join(", ");
				}
				skill.agents = skill.agents ? skill.agents.length : 0; //show number of agents
				skill.areas = skill.areas ? skill.areas.length : 0; //show number of areas
				if(cats && cats.length > 0) {
					for(let j=0;j<cats.length;j++) {
						if(skill.category == cats[j].id) {
							skill.categoryName = cats[j].name;
							break;
						}
					}
				}
				info.push(skill);
			}
			return info;
		}
		return info;
	}
)

export const skillCategorySelector = createSelector(
	skillAdminCategoryList
	, (rs) => {
		if(!rs) {
			return emptyArray;
		}
		const list = rs.skillcategory;
		let info = [];
		if(list && list.length > 0) {
			for(let i=0;i<list.length;i++) {
				info.push(list[i]);
			}
			return info;
		}
		return info;
	}
)

export const skillAdminAgentListSelector = createSelector(
	skillAdminAgentList
	, (rs) => {
		if(!rs) {
			return emptyArray;
		}
		const list = rs.agents;
		let info = [];
		if(list && list.length > 0) {
			for(let i=0;i<list.length;i++) {
				info.push(list[i]);
			}
			return info;
		}
		return info;
	}
)

export const skillAdminAreaListSelector = createSelector(
	skillAdminAreaList
	, (rs) => {
		if(!rs) {
			return emptyArray;
		}
		const list = rs.skills;
		let info = [];
		if(list && list.length > 0) {
			for(let i=0;i<list.length;i++) {
				info.push(list[i]);
			}
			return info;
		}
		return info;
	}
)

export const skillProficiencySelector = createSelector(
	skillAdminProficiencyList
	, (rs) => {
		if(!rs) {
			return emptyArray;
		}
		const list = rs.proficiencies;
		let info = [];
		if(list && list.length > 0) {
			for(let i=0;i<list.length;i++) {
				info.push(list[i]);
			}
			return info;
		}
		return info;
	}
)

const getAccountSelector = (view) => {
	switch(view) {
		case M_ACCOUNTS:
		case M_ACC_EMAIL:
			return accountEmailSelector
		case M_ACC_LINE:
			return accountLineSelector;
		case M_ACC_LINKEDIN:
			return accountLinkedInSelector;
		case M_ACC_JIRA:
			return accountJiraSelector;
		case M_ACC_HUBSPOT:
			return accountHubSpotSelector;
		case M_ACC_MSTEAMS:
			return accountMSTeamsSelector;
		case M_ACC_GOOGLEREVIEW:
			return accountGoogleReviewSelector;
		case M_ACC_GOOGLECHAT:
			return accountGoogleChatSelector;
		case M_ACC_GOOGLEPLAY:
			return accountGooglePlaySelector;
		case M_ACC_YOUTUBE:
			return accountYoutubeSelector;
		case M_ACC_FACEBOOK:
			return accountFacebookSelector;
		case M_ACC_INSTAGRAM:
			return accountInstagramSelector;
		case M_ACC_SMPP:
			return accountSMPP;
		case M_ACC_TWITTER:
			return accountTwitterSelector;
		case M_ACC_TRUSTPILOT:
			return accountTrustpilotSelector;
		case M_ACC_TELEGRAM:
			return accountTelegramSelector;
		case M_ACC_WHATSAPP:
			return accountWhatsAppSelector;
		case M_ACC_TWILIO:
			return accountTwilioSelector;
		case M_ACC_VIBER:
			return accountViberSelector;
		case M_ACC_SLACK:
			return accountSlackSelector;
		default:
			return emptyArray;
	}
}

export const getAdminListSelector = selectNoSelector(
	getCurrentAdminView
	, view => {
		if (view == ADMIN_VIEW_MAP["wordlist"]) {
			return wordListSelector;
		} else if (isSignature(view)) {
			return signaturesSelector;
		} else if (isSalutation(view)) {
			return salutationsSelector;
		} else if (isTemplate(view)) {
			return templatesSelector;
		} else if (view == ADMIN_VIEW_MAP["addressbook-personal"]
			|| view == ADMIN_VIEW_MAP["addressbook"]) {
			return addressBookSelector;
		} else if (view == ADMIN_VIEW_MAP["externalexpert-personal"]
			|| view == ADMIN_VIEW_MAP["externalexpert"]) {
			return externalExpertAddressSelector;
		} else if (isAccount(view)) {
			return getAccountSelector(view);
		} else if (view == ADMIN_VIEW_MAP["filearchive"]) {
			return fileArchiveAdminSelector;
		} else if (view == ADMIN_VIEW_MAP["keywords"]) {
			return routeKeywordsSelector;
		} else if (view == ADMIN_VIEW_MAP["routinggroups"]) {
			return routingGroupSelector;
		} else if (view == ADMIN_VIEW_MAP["autotags"]) {
			return routeAutoTagsSelector;
		} else if (view == ADMIN_VIEW_MAP["sip"]) {
			return routeSipSelector;
		} else if (view == ADMIN_VIEW_MAP["agents"]) {
			return agentListSelector;
		} else if (view === M_AGENTSIPSTATUS) {
			return agentSipStatusSelector;
		} else if (view === M_STUN_TURN) {
			return stunTurnSelector;
		} else if (view === M_QUICK_REPLY) {
			return quickRepliesSelector;
		} else if (view === M_CLASSIFIER) {
			return classifierSelector;
		} else if (view === M_REVIEW_KEYWORDS) {
			return reviewAddressesSelector;
		} else if (view === M_REVIEW_AGENTS) {
			return reviewAgentsSelector;
		} else if (view == M_ERRANDINTERNALSTATE) {
			return errandInternalStateSelector;
		} else if (view == M_COMPANIES) {
			return companySelector;
		} else if (view == M_CONFIGCHANGELOG) {
			return configChangeLogSelector;
		} else if (view == M_CHATWIDGETDL) {
			return chatWidgetCfgSelector;
		} else if (view == M_FAQWIDGETDL) {
			return faqWidgetCfgSelector;
		} else if (view == M_VOICEWIDGETDL) {
			return voiceWidgetCfgSelector;
		} else if (view == M_TAG) {
			return mainTagsListSelector;
		// } else if (view == M_GROUPS) {
		// 	return adminGroupsSelector;
		//TODO: avoid or move out newer selectors from here.
		//if they dont use shareable `AdminList` component
		//otherwise may cause some bugs on admin list table
		} else if (view == M_CORS_WHITELIST) {
			return corsWhitelistSelector;
		} else if (view == M_CHAT_IP_BLACKLIST) {
			return chatIPBlacklistSelector;
		} else if (view == M_CALL_RECORDINGS) {
			return callRecordingsSelector;
		} else if (view == M_AGENT_SIP_LOGINS) {
			return agentSipLoginsSelector;
		} else if (view == M_SIP_PRIORITY_NUMBERS) {
			return sipPriorityNumbersSelector;
		} else if (view == M_CONTACT_CARDS) {
			return contactCardSelector;
		} else if (view == ADMIN_VIEW_MAP["organisations"]){
			return newOrganisationsSelector;
		} else if (view == M_API_CALLBACK) {
			return callbackAPIListSelector;
		} else if (view == M_API_ACCESSTOKENS) {
			return jwtAPIListSelector;
		} else if (view == M_CALL_IVR) {
			return ivrListRecordsSelector;
		} else if (view == M_CALL_SIP_TRUNK) {
			return sipTrunkListSelector;
		} else if (view == M_SKILLS) {
			return skillsAdminSelector;
		} else if (view == M_GENERATIVE_AI_DOCUMENT) {
			return genaiDocumentSelector;
		}
		return emptyArray;
	}
);

export const getAdminListWipSelector = selectNoSelector(
	getCurrentAdminView, adminRoot
	, (view, admin) => {
		if (view == M_GENERATIVE_AI_DOCUMENT) {
			return admin[stateName(keyGetGenerativeAIDocument)].wip;
		}
		return false;
	}
);

const adminLanguageList = state => adminRoot(state).languageList;
export const getAdminLanguageList = selectNoSelector(
	adminLanguageList
	, languages =>{
		if(languages && languages.data && languages.data.length > 0){
			return languages.data;
		}
		return emptyArray;
	}
);

export const getAdminListDefSelector = selectNoSelector(
	getCurrentAdminView
	, view => {
		if (view == M_CHATWIDGETDL) {
			return chatWidgetCfgDefSelector;
		} else if (view == M_FAQWIDGETDL) {
			return faqWidgetCfgDefSelector;
		} else if (view == M_VOICEWIDGETDL) {
			return voiceWidgetCfgDefSelector;
		}
		return emptyArray;
	}
);

export const routingTagsSelector = createSelector(
	routeAutoTags
	, tagList => {
		if (tagList && tagList.areaTags) {
			return tagList.areaTags
		}
		return emptyArray;
	}
);

const normalizedColumn = columnMap => {
	const result = {};
	$.each(columnMap, (k, v) => {
		result[k] = {};
		$.each(v, (i, v) => {
			result[k][v] = true;
		});
	});
	return result;
};

const salutationOrSignatureColumns = ["id", "Name", "CreatedBy", "Date", "Area"]
	, listColumnMap = {
		[M_TEMPLATE]: ["id", "name", "areas"]
		, [M_TEMPLATE_WHATSAPP]: ["id", "name", "areas"]
		, [M_SALUTATION]: salutationOrSignatureColumns
		, [M_ERRANDINTERNALSTATE]: ["id", "name"]
		, [M_REVIEW_KEYWORDS]: ["id", "keywords", "type", "areas"]
		, [M_MY_SALUTATION]: salutationOrSignatureColumns
		, [ADMIN_VIEW_MAP["filearchive"]]: ["id", "fileName", "description", "timestampUploaded", "userName"]
		, [ADMIN_VIEW_MAP["keywords"]]: ["id", "content", "type", "areas"]
		, [ADMIN_VIEW_MAP["autotags"]]: ["id", "content", "type", "tag", "tagColor", "areas"]
		, [ADMIN_VIEW_MAP["routinggroups"]]: ["id", "name"]
		, [M_ACCOUNTS]: ["id", "areaName", "channelName", "name", "accountName", "enabled", "testAccount"]
		, [M_ACC_EMAIL]: ["id", "areaName", "channelName", "name", "accountName", "enabled", "testAccount"]
		, [M_ACC_LINE]: ["id", "areaName", "name", "accountID", "enabled"]
		, [M_ACC_FACEBOOK]: ["id", "areaName", "name", "accountName", "accountID", "enabled","expirationTime"]
		, [M_ACC_INSTAGRAM]: ["id", "areaName", "name", "accountName", "accountID", "enabled","expirationTime"]
		, [M_ACC_TWITTER]: ["id", "areaName", "name", "accountName", "accountID", "enabled"]
		, [M_ACC_LINKEDIN]: ["id", "areaName", "name", "accountName", "accountID", "enabled"]
		, [M_ACC_JIRA]: ["id", "areaName", "name", "accountName", "accountID", "enabled"]
		, [M_ACC_HUBSPOT]: ["id", "areaName", "name", "accountName", "enabled"]
		, [M_ACC_MSTEAMS]: ["id", "areaName", "name", "accountName", "enabled"]
		, [M_ACC_GOOGLEREVIEW]: ["id", "areaName", "name", "accountName", "enabled"]
		, [M_ACC_GOOGLECHAT]: ["id", "areaName", "accountName", "enabled"]
		, [M_ACC_GOOGLEPLAY]: ["id", "areaName", "accountName", "enabled"]
		, [M_ACC_SMPP]: ["id", "name", "enabled"]
		, [M_ACC_TRUSTPILOT]: ["id", "areaName", "name", "accountID", "enabled"]
		, [M_ACC_TELEGRAM]: ["id", "areaName", "name", "accountID", "enabled"]
		, [M_ACC_WHATSAPP]: ["id", "areaName", "accountName", "accountID", "enabled", "api"]
		, [M_ACC_TWILIO]: ["id", "areaName", "name", "number",  "accountName", "enabled"]
		, [M_ACC_VIBER]: ["id", "areaName", "name", "accountID", "enabled"]
		, [M_ACC_SLACK]: ["id", "name", "appId", "enabled"]
		, [M_ACC_YOUTUBE]: ["id", "areaName", "name", "enabled"]
		, [ADMIN_VIEW_MAP["sip"]]: ["id", "routeSipId", "areas"]
		, [ADMIN_VIEW_MAP["chat-downloadwidget"]]: ["id", "Name", "area"]
		, [ADMIN_VIEW_MAP["faq-downloadwidget"]]: ["id", "Name", "area"]
		, [ADMIN_VIEW_MAP["voice-downloadwidget"]]: ["id", "Name", "area"]
		, [M_AGENTSIPSTATUS]: ["id", "name", "internalName", "className", "active"]
		, [M_STUN_TURN]: ["id", "name", "url", "status"]
		, [M_CLASSIFIER]: ["id", "name", "areas"]
		, [M_QUICK_REPLY]: ["id", "name", "areas"]
		, [M_TAG]: ["id", "name", "areas", "connectedTagsLen", "tagDelete"]
		, [M_API_CALLBACK]: ["id", "name", "endpoint", "createdBy"]
		, [M_API_ACCESSTOKENS]: ["id", "name", "expireDate", "createdOn", "createdBy","token"]
		, [M_GENERATIVE_AI_DOCUMENT]: ["id", "name", "description", "areas", "timestampCreated", "status"]
	}
	, normColumnMap = normalizedColumn(listColumnMap)
	;
export const listColumnSelector = noSelector(
	getCurrentAdminView
	, view => listColumnMap[view]
);

const currentSortField = state => adminUi(state).currentSortField;

// Use selector to manage the proper sort field because not all the data having
// the same sort field name. Without using the same sort field name
// react-bootstrap-table will throw error.
export const currentSortFieldMemo = noSelector(
	currentSortField
	, getCurrentAdminView
	, (sortField, view) => {
		if (!normColumnMap[view]) {
			if (process.env.NODE_ENV !== 'production') {
				console.debug("dbg: error fixme: no such admin view:", view);
			}
			return sortField;
		}
		const columns = normColumnMap[view];
		if (columns[sortField]) {
			return sortField;
		}
		return listColumnMap[view][0];
	}
);

export const currentSortOrder = state => adminUi(state).currentSortOrder;

function getStunTurnServicesList(state) {
	const admin = state.app.admin
	if(typeof admin !== 'undefined' && admin !== null &&
		admin.stunTurnList !== null && admin.stunTurnList.data !== null
		&& admin.stunTurnList.data!== null){
		return admin.stunTurnList.data.webrtcservices;
	}
	return emptyArray;
}

export const getStunTurnServices = createSelector(
	getStunTurnServicesList
	, services =>{
		if(services){
			return services;
		}
		return emptyArray;
	}
);

function getAllStunTurnList(state) {
	const admin = state.app.admin
	if(typeof admin !== 'undefined' && admin !== null &&
		admin.stunTurnList !== null && admin.stunTurnList.data !== null
		&& admin.stunTurnList.data!== null){
		return admin.stunTurnList.data.stunturn;
	}
	return emptyArray;
}

export const getStunTurnList = createSelector(
	getAllStunTurnList
	, theList =>{
		if(theList){
			return theList;
		}
		return emptyArray;
	}
);
function getChannelList(state) {
	const addressBookData = addressBook(state);
	if (addressBookData) {
		if (addressBookData && addressBookData.channel && addressBookData.channel.length > 0) {
			return addressBookData.channel;
		}
	}
	return emptyArray;
}

export const getAdminChannelSelector = createSelector(
	getChannelList
	, channels => {
		if(channels){
			let dataList = [];
			for(let i = 0; i< channels.length; i++){
				let data =  channels[i];
				dataList.push({
					id: data.type,
					name: data.name,
				});
			}
			return dataList;
		}
		return emptyArray;
	}
);

const getAllAdminAreas = state => {
	const adminState = adminRoot(state);
	if (typeof adminState.admin.areaList !== "undefined" && adminState.admin.areaList) {
		return adminState.admin.areaList;
	}
	return emptyArray;
};

// insert-protect
export const IP_ADMIN = "admin"
	, IP_MANUAL = "manual"
	, IP_WORKFLOW = "workflow"
	;
export const insertProtectCkeditor = noSelector(
	currentActiveReply
	, isAdminPageSelector
	, (activeReply, isAdminPage) => {
		if (activeReply === RPLY_MANUAL) {
			return IP_MANUAL;
		} else if (isAdminPage) {
			return IP_ADMIN;
		}
		return IP_WORKFLOW;
	}
);

export const insertProtectCkeditorSettings = createSelector(
	ckeditorSettings
	, insertProtectCkeditor
	, manualFileArchivesSelector
	, fileArchivesSelector
	, adminAdmin
	, (
		settings
		, insertProtectCke
		, manualFileArchives
		, errandFileArchives
		, admin
	) => {
		if (insertProtectCke === RPLY_MANUAL) {
			return update(settings, {archiveImgs: {$set: manualFileArchives}});
		} else if (insertProtectCke === IP_ADMIN) {
			const { archiveImages, langSrc } = admin;
			return update(settings, {
				archiveImgs: {$set: archiveImages}
				, languageSrc: {$set: langSrc}
			});
		}
		return update(settings, {archiveImgs: {$set: errandFileArchives}});
	}
);

export const selectedFileArchive = createSelector (
	getActiveAdminId
	, fileArchiveAdminSelector
	, (id, fileArchives) => {
		let selectedArc = {};
		$.each(fileArchives, (i, v) => {
			if(id == v.id){
				selectedArc = v;
				return selectedArc;
			}
		});
		return selectedArc;
	}
);

const selectChannel = state => adminAdmin(state).dataSrc.channel;
export const selectEmailChannel = createSelector (
	selectChannel,
	(channel) => {
		let channelId = 1;
		$.each(channel,(i,v) => {
			if(v.name === "Email") {
				channelId = v.type
				return false;
			}
		});
		return channelId;
	}
)

//get main ivr based on current level that opened in ui

const defaultCallflow = {
	level: 0,
	id: "0",
	name: "",
	description: "",
	parentid: "",
	type: "singledtmfprompt", //default
	prompt: "",
	promptdownload: "",
	dtmftobehere: "",
	actiontype: "",
	connecttoqueue: ""
}

const ivrGetCurrent = state => adminAdmin(state).input.ivrPromptId;
const ivrGetCallflow = state => adminAdmin(state).input.ivrCallflow;
export const mainIVRSelector = createSelector(
	ivrGetCurrent
	, ivrGetCallflow
	, (parentIvr, activeCallflow) => {
		if(!activeCallflow) {
			return defaultCallflow;
		}
		let mainPrompt = defaultCallflow;
		const cf = activeCallflow;
		cf.map(c => {
			if(c.id == parentIvr){
				mainPrompt = c;
				return mainPrompt;
			}
		})
		return mainPrompt;
	}
)

function getChildCount(list, id) {
	let childCount = 0;
	for (let i = 0; i < list.length; i++) {
		if (list[i].parentid === id) {
			childCount++;
		}
	}
	return childCount;
}

export const IVRDiagramDataSelector = createSelector(
	ivrGetCallflow
	, (activeCallflow) => {
		if (!activeCallflow) {
			return emptyObject;
		}
		return generateCallFlowTree(activeCallflow);
	}
)

function generateCallFlowTree(callflow) {
	let list = [];

	//Adding another keys for node generation purposes (nodeId, parentNodeId)
	callflow.map(ac => {
		const id = ac.id.replace(".", "_");
		ac.nodeId = "node_" + id;

		const parentId = ac.parentid.replace(".", "_");
		ac.parentNodeId = "node_" + parentId;

		ac.childLen = getChildCount(callflow, ac.id);

		list.push(ac);

		//Sorting by parent id to avoid the messed up on connector
		list.sort((a, b) => a.parentid.localeCompare(b.parentid));
	});
	const callTree = setNodePositions(list);
	const nodeTree = generateNodeTree(callTree);

	return nodeTree;
}


const INIT_IVR_PARENT_POS_X = 0;
const INIT_IVR_PARENT_POS_Y = 300;

function setNodePositions(data) {
	if (data && data.length > 0) {
		const maxLevel = Math.max(...data.map((node) => node.level)); // Find the maximum level
		const levelCounts = {}; // Keep track of the number of nodes at each level

		for (let level = 0; level <= maxLevel; level++) {
			const nodes = data.filter((node) => node.level === level);
			levelCounts[level] = nodes.length;
			const offset = 70 * (levelCounts[level] - 1) / 2; // Calculate the offset for the current level
			const nodeWidth = 300;
			const levelDistance = nodeWidth+100; //distance between each level horizontally
			for (let i = 0; i < nodes.length; i++) {
				const node = nodes[i];
				if (level === 0) {
					node.pos_x = INIT_IVR_PARENT_POS_X;
					node.pos_y = INIT_IVR_PARENT_POS_Y;
				} else {
					node.pos_x = ((level - 1) * levelDistance) + nodeWidth; // Set the horizontal position based on the level, except for level=0
					node.pos_y = INIT_IVR_PARENT_POS_Y + (i * 100 - offset);
				}
			}
		}
	}
	return data;
}

function nodeHTMLGenerate(content, rightContent, icon) {
	let right = rightContent, rightClass = "", iconDOM = "";
	if(rightContent == "") {
		rightClass = "end";
		right = I("Call ends");
	}
	if(icon) {
		iconDOM = "<i class="+icon+"></i>"
	}
	if(content) {
		return "<div class='CentionNodeBox'><div class='node-dial' title='"+content+"'><span>"+content+"</span></div><div class='node-line'></div><div class='node-action "+rightClass+"' title='"+right+"'>"+iconDOM+right+"<div></div>";
	} else {
		return "<div class='CentionNodeBox'><div class='node-action "+rightClass+"'>"+iconDOM+right+"<div></div>";
	}
}

//create drawflow diagram's JSON based on the current IVR's callflow
function generateNodeTree(nodes) {
	const nodeTree = {
		drawflow: {
			Home: {
				data: {},
			},
		},
	};

	// create nodes
	nodes.forEach((node, i) => {
		const nodeId = node.nodeId;
		const parentNodeId = node.parentNodeId;

		let dtmfInfo = "";

		if (node.dtmftobehere !== "") {
			dtmfInfo = `Dial ${node.dtmftobehere} : ${node.name}`
		}

		const actionType = IVR_CONNECT_OPTIONS.find(option => option.actionType === node.actiontype);
		const actIcon = IVR_CONNECT_ICON_MAP[node.actiontype];
		const nodeHTMLContent = nodeHTMLGenerate(dtmfInfo, actionType ? actionType.name : "", actIcon);

		let nodeClass = "";

		if(node.level === "") {
			nodeClass = "rootParent";
		} else {
			nodeClass = "parent";
		}

		const newNode = {
			id: nodeId,
			name: node.name,
			data: {},
			class: nodeClass,
			html: nodeHTMLContent,
			inputs: {},
			outputs: {},
			pos_x: node.pos_x,
			pos_y: node.pos_y
		};

		// add node to node tree
		nodeTree.drawflow.Home.data[nodeId] = newNode;

		// add connections to parent node
		if (parentNodeId) {
			const parentNode = nodeTree.drawflow.Home.data[parentNodeId];

			if (parentNode) {
				// add output connection to parent node
				let outputId = "output_1";
				if (parentNode.outputs) {
					const connLength = Object.keys(parentNode.outputs).length + 1;
					outputId = "output_" + connLength;

					parentNode.outputs[outputId] = {
						connections: [
							{
								node: nodeId,
								output: "input_1",
							},
						],
					};
				}

				// add input connection to current node
				newNode.inputs.input_1 = {
					connections: [
						{
							node: parentNodeId,
							input: outputId,
						},
					],
				};
			}

		}
	});

	return nodeTree;
}
