import {
	RPLY_COLLABORATE
	, RPLY_ERRAND
	, RPLY_EXT_FWD
	, RPLY_MANUAL
} from '../../common/v5/constants';
import { getAppState } from '../util';
import { noSelector } from './common';
import {
	currentActiveReply
} from './errand';

export const ckeditorRootMemoize = store => getAppState(store, 'ckeditor');

const insertionTextMemoize = state => ckeditorRootMemoize(state).insertionText;

function createReplySelectionInsertionText(...replies) {
	return noSelector(
		currentActiveReply
		, insertionTextMemoize
		, (currentReply, text) => {
			for (var i = 0; i < replies.length; i++) {
				if (currentReply === replies[i]) {
					return text;
				}
			}
			return "";
		}
	);
};

export const ckeditorInsertionTextMemoize = createReplySelectionInsertionText(
	RPLY_ERRAND
	, RPLY_EXT_FWD
	, RPLY_COLLABORATE
);

// TODO: manual errand Ckeditor operate on different Ckeditor and thus different
// ckeditor memory and should use different store to handle its insertion, OR
// no?. It doesn't create problem at the moment simply because no code allow
// insertion into both of errand-reply ckeditor and manual ckeditor together.
export const manualCkeditorInsertionTextMemoize = createReplySelectionInsertionText(RPLY_MANUAL);
