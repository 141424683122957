import { createSelector } from 'reselect';
import update from 'immutability-helper';
import { hasSuffix, isPhoneNumberService } from '../../common/helpers';
import {
	D_CLIENT_AVATAR
	, D_FORWARD_AGENTS
	, UNSELECT
	, emptyArray
	, emptyObject
	, ONLINE_STATUS
	, AWAY_STATUS
	, INSESSION_STATUS,
	ONHOLD_STATUS
} from '../../common/v5/constants';
import {
	ACCEPT_ST_YES
	, BT_ACCEPT
	, BT_CANCEL
	, BT_CANCEL_OPEN_ERRAND
	, BT_CANCEL_XFER
	, BT_ERRAND
	, BT_FORWARD
	, BT_HANG_UP
	, BT_RECONNECT_TO_TWILIO
	, BT_REJECT
	, BT_REJECTED_OK
	, BT_REPLACE
	, BT_RETRY
	, BT_TRANSFER
	, CALL_NOT_READY
	, CALLS_CONNECTED
	, CALLS_CONNECTING
	, CALLS_CONNECTING_REMOTE
	, CALLS_GETTING_TOKEN
	, CALLS_IDLE
	, CALLS_NO_TOKEN
	, CALLS_OUTBOUNDING
	, CALLT_INBOUND
	, CALLT_OUTBOUND
	, CALLT_UNKNOWN
	, RS_CHCK_BRWSR
	, RS_FAILED_LIB
	, RS_FAILED_SERVER
	, RS_LOADING_LIB
	, RS_NO_SAFARI
	, RS_READY
	, RS_STPD_BRWSR
	, SCS_CALL_WIP
	, SCS_INCOMING_CALL
	, SCS_IDLE
	, SCS_NOT_READY_OR_ERROR
	, TM_AGENT_ID
	, XFER_ST_INVITE
	, XFER_ST_JOIN
	, XFER_ST_LEAVE
	, buttonsMap
	, errorColor
	, goodColor
	, txtActionWip
	, txtCallNotReady
	, txtConnected
	, txtConnecting
	, txtConnectingServer
	, txtConnectionExist
	, txtDisconnected
	, txtErrorConnectServer
	, txtErrorLoadTwilioLib
	, txtIENotSupported
	, txtLoadingTwilioLib
	, txtNameJoinedWarmXfer
	, txtNameLeft
	, txtNameRejectedWarmXfer
	, txtNoSelectedAgent
	, txtNoToken
	, txtPreviousErrand
	, txtReplacing
	, txtSafariNotSupported
	, txtUnknownConnected
	, txtWarmXferingToName
	, warnColor
} from '../../common/v5/callConstants';
import {
	getAppState
	, getNormalizedDomain
	, getStateName
	, reduxCreators
} from '../util';
import {
	noSelector
	, replaceName
	, selectCreateSelector
	, selectNoSelector
} from './common';
import { areaByIdMemo, clientAvatarByIdMemo } from './domain';
import {
	keyChangeAcceptCall
	, keyGetAcceptCall
	, keyGetOutboundPhones
	, keyAgentCallLog
	, keyGetAllSipAgents
	, keyGetActiveCalls
	, keyGetSipNumberInUse
} from '../constants/keys';
import { getAreasSelector, getMyId } from './workflow';

export const callMap = reduxCreators([
	[keyChangeAcceptCall, 'changeAccept']
	, [keyGetAcceptCall, 'getAccept']
	, [keyGetOutboundPhones, 'outboundPhones']
	, [keyAgentCallLog, 'agentCallLog']
	, [keyGetAllSipAgents, 'getAllSipAgents']
	, [keyGetActiveCalls, 'getActiveCalls']
	, [keyGetSipNumberInUse, 'getSipNumberInUse']
]);

export const stateName = key => getStateName(callMap[key]);

const connectedServerCallInProgress = {
		headerText: txtConnected
		, headerTextColor: goodColor
		, status: SCS_CALL_WIP
	}
	, idleForAll = update(
		connectedServerCallInProgress
		, {status: {$set: SCS_IDLE}}
	)
	, noButton = {
		buttons: emptyObject
		, order: emptyArray
	}
	, defaultCallPadState = {
		headerIcon: "fas fa-exclamation-triangle fa-2x"
		, headerText: txtCallNotReady
		, status: SCS_NOT_READY_OR_ERROR
	}
	, specialFailedServer = {
		type: CALL_NOT_READY
		, special: RS_FAILED_SERVER
	}
	, specialNoIE = {
		type: CALL_NOT_READY
		, special: RS_STPD_BRWSR
	}
	, specialSafari = {
		type: CALL_NOT_READY
		, special: RS_NO_SAFARI
	}
	, actionBusy = {
		reason: txtActionWip
	}
	, emptyToken = {
		reason: txtNoToken
	}
	, noTransferee = {
		reason: txtNoSelectedAgent
	}
	, emptyProfile = {
		avatar: ""
		, name: ""
		, number: ""
		, sid: ""
		, type: CALLT_UNKNOWN
	}
	;
export const getCallRoot = store => getAppState(store, 'call');

export const callStateByKey = (state, key) => getCallRoot(state)[stateName(key)];

export const outboundPhonesByArea = state => callStateByKey(state, keyGetOutboundPhones).data;

const getUI = state => getCallRoot(state).ui;

const phoneArea = state => getCallRoot(state).phones;

export const selectedAudioInputMemo = state => getUI(state).audioInput;

export const getOutboundPhoneId = state => getUI(state).selectedOutboundPhoneId;

export const isReplacingWorkplace = state => !!getUI(state).replacing;

export const acceptCallMemoize = state => getUI(state).accept;

export const outboundCallSid = state => getUI(state).outboundCallSid;

export const outboundDialBack = state => getUI(state).outboundDialBack;

export const outboundErrandId = state => getUI(state).outboundErrandId;

const inboundCallSid = state => getUI(state).inboundCallSid;

export const inboundErrandId = state => getUI(state).inboundErrandId;

const inboundErrandAreaId = state => getUI(state).inboundErrandAreaId;

export const autoOpenErrand = state => getUI(state).autoOpenErrand;

const isAutoOpenErrand = state => !autoOpenErrand(state).stop;

const autoOpenErrandTick = state => autoOpenErrand(state).tick;

export const phoneNumber = state => getUI(state).phone;

const c3PhoneNumber = state => getUI(state).phoneC3;

const clientPhoneMailOrigin = state => getUI(state).phoneMailOrigin;

const clientAvatarDomainById = state => state.domain[D_CLIENT_AVATAR].byId;

const clientPhoneAvatar = state => clientAvatarDomainById(state)[clientPhoneMailOrigin(state)];

const isBusy = state => getUI(state).busy;

const busyWithDetail = state => isBusy(state) ? actionBusy : false;

const otherAgents = state => getUI(state).others;

export const isCallPadPopupShown = state => getUI(state).showCallPopup;

export const showSipIncomingPopup = state => getUI(state).showSipIncomingPopup;

export const showSipOutgoingPopup = state => getUI(state).showSipOutgoingPopup;

export const meState = state => getUI(state).me;

const noTwilioToken = state => meState(state).tokens.twilio == "";

const isReplacing = state => getUI(state).replacing;

export const isAcceptCall = state => acceptCallMemoize(state) === ACCEPT_ST_YES;

const readyState = state => getUI(state).ready;

const callXfer = state => getUI(state).transfer;

export const transferMethod = state => getUI(state).transferMethod;

export const isXferCallInProgress = state => callXfer(state).inProgress;

const xferState = state => callXfer(state).st;

export const xfereeName = state => callXfer(state).name;

export const selectedTransfereeAgentId = state =>
	getUI(state).selectedTransfereeAgentId;

export const selectedForwardeeIndex = state => getUI(state).selectedForwardeeIndex;

const twilioCall = state => getUI(state).status.twilio;

export const twilioCallStatus = state => twilioCall(state).st;

export const isTwilioOutbounding = state =>
	twilioCallStatus(state) === CALLS_OUTBOUNDING;

export const twilioCallType = state => twilioCall(state).typ;

const sipCall = state => getUI(state).status.sip;

export const sipCallStatus = state => sipCall(state).st;

export const sipCallConn = state => sipCall(state).conn;

export const sipCallIsRecording = state => sipCall(state).isRecording;

export const sipGetCallXferData = state => sipCall(state).sipTransferData;

export const sipGetXferRef = state => sipCall(state).transferredRefId;

export const sipGetCurrentEid = state => sipCall(state).currentSipErrandId;

export const sipGetCurrentManualEid = state => state.app.errand.ui.manualCall.createdId;

export const sipCallShowAgentList = state => sipCall(state).showAgentList;

export const sipCallShowDtmfKeypad = state => sipCall(state).showDtmfKeypad;

export const sipCallShowExtTransferKeypad = state => sipCall(state).showExternalTransferKeypad;

export const sipCallTimer = state => sipCall(state).sipCallTimer;

export const sipCallCurrentTransferMode = state => sipCall(state).currentTransferMode;

export const sipCallTransferTargetNumber = state => sipCall(state).currentTransferTargetNumber;

export const sipCallTransferStatus = state => sipCall(state).transferSipLine;

export const sipCallIncomingOngoing = state => sipCall(state).incomingOngoing;

export const sipCallTransferIsExternal = state => sipCall(state).isExternalTransfer;

export const sipMakeCallCurrentErrand = state => sipCall(state).sipCallFromErrand;

export const sipGetSnoopDisplayString = state => sipCall(state).snoopDisplayString;

function getAreaForwardAgents(state) {
	return state.domain[D_FORWARD_AGENTS].byId;
}

const inboundErrandAreaForwardAgents = noSelector(
	inboundErrandAreaId
	, getAreaForwardAgents
	, (areaId, agentsByAreaId) => {
		if (areaId <= 0) {
			return;
		}
		return agentsByAreaId[areaId];
	}
);

const normalizedInboundErrandAreaForwardAgents = createSelector(
	inboundErrandAreaForwardAgents
	, agents => {
		if (!agents || agents.length <= 0) {
			return emptyObject;
		}
		const result = {};
		$.each(agents, (i, id) => {
			result[id] = true;
		});
		return result;
	}
);

export const canWarmXfer = noSelector(meState, ({ call }) => call && call.warm);

export const selectedPhoneMemoize = noSelector(getOutboundPhoneId, id => id);

// Extra guard but likely redundant as backend did not send current agent.
export const onlyOtherAgents = createSelector(
	canWarmXfer
	, normalizedInboundErrandAreaForwardAgents
	, otherAgents
	, getMyId
	, (canTransfer, normalizedAgents, others, myId) => {
		if (!canTransfer) {
			return emptyArray;
		}
		let updatedOthers = [];
		$.each(others, (i, v) => {
			const { id } = v;
			if (myId == id || !normalizedAgents[id]) {
				return;
			}
			updatedOthers.push(v);
		});
		if (updatedOthers.length <= 0) {
			return emptyArray;
		}
		return updatedOthers;
	}
);

const isOtherAgentsExist = state => {
	const others = onlyOtherAgents(state);
	if (others && others.length) {
		return true;
	}
	return false;
};

const c3PhoneAreaId = noSelector(
	c3PhoneNumber
	, phoneArea
	, (number, areaByPhone) => {
		if (!number || !areaByPhone) {
			return 0;
		}
		const areaId = areaByPhone[number];
		if (typeof areaId !== "number" || areaId <= 0) {
			return 0;
		}
		return areaId;
	}
);

const externalForwardAreaId = noSelector(
	twilioCallStatus
	, inboundErrandAreaId
	, c3PhoneAreaId
	, (status, errandAreaId, phoneAreaId) => {
		if (status === CALLS_CONNECTED && errandAreaId > 0) {
			return errandAreaId;
		}
		return phoneAreaId;
	}
);

const c3PhoneAreaAddressBook = noSelector(
	areaByIdMemo
	, externalForwardAreaId
	, (areaMap, areaId) => {
		if (areaId <= 0) {
			return emptyArray;
		}
		const area = areaMap[areaId];
		if (!area) {
			return emptyArray;
		}
		const addressbook = area.addressbook_addresses;
		if (!addressbook || !addressbook.length) {
			return emptyArray;
		}
		return addressbook;
	}
);

export const externalForwardableMemo = createSelector(
	c3PhoneAreaAddressBook
	, addresses => {
		if (!addresses.length) {
			return emptyArray;
		}
		const phones = [];
		addresses.forEach(v => {
			if (isPhoneNumberService(Workflow.Errand, v.service)) {
				phones.push(update(
					v
					, {
						id: {$set: phones.length}
						, name: {$set: v.value}
						, number: {$set: v.id}
					}
				));
			}
		});
		if (!phones.length) {
			return emptyArray;
		}
		return phones;
	}
);

const hasExternalForwardable = noSelector(
	externalForwardableMemo
	, phones => phones.length > 0
);

export const selectedForwardee = noSelector(
	externalForwardableMemo
	, selectedForwardeeIndex
	, (options, selected) => {
		if (selected === UNSELECT) {
			return;
		}
		const forwardee = options[selected];
		if (typeof forwardee === "undefined" || !forwardee.number) {
			return;
		}
		return forwardee;
	}
);

const invalidForwardeeSelection = createSelector(
	selectedForwardee
	, selected => {
		if (selected) {
			return false;
		}
		return {reason: "invalid forwardee selection"};
	}
);

export const transferableOptionsMemo = noSelector(
	onlyOtherAgents
	, externalForwardableMemo
	, (others, numbers) => {
		return numbers;
	}
);

const canNotActivateXferDetail = state => {
	const busy = busyWithDetail(state);
	if (busy) {
		return busy;
	}
	const selectedXferee = selectedTransfereeAgentId(state) !== UNSELECT;
	if (selectedXferee) {
		return false;
	}
	return noTransferee;
}

const isConnectingTwilio = status => {
	if (status === CALLS_GETTING_TOKEN || status === CALLS_CONNECTING_REMOTE) {
		return true;
	}
	return false;
};

const twilioConnectedWithType = (status, type, expectedType) => {
	if (status === CALLS_CONNECTED && type === expectedType) {
		return true;
	}
	return false;
}

function buttonWithDisabledOption(button, disabled) {
	if (!disabled) {
		return button;
	}
	return update(button, {disabled: {$set: disabled}})
}

function addOneButton(object, buttonKey, merge) {
	let condition;
	if (merge) {
		condition = update(buttonsMap[buttonKey], {$merge: merge});
	} else {
		condition = buttonsMap[buttonKey];
	}
	object.buttons[buttonKey] = condition;
	object.order.push(buttonKey);
	return object;
}

function addDisablableButton(object, disabled, buttonKey) {
	object.buttons[buttonKey] = buttonWithDisabledOption(
		buttonsMap[buttonKey]
		, disabled
	);
	object.order.push(buttonKey);
	return object;
}

function addButtons(object, disabled, ...buttons) {
	$.each(buttons, (i, v) => {
		addDisablableButton(object, disabled, v);
	});
	return object;
}

function getDefaultButtons(disabled, ...buttons) {
	if (!buttons.length) {
		return noButton;
	}
	const bttns = {buttons: {}, order: []};
	addButtons(bttns, disabled, ...buttons);
	return bttns;
}

function setButtonsAndFooterConnectedServer(buttons, footer) {
	let updater = {};
	if (buttons) {
		updater.buttons = {$set: buttons};
	}
	if (footer) {
		updater.footerText = {$set: footer};
	}
	return update(connectedServerCallInProgress, updater);
}

function setButtonsToConnectedServer(buttons) {
	return setButtonsAndFooterConnectedServer(buttons);
}

function addButtonsToIdleConnected(disabled, ...buttons) {
	return setButtonsToConnectedServer(getDefaultButtons(disabled, ...buttons));
}

function addInboundButtonsAndOrFooter(
	openErrand
	, disabled
	, buttons
	, footerText
) {
	if (typeof openErrand !== "undefined") {
		const { key, countdown } = openErrand;
		let merge;
		if (disabled) {
			merge = {disabled};
		}
		if (typeof countdown !== "undefined") {
			if (merge) {
				merge.countdown = countdown;
			} else {
				merge = {countdown};
			}
		}
		addOneButton(buttons, key, merge);
	}
	addButtons(buttons, disabled, BT_HANG_UP);
	return setButtonsAndFooterConnectedServer(buttons, footerText);
}

const checkOpenErrand = createSelector(
	inboundErrandId
	, isAutoOpenErrand
	, autoOpenErrandTick
	, (errandId, autoOpenErrand, tick) => {
		if (errandId > 0) {
			if (autoOpenErrand) {
				return {key: BT_CANCEL_OPEN_ERRAND, countdown: {tick}};
			}
			return {key: BT_ERRAND};
		}
	}
);

const xferingCallMemoized = createSelector(
	busyWithDetail
	, xferState
	, xfereeName
	, checkOpenErrand
	, (busy, xferStatus, name, openErrand) => {
		const buttons = {buttons: {}, order: []};
		let footerText;
		if (xferStatus === XFER_ST_INVITE) {
			footerText = replaceName(txtWarmXferingToName, name);
			addButtons(buttons, false, BT_CANCEL_XFER);
		} else if (xferStatus === XFER_ST_JOIN) {
			footerText = replaceName(txtNameJoinedWarmXfer, name);
		} else if (xferStatus === XFER_ST_LEAVE) {
			footerText = replaceName(txtNameLeft, name);
		} else {
			// transfer rejected
			footerText = replaceName(txtNameRejectedWarmXfer, name);
			addButtons(buttons, false, BT_REJECTED_OK);
		}
		return addInboundButtonsAndOrFooter(
			openErrand
			, busy
			, buttons
			, footerText
		);
	}
);

const inboundCallConnectedMemoized = selectCreateSelector(
	busyWithDetail
	, canWarmXfer
	, isXferCallInProgress
	, isOtherAgentsExist
	, canNotActivateXferDetail
	, checkOpenErrand
	, transferMethod
	, hasExternalForwardable
	, invalidForwardeeSelection
	, (
		busy
		, allowXfer
		, xferWip
		, hasOtherAgents
		, canNotXfer
		, openErrand
		, method
		, forwardable
		, invalidForwardee
	) => {
		const buttons = {buttons: {}, order: []};
		if (allowXfer) {
			if (xferWip) {
				return xferingCallMemoized;
			} else if (hasOtherAgents || forwardable) {
				const transferToAgent = method === TM_AGENT_ID;
				addButtons(
					buttons
					, transferToAgent ? canNotXfer : invalidForwardee
					, transferToAgent ? BT_TRANSFER : BT_FORWARD
				);
			}
		} else if (forwardable) {
			addButtons(buttons, invalidForwardee, BT_FORWARD);
		}
		return addInboundButtonsAndOrFooter(openErrand, busy, buttons);
	}
);

const callConnectedMemoize = selectCreateSelector(
	twilioCallType
	, type => {
		if (type === CALLT_INBOUND) {
			return inboundCallConnectedMemoized;
		} else if (type === CALLT_OUTBOUND) {
			return addButtonsToIdleConnected(false, BT_HANG_UP);
		} else {
			// CALLT_UNKNOWN, error
			return {
				buttons: getDefaultButtons(false, BT_CANCEL, BT_HANG_UP)
				, headerText: txtUnknownConnected
				, headerTextColor: warnColor
				, status: SCS_NOT_READY_OR_ERROR
			};
		}
	}
);

const lastErrandId = noSelector(
	inboundCallSid
	, inboundErrandId
	, (callSid, errandId) => !callSid ? errandId : 0
);

const mergeReason = (...reasons) => {
	const all = [];
	$.each(reasons, (i, v) => {
		if (v && typeof v.reason !== "undefined") {
			const { reason } = v;
			all.push(reason + (hasSuffix(reason, ".") ? "" : "."));
		}
	});
	if (all.length > 0) {
		return {reason: all.join(" ")}
	}
	return false;
}

const callStateMemoize = selectCreateSelector(
	twilioCallStatus
	, twilioCallType
	, lastErrandId
	, busyWithDetail
	, noTwilioToken
	, hasExternalForwardable
	, invalidForwardeeSelection
	, (
		status
		, type
		, lastErrand
		, busy
		, noToken
		, hasForwardable
		, invalidForwardee
	) => {
		if (isConnectingTwilio(status)) {
			return {headerText: txtConnecting, status: SCS_NOT_READY_OR_ERROR};
		} else if (status === CALLS_NO_TOKEN) {
			const busyBool = !!busy;
			let disabled = busyBool || noToken;
			if (disabled) {
				if (busyBool) {
					disabled = busy;
				} else {
					disabled = emptyToken;
				}
			}
			return {
				buttons: getDefaultButtons(disabled, BT_RECONNECT_TO_TWILIO)
				, headerText: txtDisconnected
				, headerTextColor: errorColor
				, status: SCS_NOT_READY_OR_ERROR
			};
		} else if (status === CALLS_IDLE) {
			if (lastErrand > 0) {
				return update(
					idleForAll
					, {buttons: {$set: addOneButton(
						{buttons: {}, order: []}
						, BT_ERRAND
						, {text: txtPreviousErrand}
					)}}
				);
			}
			return idleForAll;
		} else if (status === CALLS_OUTBOUNDING) {
			return addButtonsToIdleConnected(busy, BT_CANCEL);
		} else if (status === CALLS_CONNECTING) {
			const buttons = getDefaultButtons(busy, BT_REJECT, BT_ACCEPT);
			if (hasForwardable) {
				addButtons(
					buttons
					, mergeReason(busy, invalidForwardee)
					, BT_FORWARD
				);
			}
			return update(setButtonsToConnectedServer(buttons), {
				buttons: {buttons: {[BT_ACCEPT]: {class: {$set: "call-shake"}}}}
				, status: {$set: SCS_INCOMING_CALL}
			});
		} else {
			return callConnectedMemoize;
		}
	}
);

const notReadyMemoize = selectCreateSelector(
	readyState
	, readiness => {
		if (readiness === RS_FAILED_SERVER) {
			return {
				buttons: getDefaultButtons(false, BT_RETRY)
				, headerIcon: specialFailedServer
				, headerText: txtErrorConnectServer
				, status: SCS_NOT_READY_OR_ERROR
			};
		} else if (readiness === RS_CHCK_BRWSR) {
			return defaultCallPadState;
		}
		let headerIcon, headerText;
		if (readiness === RS_STPD_BRWSR) {
			headerIcon = specialNoIE;
			headerText = txtIENotSupported;
		} else if (readiness === RS_NO_SAFARI) {
			headerIcon = specialSafari;
			headerText = txtSafariNotSupported;
		} else if (readiness === RS_FAILED_LIB) {
			headerIcon = "fa fa-exclamation-triangle fa-2x text-danger";
			headerText = txtErrorLoadTwilioLib;
		}  else {
			headerIcon = "fa fa-spinner fa-pulse fa-2x fa-fw";
			if (readiness === RS_LOADING_LIB) {
				headerText = txtLoadingTwilioLib;
			} else {
				headerText = txtConnectingServer;
			}
		}
		return {headerIcon, headerText, status: SCS_NOT_READY_OR_ERROR};
	}
);

const callPadMemoize = selectCreateSelector(
	getUI
	, isBusy
	, ({ accept, me, ready, replacing }, busy) => {
		if (ready !== RS_READY) {
			return notReadyMemoize;
		} else if (accept !== ACCEPT_ST_YES) {
			return {
				headerIcon: "fa fa-sign-out fa-2x"
				, headerText: I('Signed-out from call')
				, status: SCS_NOT_READY_OR_ERROR
			};
		} else if (me.exist) {
			if (replacing) {
				return {
					headerText: txtReplacing
					, status: SCS_NOT_READY_OR_ERROR
				};
			} else {
				return {
					buttons: getDefaultButtons(busy, BT_REPLACE)
					, headerText: txtConnectionExist
					, status: SCS_NOT_READY_OR_ERROR
				};
			}
		} else {
			return callStateMemoize;
		}
	}
);

export const callButtonsMemoize = noSelector(
	callPadMemoize
	, ({ buttons }) => buttons ? buttons : noButton
);

export const callStatusMemoize = noSelector(
	callPadMemoize
	, ({ status }) => status
);

export const headerIconMemoize = noSelector(
	callPadMemoize
	, ({ headerIcon }) => headerIcon
);

export const headerTextMemoize = noSelector(
	callPadMemoize
	, ({ headerText }) => headerText
);

export const footerIconMemoize = noSelector(
	callPadMemoize
	, ({ footerIcon }) => footerIcon
);

export const footerTextMemoize = noSelector(
	callPadMemoize
	, ({ footerText }) => footerText
);

export const showDialPadMemoize = noSelector(
	callStatusMemoize
	, status => status === SCS_IDLE
);

export const callStatusIdle = showDialPadMemoize;

export const isErrorOrNotReadyMemoize = noSelector(
	callStatusMemoize
	, status => status === SCS_NOT_READY_OR_ERROR
);

const isCallInProgressMemoize = noSelector(
	callStatusMemoize
	, status => status === SCS_CALL_WIP || status === SCS_INCOMING_CALL
);

const currentCallSid = noSelector(
	inboundCallSid
	, outboundCallSid
	, twilioCallType
	, (callSidInbound, callSidOutbound, callType) => {
		if (callType === CALLT_OUTBOUND) {
			return callSidOutbound;
		} else if (callType === CALLT_INBOUND) {
			return callSidInbound;
		}
		return "";
	}
);

export const callProfileMemoize = createSelector(
	isCallInProgressMemoize
	, clientPhoneAvatar
	, phoneNumber
	, (callWip, client, phoneNumber) => {
		if (!callWip) {
			return false;
		} else if (!client) {
			return update(emptyProfile, {number: {$set: phoneNumber}});
		}
		return update(
			emptyProfile
			, {
				avatar: {$set: client.avatar ? client.avatar.url : ""}
				// TODO: if client avatar carry name then this should switch to use
				// name field.
				, name: {$set: client.fromAddress}
				, number: {$set: phoneNumber}
			}
		);
	}
);

const sipCallGetAgentDataList = state => sipCall(state).agentList;
const getAvailableAgents = state => state.app.workflow.agents;
export const sipCallGetAgentList = createSelector(
	sipCallGetAgentDataList
	, getAvailableAgents
	, (sipAgents, allAvailAgents) => {
		let sipAgentWithAvatar = sipAgents;
		if(sipAgents && sipAgents.length > 0) {
			$.each(sipAgents, (n, agent) => {
				if(allAvailAgents.data) {
					let allAgent = allAvailAgents.data.agents;
					for(let i = 0; i< allAgent.length; i++){
						let ag = allAgent[i];
						if(parseInt(agent.id, 10) === ag.Id) {
							agent.avatar = ag.Avatar;
							break;
						}
					}
				}
			});
			return sipAgentWithAvatar;
		} else {
			return emptyArray;
		}
	}
);

const sipTransferTargetAgentId = state => sipCall(state).sipTargetAgentId;
export const sipTransferCallTargetAvatar = createSelector(
	sipCallGetAgentList
	, sipTransferTargetAgentId
	, (sipAgents, targetAgent) => {
		let avatar = "";
		if(sipAgents && sipAgents.length > 0) {
			for(let i = 0; i< sipAgents.length; i++){
				let agent = sipAgents[i];
				if(agent.id == targetAgent) {
					avatar = agent.avatar;
					break;
				}
			}
			return avatar;
		}
		return avatar;
	}
);

const sipGetAllAgents = state => callStateByKey(state, keyGetAllSipAgents).data;
const sipGetActiveCalls = state => callStateByKey(state, keyGetActiveCalls).data;
export const sipGetAllAgentsWip = state => callStateByKey(state, keyGetAllSipAgents).wip;
export const sipGetActiveCallsWip = state => callStateByKey(state, keyGetActiveCalls).wip;
export const sipGetSipNumberInUse = state => callStateByKey(state, keyGetSipNumberInUse).data;

//Active Sip Calls
export const sipActiveCalls = createSelector(
	sipGetSipNumberInUse
	, (voiceData) => {
		let calls = [];
		if(voiceData) {
			if(voiceData.data && voiceData.data.calls.length > 0) {
				const callsInfo = voiceData.data.calls;
				for(let i = 0; i< callsInfo.length; i++){
					let call = callsInfo[i];
					let callStatus = INSESSION_STATUS;
					let canSnoop = true;
					let icon = "icon-call";
					// Current info from Switchd does not includes call status information
					// Exclude it for now
					/* if(call.onHold) {
						callStatus = ONHOLD_STATUS;
						icon = "icon-pause";
						canSnoop = false;
					} */
					calls.push({from: call.callernumber, group: call.areaname, agent: call.agentname, status: callStatus, icon: icon, snoop: canSnoop, sip: call.callid});
				}
			}

		}
		return calls;
	}
)

//Sip Agents Queue
export const sipAgentQueue = createSelector(
	sipGetAllAgents
	, sipGetActiveCalls
	, (agents, calls) => {
		let activeAgents = [];
		if(agents && agents.data) {
			if(agents.data.length > 0) {
				$.each(agents.data,function(i, v){
					//check if there's active call session with each agent
					let caller = "", callStatus = "", icon = "";;
					if(v.status === "Available") {
						callStatus = ONLINE_STATUS;
					} else {
						callStatus = AWAY_STATUS;
					}
					if(calls && calls.data && calls.data.length > 0) {
						for(let i = 0; i< calls.data.length; i++){
							let call = calls.data[i];
							if(call.user === parseInt(v.id)) {
								caller = call.phoneNumber;
								if(call.onHold) {
									callStatus = ONHOLD_STATUS;
									icon = "icon-pause";
								} else {
									callStatus = INSESSION_STATUS;
									icon = "icon-call";
								}
							}
						}
					}
					activeAgents.push({agent: v.username, status: callStatus, group: v.areas, from: caller, icon: icon, channel: "icon-call"});
				});
			}
		}
		return activeAgents;
	}
);

//Sip Group Queue
export const sipGroupQueue = createSelector(
	sipGetAllAgents
	, sipGetActiveCalls
	, (agents, calls) => {
		let activeGroups = [];
		let areaData  = {}, agentsArr = [];
		if(agents && agents.data) {
			if(agents.data.length > 0) {
				$.each(agents.data,function(i, v){
					agentsArr.push(v);
					if(v.areaInfo) {
						var areaObj = JSON.parse(v.areaInfo);
						for (const key of Object.keys(areaObj)) {
							let id = areaObj[key].id;
							areaData[id] = {area: areaObj[key], agent: agentsArr, calls: []};
							continue;
						}
					}
				});
			}
			let areasArr = [];
			Object.entries(areaData).map(item => {
				areasArr.push(item[1]);
				let areaId = item[1].area.id;
				if(calls && calls.data) {
					if(calls.data.length > 0) {
						for(let i = 0; i<calls.data.length; i++){
							let call = calls.data[i];
							if(areaId === call.areaId) {
								item[1].calls.push(call);
							}
						}
					}
				}
			});
			for(let i = 0; i< areasArr.length; i++){
				let a = areasArr[i];
				let areaName = a.area ? a.area.name : "";
				let activeCalls = a.calls ? a.calls.length : 0;
				let activeAgents = a.agent ? a.agent.length : 0;
				let cTxt = "";
				if(activeAgents > 1) {
					cTxt = I("agents online");
				} else {
					cTxt = I("agent online");
				}
				if(activeAgents > 0) {
					activeGroups.push({
						area: areaName,
						queue: 0, //TODO add queue count from queued
						active: activeCalls,
						agents: activeAgents,
						customText: cTxt,
						channel: "icon-call"
					});
				}
			}
		}
		return activeGroups;
	}
);
