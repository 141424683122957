import moment from 'moment';
import update from 'immutability-helper';
import { createSelector } from 'reselect';
import { I } from '../../common/v5/config.js';
import {
	PRINT_COL_CONVERSATION,
	PRINT_COL_MESSAGE,
	PRINT_ERRANDS,
	PT_COLLABORATION,
	PT_ERRAND
} from '../../common/v5/constants';

const getPrintType = state => state.app.print.type;

const getPrintData = state => state.app.print.data;

const getPrintTime = state => state.app.print.time;

const wfSettings = state => state.app.workflow.fetchWfSettings.data;

const getCurrentAgentName = state => {
	const settings = wfSettings(state);
	if (!settings) {
		return "";
	}
	return settings.activeUserName;
};

const getCurrentAgentTZ = state => {
	const settings = wfSettings(state);
	if (!settings) {
		return "+0000";
	}
	return settings.agentTimezoneOffset;
};

const getErrandPrintContent = state => {
	if(!state.app.errand.printContent.data){
		return "";
	}
	return {content: state.app.errand.printContent.data.content};
}

const getErrandPrintInfo = state => {
	if(!state.app.errand.printContent.data){
		return "";
	}
	return state.app.errand.printContent.data.printInfo;
}

const getDomainExternalExpertThreadsById = state => state.domain.externalExpertThreads.byId;

export const getPrintInfo = createSelector(
	[
		getPrintType,
		getPrintTime,
		getCurrentAgentTZ,
		getCurrentAgentName,
		getErrandPrintInfo,
	],
	(type, time, tz, name, errandInfo) => {
		if (type === PRINT_ERRANDS) {
			return errandInfo;
		}
		return I('Printed by {AGENT_NAME} at {DATE_TIME}.')
			.replace('{AGENT_NAME}', name)
			.replace('{DATE_TIME}', moment.unix(time).utcOffset(tz)
				.format('YYYY/MM/DD HH:mm'));
	}
);

const convertToErrandCompatible = data => update(data, {
	eid: {$set: data.id},
	message: {$set: data.htmlBody},
	created: {$set: data.dateHuman}
});

const getPrintCollaborationConversation = createSelector(
	[
		getPrintType,
		getPrintData,
		getDomainExternalExpertThreadsById
	],
	(type, data, threads) => {
		if (type !== PRINT_COL_CONVERSATION || !data) {
			return null;
		}
		const messages = threads[data].message;
		if (!messages.length) {
			return null;
		}
		let m = {content: []};
		$.each(messages, (i,v) => {
			m.content.push(convertToErrandCompatible(v));
		});
		return m;
	}
);

const getPrintCollaborationMessage = createSelector(
	[
		getPrintType,
		getPrintData,
		getDomainExternalExpertThreadsById
	],
	(type, data, threads) => {
		if (type !== PRINT_COL_MESSAGE || !data) {
			return null;
		}
		let found, message;
		const { thread, answer, id } = data;
		$.each(threads[thread].message, (i,v) => {
			if (v.answer === answer && v.id === id) {
				found = true;
				message = convertToErrandCompatible(v);
			}
		});
		if (!found) {
			return null;
		}
		return {content: [message]};
	}
);

export const getPrintBodySelector = createSelector(
	[
		getPrintType,
		getErrandPrintContent,
		getPrintCollaborationConversation,
		getPrintCollaborationMessage
	],
	(type, errands, colConversation, colMessage) => {
		if (type === PRINT_ERRANDS) {
			return errands;
		} else if (type === PRINT_COL_CONVERSATION) {
			return colConversation;
		} else if (type === PRINT_COL_MESSAGE) {
			return colMessage;
		}
		return null;
	}
);

export const getPrintTypeSelector = createSelector(
	getPrintType,
	type => {
		if (type === PRINT_ERRANDS) {
			return PT_ERRAND;
		} else if (type === PRINT_COL_CONVERSATION ||
			type === PRINT_COL_MESSAGE) {
			return PT_COLLABORATION;
		}
	}
);
